
import { defineComponent, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getTeacherInfo } from "@/services";
import store, { MutationNames } from "@/store/index";
import logger from "@evideo/logger";

export default defineComponent({
  setup() {
    const teacherName = ref("");
    const router = useRouter();
    // let teachMaterialType = ref("official");
    onBeforeMount(() => {
      getBookInfo();
    });
    const getBookInfo = () => {
      logger.info("重新获取数据");
      getTeacherInfo({}).then((data: any) => {
        logger.info("teacherName", data);
        teacherName.value = data.nick_name;
        store.commit(MutationNames.CHANGE_NICKNAME, data.nick_name);
      });
    };
    const goToTeachingMaterial = () => {
      router.push({
        path: "/teachMaterial",
        query: { teachMaterialType: "official" },
      });
    };
    const goToMyCourse = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
      // 1108 关闭我的教材要进行调整
      // router.push({
      //   path: "/teachMaterial",
      //   query: { teachMaterialType: "myCourse" },
      // });
    };
    const goToHome = () => {
      router.push({
        path: "/login",
      });
    };
    const goToMusicAppreciation = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goToInteractiveBeat = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goToMusicTheory = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goToStaff = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goToMusicalNotation = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goToChorusZone = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goLeft = () => {
      const elem: HTMLElement = document.getElementById(
        "Middle"
      ) as HTMLElement;
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft += 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    const goRight = () => {
      const elem: HTMLElement = document.getElementById(
        "Middle"
      ) as HTMLElement;
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft -= 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    return {
      teacherName,
      goToTeachingMaterial,
      goToHome,
      goToMyCourse,
      goToChorusZone,
      goToInteractiveBeat,
      goToMusicAppreciation,
      goToMusicTheory,
      goToStaff,
      goToMusicalNotation,
      goLeft,
      goRight,
    };
  },
});
