
import { getUserMelodyFiles } from "@/services/rhythm";
import { Search } from "@element-plus/icons-vue";
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  toRefs,
  watch,
  computed,
  onUnmounted,
  defineEmits,
  onBeforeUnmount,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";
import { ElMessageBox, ElMessage } from "element-plus";
import { dataEmbeddingPoint } from "@/services";
import store from "@/store/index";
import moment from "moment";
import remindEmptyResource from "@/components/remindEmptyResource.vue";
import logger from "@evideo/logger";
import {
  getCacheFile,
  uploadCacheFileFormData,
} from "@/services/localResource";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import { inject } from "vue";
import { SocketEventMap } from "@/utils/socket-event";
import { noticeCacheErrorDownloadResult } from "@/utils/socketCommon";
import {
  checkCurrentCacheDownloading,
  subCurrentCacheDownloadNum,
} from "@/utils/localResourceParse";
import { getMelodyFile, delMelodyFile } from "@/services";
import Confirm from "./confirm.vue";
import { MutationNames } from "@/store/index";
/**
 * type 1我的律动 2律动广场
 */
export default defineComponent({
  name: "ListContent",
  components: {
    Search,
    remindEmptyResource,
    Confirm,
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "律动列表",
    },
  },
  emits: ["getMyList"],
  setup(props, ctx) {
    // 是否有本地缓存的处理数据
    let finishedLocalCacheData = ref([]);
    const queryParams = ref({
      page: 1,
      name: "",
    });
    const store = useStore();
    // 删除选中律动id
    const delId = ref(0);
    const currentPage = ref(1);
    const loading = ref(false);
    const list = ref([]);
    const router = useRouter();
    const listType = ref(props.type);
    const isEmpty = ref(computed(() => list.value.length === 0));
    const localResourceState = computed(() => store.state.localResourceState);
    let firstLoading = ref(true);
    const visible = ref(false);
    const backVisible = ref(false);
    const visibleId = ref(0);
    const iframeVisible = ref(false);
    const deleteVisible = ref(false);
    const addUrl = ref("");
    // 是否打开iframe
    let isOpenIframe = ref(false);
    // let currentScrollPosition = ref(0);
    // let previousScrollPosition = ref(0);
    // 列表滚动时操作的弹框隐藏
    const scrolling = (e) => {
      // currentScrollPosition.value = e.target.scrollTop;
      // const scrollDistance =
      //   currentScrollPosition.value - previousScrollPosition.value;
      // console.log("本次滚动距离：", scrollDistance);
      // previousScrollPosition.value = currentScrollPosition.value;
      closePop();
    };
    const compareVersion = (v1, v2) => {
      // -1|v2大于v1；0|相同；1|v2小于v1
      v1 = v1.split(".");
      v2 = v2.split(".");
      const len = Math.max(v1.length, v2.length);
      while (v1.length < len) {
        v1.push("0");
      }
      while (v2.length < len) {
        v2.push("0");
      }
      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);
        if (num1 > num2) {
          return 1;
        } else if (num1 < num2) {
          return -1;
        }
      }

      return 0;
    };
    const iscompare = compareVersion(store.state.version, "1.4.3"); // 对比是否满足新要版本
    const LocalCacheData = ref([]);
    // 处理该教材需要确认的资源数据  拿到本地存储的数据
    const handleLocalCacheData = (data) => {
      data.forEach((el) => {
        const item = {};
        item["cloud_id"] = el.id;
        item["type"] = "MUSIC_RHYTHM";
        item["version_time"] = el.updated_at;
        item["source"] = "official";
        if (item.cloud_id > 0) {
          LocalCacheData.value.push(item);
        }
      });
      return { items: LocalCacheData.value };
    };
    const closePop = () => {
      console.log(123);
      visible.value = false;
    };
    // 向缓存服务确认资源是否在本地已存在
    const isLocalCacheData = (data) => {
      return getCacheFile(JSON.stringify(data));
    };
    // 初始化整理数据
    const filterParams = (list) => {
      const params = [];
      console.log(list);
      list.forEach((el) => {
        el["status"] = "expire";
        // 将数据和本地是否有资源的数据进行比较，重新处理数据
        finishedLocalCacheData.value.find((data) => {
          const { status } = data.file_info || {};
          // 当id相同且本地url不为空
          if (
            data.cloud_id === el.id &&
            status &&
            (status === "success" || status === "loading")
          ) {
            el["status"] = status;
            if (status === "loading") {
              const { complete_file_num, total_file_num } = data.progress || {};
              const currentProgress = total_file_num
                ? Math.floor((complete_file_num / total_file_num) * 100)
                : 0;
              el["localDownloadProgress"] = currentProgress;
            } else {
              el["localDownloadProgress"] = undefined;
            }
          }
        });
        params.push(el);
      });
      console.log("params", params);
      return params;
    };
    const getList = _.debounce(async (loadMore = false, type = "son") => {
      console.log(
        "1233312344",
        props.type,
        currentPage.value,
        loadMore,
        queryParams.value
      );
      // if (loading.value) {
      //   return;
      // }
      if (type === "parent") {
        currentPage.value = 1;
      }
      if (loadMore) {
        console.log(13312344556677);
        currentPage.value++;
      }
      loading.value = true;
      const {
        data,
        meta: { current_page },
      } = await getUserMelodyFiles({
        ...queryParams.value,
        type: listType.value,
        per_page: 10,
        page: currentPage.value,
      });
      if (loadMore) {
        console.log(1331234455, data);
        list.value = [...list.value, ...data];
        if (!data.length) {
          currentPage.value--;
          // current_page--;
          console.log(133123445566, currentPage.value);
          ElMessage.info({
            offset: 200,
            message: "无更多数据",
            type: "info",
          });
        }
      } else {
        list.value = data;
      }
      // currentPage.value = current_page;
      loading.value = false;
      // 1.4.3版本以上的话就进行缓存
      if (iscompare >= 0 && localResourceState.value) {
        finishedLocalCacheData.value = await handleLocalCacheData(list.value); // 转换成为指定资源数据
        const LocalCacheData = await isLocalCacheData(
          finishedLocalCacheData.value
        ); // 拿到本地资源信息，以及过期的资源信息
        finishedLocalCacheData.value = LocalCacheData.data;
        list.value = filterParams(list.value);
      }
    });
    // 数据埋点 Android用自己的
    const handleDataReport = (item) => {
      const eventattr = { id: item.id };
      logger.info("eventattr", eventattr);
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

      // pc数据上报
      const data = {
        uid: store.state.pcUserIdAES,
        ct: "96",
        ci: store.state.mac,
        mac: store.state.mac,
        sv: store.state.version,
        fv: store.state.hardwareModel,
        ca: ca,
        pt: "pc",
        eventid: "teaching_rhythm_open",
        pageid: "rhythm",
        moduleid: "teaching",
        eventattr: eventattr,
      };
      logger.info("data", data);
      const body = encodeURIComponent(JSON.stringify(data));
      logger.info("body", body);
      dataEmbeddingPoint(body);
    };
    const handleClick = (item) => {
      logger.info("type", props.type, item, iscompare, item.id);
      // closePop();
      if (listType.value === 2) {
        handleDataReport(item);
      }
      // 大于1.4.3版本的就需要传歌曲id版本等
      if (iscompare >= 0) {
        router.push({
          path: `/musicRhythm/detail/${item.id}`,
          query: {
            id: item.id,
            version_time: item.updated_at,
            type: "MUSIC_RHYTHM",
            source: "official",
          },
        });
      } else {
        router.push({
          path: `/musicRhythm/detail/${item.id}`,
        });
      }
    };
    // 下载解析失败或create失败，将loading图标置为未下载状态
    const handleChooseOrigin = (data) => {
      for (let i = 0; i < list.value.length; i++) {
        if (data.id === list.value[i].id) {
          list.value[i].status = "expire";
        }
      }
    };
    const loadClick = (item) => {
      // closePop();
      if (!checkCurrentCacheDownloading()) {
        return;
      }
      const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // 点击下载load资源解析后
      item.status = "loading";
      item.localDownloadProgress = undefined;
      const loadData = {
        type: "music_rhythm",
        version_time: item.updated_at,
        source: "official",
        name: item.name,
        cloud_id: item.id,
      };
      // 资源解析，发布下载任务
      LocalResourceExchange.resourceParse(loadData)
        .then((res) => {
          // 数据表单格式保存
          var formData = new FormData();
          formData.append("version_time", item.updated_at || currentTime);
          formData.append("mode", "music_rhythm"); // 参考socket里的mode形式
          formData.append("cloud_id", item.id);
          formData.append("name", item.name + ".json");
          formData.append("resource_file", res);
          formData.append("type", "MUSIC_RHYTHM"); // 大写（PPT AUDIO VIDEO SWF MUSIC_RHYTHM）
          formData.append("source", "official");
          formData.append("direct", true); // 需要立即使用的资源，传true
          uploadCacheFileFormData(formData)
            .then((_res) => {
              item.localDownloadProgress = 0;
              console.log("创建缓存资源下载成功");
            })
            .catch((err) => {
              console.error(err);
              handleChooseOrigin(item);
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            });
        })
        .catch((err) => {
          console.error(err);
          handleChooseOrigin(item);
          ElMessage.error({
            offset: 200,
            message: "资源下载失败",
            type: "error",
          });
        })
        .finally(() => {
          subCurrentCacheDownloadNum();
        });
    };
    // 缓存到本地之后接收缓存服务发过来的消息
    const socket = inject("socket");
    const downloadStatus = (res) => {
      console.log("#message music_rhythm:", res);
      const { status, cloud_id } = res.data.details[0] || {};
      for (let i = 0; i < list.value.length; i++) {
        if (cloud_id === list.value[i].id) {
          list.value[i].status = status;
          if (status === 0) {
            list.value[i].status = "success";
          } else {
            list.value[i].status = "expire";
            ElMessage.error({
              offset: 200,
              message: "资源下载失败",
              type: "error",
            });
          }
        }
      }
    };

    const downloadProgress = (res) => {
      console.log("#message progress: ", res);
      const { complete_file_num, total_file_num, cloud_id } = res.data || {};
      const currentProgress = total_file_num
        ? Math.floor((complete_file_num / total_file_num) * 100)
        : 0;
      for (let i = 0; i < list.value.length; i++) {
        if (cloud_id === list.value[i].id) {
          list.value[i].localDownloadProgress = currentProgress;
        }
      }
    };

    socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
    socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    socket.on(
      SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
      noticeCacheErrorDownloadResult
    );
    const getNextPageList = async () => {
      console.log(1331234400);
      await getList(true);
    };
    const handleFocus = () => {
      firstLoading.value = false;
    };
    const handleVisibleModal = (id) => {
      if (id !== visibleId.value) {
        visible.value = true;
        visibleId.value = id;
      } else {
        visible.value = !visible.value;
        visibleId.value = id;
      }
    };
    const clickPopover = async (id, type) => {
      console.log(123123, id);
      if (type === "icon") {
        handleVisibleModal(id);
      } else if (type === "edit") {
        const env = process.env.RELEASE_ENV;
        console.log("123123444eee", props, env);
        // addUrl.value = `http://192.168.92.101:8080/h5.html#/?type=1&id=${id}&token=${store.state.token}`;
        if (env == "prod") {
          addUrl.value = `https://mohuan-h5.jilingshu.com.cn.tech/h5.html#/?type=1&id=${id}&token=${store.state.token}`;
        } else {
          addUrl.value = `https://${env}mohuan-h5.jilingshu.com.cn.tech/h5.html#/?type=1&id=${id}&token=${store.state.token}`;
        }
        // addUrl.value = `https://${env}mohuan-h5.jilingshu.com.cn.tech/h5.html#/?type=1&id=${id}&token=${store.state.token}`;
        console.log("123123444eeeee", addUrl.value);
        iframeVisible.value = true;
        isOpenIframe.value = true;
        handleVisibleModal(id);
        document.getElementById("list_1").scrollTop = 0;
      } else if (type === "copy") {
        getMelodyFile(id).then((data) => {
          console.log("data", data);
          if (data.status_code === 0) {
            ElMessage.success({
              offset: 200,
              message: "复制成功",
            });
            currentPage.value = 1;
            getList();
            handleVisibleModal(id);
            document.getElementById("list_1").scrollTop = 0;
          } else {
            ElMessage.error({
              offset: 200,
              message: "复制失败",
            });
          }
        });
      } else if (type === "del") {
        delId.value = id;
        closePop();
        deleteVisible.value = true;
        // await delMelodyFile(id);
        // ElMessage.success({
        //   offset: 200,
        //   message: "删除成功",
        // });
        // getList();
        // handleVisibleModal(id);
      }
    };
    const handleDeleteLD = async () => {
      // return new Promise((resolve, reject) => {
      if (delId.value > 0) {
        await delMelodyFile(delId.value);
        ElMessage.success({
          offset: 200,
          message: "删除成功",
        });
        currentPage.value = 1;
        getList();
        document.getElementById("list_1").scrollTop = 0;
        // handleVisibleModal(id);
      }
      // });
    };
    const getMelody = (id) => {
      // closePop();
      getMelodyFile(id).then((data) => {
        console.log("data", data);
        if (data.status_code === 0) {
          ElMessage.success({
            offset: 200,
            message: "获取成功",
          });
          // listType.value = 1;
          // currentPage.value = 1;
          // getList();
          ctx.emit("getMyList");
        } else {
          ElMessage.error({
            offset: 200,
            message: "获取失败",
          });
        }
      });
    };
    const addYYLD = () => {
      const env = process.env.RELEASE_ENV;
      console.log("123123444", props, env, window);
      if (env == "prod") {
        addUrl.value = `https://mohuan-h5.jilingshu.com.cn.tech/h5.html#/?type=1&token=${store.state.token}`;
      } else {
        addUrl.value = `https://${env}mohuan-h5.jilingshu.com.cn.tech/h5.html#/?type=1&token=${store.state.token}`;
      }

      // addUrl.value = `http://192.168.92.101:8080/h5.html#/?type=1&token=${store.state.token}`;
      console.log(addUrl.value);
      iframeVisible.value = true;
      isOpenIframe.value = true;
    };
    watch(
      () => queryParams.value,
      (value) => {
        console.log("12333", queryParams.value.name, props.type, value);
        if (props.type == 1) {
          store.commit(
            MutationNames.CHANGE_QUERY_MY_MELODY_INFO,
            queryParams.value.name
          );
        } else {
          store.commit(
            MutationNames.CHANGE_QUERY_MELODY_INFO,
            queryParams.value.name
          );
        }
        currentPage.value = 1;
        getList();
      },
      {
        deep: true,
      }
    );

    const isColseModal = (type) => {
      if (!type) {
        backVisible.value = false;
      } else {
        backVisible.value = false;
        iframeVisible.value = false;
        isOpenIframe.value = false;
        currentPage.value = 1;
        getList();
      }
    };
    const getIpcMessage = () => {
      console.log(12399);
      // const iframe = document.getElementById("cb");
      if (!isOpenIframe.value) {
        console.log(12377);
        console.log("qqqqqq");
        store.commit(MutationNames.CHANGE_QUERY_MY_MELODY_INFO, "");
        store.commit(MutationNames.CHANGE_QUERY_MELODY_INFO, "");
        router.push({
          path: "/gameCenter",
        });
      } else {
        console.log(1238899);
        backVisible.value = true;
      }
    };
    onBeforeMount(() => {
      loading.value = true;
      console.log(
        "12333344",
        store.state.queryMyMelodyInfo,
        store.state.queryMelodyInfo
      );
      if (props.type == 1) {
        queryParams.value.name = store.state.queryMyMelodyInfo;
      } else {
        queryParams.value.name = store.state.queryMelodyInfo;
      }
      getList();
    });
    onMounted(() => {
      if (window.require && listType.value === 1) {
        console.log("收到electron goGameCenter消息1");
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.on("goGameCenterAdd", getIpcMessage);
      }
      window.closeAddLDIframe = () => {
        iframeVisible.value = false;
        isOpenIframe.value = false;
      };
      window.addEventListener("message", (e) => {
        console.log("收到信息", e.data.type);
        if (e.data.type === "close") {
          console.log(90909099);
          document.getElementById("list_2").scrollTop = 0;
          backVisible.value = false;
          iframeVisible.value = false;
          isOpenIframe.value = false;
          currentPage.value = 1;
          getList();
        }
      });
    });
    onBeforeUnmount(async () => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.removeListener("goGameCenterAdd", getIpcMessage);
    });
    onUnmounted(() => {
      // iframeVisible.value = false;
      // isOpenIframe.value = false;
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
      socket.off(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
    });
    return {
      ...toRefs(props),
      list,
      isEmpty,
      queryParams,
      loading,
      store,
      firstLoading,
      handleClick,
      getNextPageList,
      handleFocus,
      iscompare,
      loadClick,
      localResourceState,
      listType,
      visible,
      clickPopover,
      visibleId,
      getMelody,
      getList,
      iframeVisible,
      addYYLD,
      addUrl,
      backVisible,
      isColseModal,
      closePop,
      deleteVisible,
      handleDeleteLD,
      scrolling,
    };
  },
});
