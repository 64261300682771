
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onActivated,
  onMounted,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getFamousMusicSongType } from "@/services";
import store, { MutationNames } from "@/store/index";
import JsBridge from "../../jsBridge";
import { dataEmbeddingPoint } from "@/services";
import config from "@/config/app.config";
import { eventCenter, event } from "@evideo/frontend-utils";
import HomeBackground from "@/components/common/HomeBackground";

export default defineComponent({
  components: {
    HomeBackground,
  },
  setup() {
    const loading = ref(false);
    const teacherName = ref(store.state.nickName);
    const schoolName = ref(store.state.schoolName);
    const router = useRouter();
    // 是否劢联定制版
    let isML = ref(false);
    let isAndroid = ref(true);
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    let typeInfo = ref([]);
    const schoolLogo = computed(() => {
      return store.state.schoolLogo;
    });

    // 返回
    const handleGoToHome = () => {
      router.push({
        path: "/home",
      });
    };
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("goHome", (event, message) => {
        // 接收electron原生返回的信息
        console.log(123123);
        // handleGoToHome();
      });
    }
    onBeforeMount(() => {
      isML.value = store.state.isML;
      getFamousMusicSongTypeInfo();
    });
    onActivated(() => {
      // this.reload();
      // location.reload();
    });
    // 获取赏析资源类型
    const getFamousMusicSongTypeInfo = () => {
      getFamousMusicSongType({
        per_page: 9999,
      }).then((data) => {
        console.log("data", data.data.items);
        // 获取到赏析资源类型，并且提取出根节点
        let typeNum = 0;
        data.data.items.forEach((elem, index) => {
          console.log(elem, index);
          if (elem.pid === 0) {
            typeNum++;
            console.log("typeNum", typeNum);
            // 当前版本只允许添加8个，后期如超过8个需要修改样式增加左右滑动
            if (typeNum > 8) {
              return;
            }
            typeInfo.value.push(elem);
          }
        });
        console.log("elem", typeInfo.value);
      });
    };
    const goToAppreciationResources = (typeID) => {
      console.log("typeid", typeID);
      router.push({
        path: "/appreciationResources",
        query: { typeId: typeID },
      });
    };

    const goToLogin = () => {
      router.push({
        path: "/home",
      });
    };

    const goLeft = () => {
      const elem = document.getElementById("Middle");
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft += 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    const goRight = () => {
      const elem = document.getElementById("Middle");
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft -= 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    return {
      loading,
      teacherName,
      schoolName,
      goToAppreciationResources,
      goToLogin,
      goLeft,
      goRight,
      handleGoToHome,
      isML,
      typeInfo,
      schoolLogo,
    };
  },
});
