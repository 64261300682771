
import _ from "lodash";
import { computed, defineComponent, ref, watch } from "vue";
import { request } from "@/utils/request";
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      default: "",
    },
    urlSearch: {
      type: String,
      default: "",
    },
    searchParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    showName: {
      type: String,
      default: "",
    },
    showId: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    showResultList: {
      type: Boolean,
      default: true,
    },
    closeIconVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:visible", "update:resultList", "update:resultSelectedList"],
  setup(props, ctx) {
    const loading = ref(false);
    const keyBoardPlaceholder = computed(() => props.placeholder);
    const rightPanelVisible = computed(() => props.showResultList);
    const rightPanelCloseVisible = computed(() => props.closeIconVisible);

    const topNum = computed(() => props.top);
    const leftNum = computed(() => props.left);

    const fetchUrl = computed(() => props.url);
    const list = computed(() => {
      if (props.data?.length > 0) {
        return props.data;
      } else {
        return [];
      }
    });
    const searchField = computed(() => props.search);
    const nameField = computed(() => props.showName);
    const idField = computed(() => props.showId);

    const result: any = ref([]);
    const resultSelected = ref<any[]>([]);

    const selectedAlphabets = ref("");
    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");

    const appendToAlphabets = _.debounce(
      (alphabet: string) => {
        selectedAlphabets.value += alphabet;
      },
      200,
      { leading: true, trailing: false }
    );
    const clear = () => {
      selectedAlphabets.value = "";
    };
    const backspace = () => {
      selectedAlphabets.value = selectedAlphabets.value.slice(0, -1);
    };
    const closePanel = () => {
      ctx.emit("update:visible", false);
    };

    const isSelected = (item: any) => {
      return (
        resultSelected.value.findIndex(
          (s: any) => s[`${idField.value}`] === item[`${idField.value}`]
        ) >= 0
      );
    };

    const toggleSelection = (item: any) => {
      const index = resultSelected.value.findIndex(
        (s: any) => s[`${idField.value}`] === item[`${idField.value}`]
      );
      if (index !== -1) {
        const temp: any[] = [
          ...resultSelected.value.slice(0, index),
          ...resultSelected.value.slice(index + 1),
        ];
        resultSelected.value.length = 0;
        resultSelected.value.push(...temp);
      } else {
        const temp: any[] = _.cloneDeep(resultSelected.value);
        resultSelected.value.length = 0;
        resultSelected.value.push(...temp, item);
      }
      ctx.emit("update:resultSelectedList", resultSelected.value);
    };

    const filterList = async (py: string) => {
      loading.value = true;

      if (fetchUrl.value) {
        const { method, host, per_page } = props?.searchParams || {};
        const res: any = await request(method || "GET", fetchUrl.value, host, {
          per_page,
          [`${props.urlSearch}`]: py,
        });
        result.value.length = 0;
        res && result.value.push(...res.data.items);
      } else {
        if (py) {
          result.value.push(
            ...list.value.filter((data: any) =>
              data[`${searchField.value}`].includes(py)
            )
          );
        } else {
          result.value.push(...list.value);
        }
      }
      loading.value = false;
      ctx.emit("update:resultList", result.value);
    };

    watch(
      () => selectedAlphabets.value,
      (newSelectedAlphabets, oldSelectedAlphabets) => {
        if (newSelectedAlphabets !== oldSelectedAlphabets) {
          filterList(newSelectedAlphabets);
        }
      },
      { immediate: true }
    );
    return {
      loading,
      topNum,
      leftNum,
      keyBoardPlaceholder,
      rightPanelVisible,
      rightPanelCloseVisible,
      selectedAlphabets,
      alphabets,
      result,
      nameField,
      clear,
      isSelected,
      backspace,
      closePanel,
      appendToAlphabets,
      toggleSelection,
    };
  },
});
