
import _ from "lodash";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  nextTick,
} from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useStore } from "vuex";
import { pinyin } from "pinyin-pro";

import {
  getFastClassWorkRecords,
  uploadBatchFastClassWork,
  updateFastClassWork,
} from "@/services/homework";
import { OcrTag } from "@evideo/ocr-tag";
import useUpLoad from "@/hooks/useUpLoad";
import useWorker from "./hooks/useWorker";

import ReviewDetail from "./component/reviewDetail.vue";
import ReviewDetailModal from "./component/reviewDetailModal.vue";
import BatchUploadModal from "./component/batchUploadModal.vue";
import ReviewTabContent from "./component/reviewTabContent.vue";
import Confirm from "./component/confirm.vue";
import PdfResourceMatch from "./component/pdfResourceMatch.vue";
import ResultReportModal from "./component/resultReportModal.vue";

import { xlsxExport, xlsxImport } from "@/utils/xlsx";
import logger from "@evideo/logger";
import videoImg from "@/assets/images/fastClassWork/video.png";
import audioImg from "@/assets/images/fastClassWork/audio.png";
import { ReviewTypes, UploadType, FastClassWorkStatusMap } from "./dataMap";
import { StudentRecords } from "./type";
import { ElMessage } from "element-plus";
import { createRandomCode } from "./utils";
import { FastClassWorkEvent, reportStat } from "./statReport";

interface ModalObj {
  visible: boolean;
  type: string;
  title: string;
  btnTxt: string;
  multiple: boolean;
  style: string;
  acceptType: string;
  closeVisible: boolean;
  maskClose: boolean;
  btnClickFunc: () => void;
  height: string;
  close: () => void;
}

interface ModalFile {
  id: string;
  name: string;
  file: File;
  progress?: number;
  url?: string;
  md5?: string;
}

interface StudentResult {
  type: string;
  params: {
    name: string;
    url: string;
    rotate: number;
    md5: string;
  };
}
export default defineComponent({
  name: "FastClassWorkReview",
  components: {
    ReviewDetail,
    BatchUploadModal,
    ReviewTabContent,
    Confirm,
    PdfResourceMatch,
    ReviewDetailModal,
    ResultReportModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);

    let pdfParse: OcrTag | undefined = undefined;

    const { upload, createOssResource } = useUpLoad();
    const { initWorker, destroyWorker, addWorkerTask, stopWorker } =
      useWorker();

    const fastClassWorkId = ref(-1); // 当前作业id
    const scoreRate = ref(1); // 教师分数比例
    const fastClassWorkName = ref<string>(""); // 作业标题
    const gradeClassName = ref(""); // 当前班级名称

    const fastClassWorkStatus = ref(-1); // 当前作业状态
    const uploadNoAllowed = ref(false); // 当前作业是否允许老师上传学生作品
    const assessNoAllowed = ref(false); // 当前作业是否允许老师打分
    const workName: any = ref(null); // 获取作业名dom元素
    const classWorkName = ref(false); // 开启作业标题类名

    const isAllSelected = ref(false);
    const isSortByScore = ref(false); // 是否按分数排序

    const reportVisible = ref(false); // 显示作业报告

    const totalNum = ref(0); // 班级总人数
    const noAssessNum = computed(() => {
      // 已提交未评阅人数
      if (studentRecordsNoAssess.value) {
        return studentRecordsNoAssess.value.length;
      } else {
        return 0;
      }
    });
    const finishAssessNum = computed(() => {
      const finishAssessStudents =
        studentRecords.value.filter(
          (record: StudentRecords) => record.teacherAssessCount === 1
        ) || [];
      return finishAssessStudents.length;
    });
    const studentRecords = ref<StudentRecords[]>([]); // 所有学生作品记录
    const sortByScoreStudentRecords = computed(() => {
      // 按成绩排序所有学生作品记录
      if (studentRecords.value) {
        const _studentRecords: StudentRecords[] = _.cloneDeep(
          studentRecords.value
        );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return _studentRecords.sort((stuA, stuB) => stuB.score - stuA.score);
      }
      return [];
    });
    const noSubmitRecords = ref<StudentRecords[]>([]); // 用于存放类型为student_record_file且状态为撤回的学生作品记录
    const studentRecordsNoAssess = computed(() => {
      // 未评阅所有学生作品记录
      if (studentRecords.value) {
        return studentRecords.value.filter(
          (record: StudentRecords) =>
            record.teacherAssessCount === 0 && record.status === 1
        );
      }
      return [];
    });
    const studentRecordsNoSubmit = computed(() => {
      // 未提交所有学生作品记录
      if (studentRecords.value) {
        return studentRecords.value.filter(
          (record: StudentRecords) => record.status === 0
        );
      }
      return [];
    });

    const tabTile = computed(() => {
      // tab分页标题
      return {
        totalTab: "全班: " + finishAssessNum.value + "/",
        total: totalNum.value,
        noAssessTab: "已提交待评阅：" + noAssessNum.value + "人",
        noSubmit: "未提交：" + studentRecordsNoSubmit.value.length + "人",
      };
    });

    const uploadingObj = ref({
      // 当前上传类型和状态
      loading: false,
      type: "",
    });
    const batchUploadVisible = ref(false); // 批量上传作品显示
    const batchFiles = ref<any[]>([]); // 批量上传作品
    const batchUploadProps = ref({
      // 批量弹窗props
      type: UploadType.BATCH,
      classStudents: studentRecords.value,
    });

    const isShowReviewDetail = ref(false); // 显示评阅弹窗
    const recordDetailNoSubmit = ref<StudentRecords>(); // 存放学生端提交且状态未为撤回的作品
    const reviewDetailRef = ref<any>(null);
    const recordDetailProps = ref<StudentRecords | undefined>(); // 评阅弹窗props

    // const confirmWarm = ref(false); // 打分为0分时提示

    const studentExcelScores = ref<any[]>([]); // 存放excel分数
    const score = ref<number>(0); // 单个学生上传打分分数
    const fileInput = ref<any>(null); // 上传组件实例
    const defaultModalObj: ModalObj = {
      // 弹窗数据
      visible: false,
      type: UploadType.EXCEL,
      title: "",
      btnTxt: "",
      style: "",
      acceptType: "",
      multiple: false,
      closeVisible: true,
      maskClose: true,
      btnClickFunc: () => {
        return;
      },
      height: "223px",
      close: () => {
        return;
      },
    };
    const modalObj = ref<ModalObj>({ ...defaultModalObj });
    const pdfProgress = ref(0);
    const modalFiles = ref<ModalFile[]>([]); // 弹窗选中的文件
    const isShowFunctionBar = ref(false); // 显示批量操作功能
    const confirmGoBack = ref(false); // 确认路由是否返回

    const deleteConfirm = ref(false); // 删除弹窗
    const confirmVisible = ref(false); // 上传返回确认弹窗

    const pdfResult = ref<any[]>([]);
    const unKnowPdfResult = ref<any[]>([]);
    const pdfMatchModalVisible = ref(false);

    const activeName = ref("allClass");

    const beSelectedStudentId = ref();
    // el-rate星星的样式
    const iconClasses = ref([
      "el-icon-selectedStar",
      "el-icon-selectedStar",
      "el-icon-selectedStar",
    ]);
    const unSelectedStarClass = ref("el-icon-unSelectedStar");

    // 查看作业报告
    const handleShowResultReport = () => {
      reportVisible.value = !reportVisible.value;
    };

    const handleCloseConfirm = () => {
      confirmVisible.value = false;
    };

    // 学生作品按成绩排序
    const handleChangeSortByScore = () => {
      isSortByScore.value = !isSortByScore.value;
    };

    const handleGoBack = () => {
      confirmGoBack.value = true;
      router.push({
        path: "/fastClassWork",
        query: { classId: router.currentRoute.value?.query.classId },
      });
    };

    const handleUploadFile = () => {
      if (fileInput.value && !uploadingObj.value.loading) {
        fileInput.value.click();
      }
    };

    const getAnswerRecord = () => {
      return new Promise((resolve) => {
        loading.value = true;
        studentRecords.value.length = 0;
        studentRecordsNoAssess.value.length = 0;
        studentRecordsNoSubmit.value.length = 0;
        getFastClassWorkRecords({ fast_classwork_id: fastClassWorkId.value })
          .then((res: any) => {
            const { data } = res || {};
            totalNum.value = data.length;

            data?.forEach((item: any) => {
              const { result } = item;
              let poster = undefined;
              let _cloneResult = _.cloneDeep(result);
              const noSubmitResult: any[] = [];
              if (_cloneResult) {
                _cloneResult = _cloneResult
                  .map((_result: any) => {
                    // status =0 为学生作品撤销
                    if (
                      _result?.type === ReviewTypes.STUDENT_RECORD_FILE &&
                      _result?.params.status === 0
                    ) {
                      noSubmitResult.push(_result);
                      return;
                    }
                    _result.params.poster = getStudentPoster(_result);
                    return _result;
                  })
                  .filter(Boolean);
                if (_cloneResult?.[0]) {
                  poster = getStudentPoster(_cloneResult[0]);
                }
              }
              if (noSubmitResult.length > 0) {
                noSubmitRecords.value.push({
                  id: item.student_id,
                  studentWorkId: item.id,
                  name: item.student_name,
                  seatNo: item.seat_no,
                  score: item.score,
                  status: item.status,
                  teacherAssessCount: item.teacher_assess_count,
                  className: gradeClassName.value,
                  fastClassWorkId: item.fast_classwork_id,
                  result: noSubmitResult,
                  poster,
                  isSelected: false,
                  rotate:
                    result && result[0]?.params?.rotate
                      ? result[0].params.rotate
                      : 0,
                });
              }
              const namePinYin = pinyin(item.student_name, {
                pattern: "first",
                toneType: "none",
              });

              studentRecords.value.push({
                id: item.student_id,
                studentWorkId: item.id,
                name: item.student_name,
                namePinYin: namePinYin
                  ? namePinYin.split(" ").join("")
                  : namePinYin,
                seatNo: item.seat_no,
                score: item.score,
                status: item.status,
                teacherAssessCount: item.teacher_assess_count,
                className: gradeClassName.value,
                fastClassWorkId: item.fast_classwork_id,
                result: _cloneResult,
                poster,
                isSelected: false,
                rotate:
                  result && result[0]?.params?.rotate
                    ? result[0].params.rotate
                    : 0,
              });
            });

            if (isShowReviewDetail.value) {
              const currentRecord = studentRecords.value.find(
                (stu: StudentRecords) => stu.id === recordDetailProps.value?.id
              );
              if (currentRecord) {
                recordDetailProps.value = currentRecord;
                recordDetailNoSubmit.value = noSubmitRecords.value.find(
                  (student: StudentRecords) =>
                    student.id === recordDetailProps.value?.id
                );
              }
            }
            resolve("");
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            loading.value = false;
          });
      });
    };

    const closeDetail = () => {
      isShowReviewDetail.value = false;
      router.back();
      getAnswerRecord();
    };

    // 打开批量上传弹窗
    const handleOpenBatchUpload = () => {
      if (
        uploadingObj.value.loading &&
        uploadingObj.value.type !== UploadType.BATCH
      ) {
        ElMessage.info({
          offset: 200,
          message: "当前正在进行导入任务，请稍后",
          type: "info",
        });
        return;
      }
      batchUploadVisible.value = true;
      batchUploadProps.value.type = UploadType.BATCH;
    };

    const closeBatchUploadModal = () => {
      batchUploadVisible.value = false;
    };

    const handleDeleteModalFile = (_index: number) => {
      if (modalFiles.value?.[_index]) {
        const _files = modalFiles.value.filter(
          (file, index) => index !== _index
        );
        modalFiles.value.length = 0;
        modalFiles.value.push(..._files);
      }
    };

    // 选中文件处理
    const handleChangeFileList = async (event: Event) => {
      pdfProgress.value = 0;
      const { files } = (event.target as HTMLInputElement) || {};
      if (files) {
        const _files = [...files];
        if (
          modalObj.value.type === UploadType.SINGLE ||
          modalObj.value.type === UploadType.MODAL_SINGLE
        ) {
          await createOssResource();
        }
        _files.forEach((file: File) => {
          if (!modalObj.value.multiple) {
            modalFiles.value.length = 0;
          }
          const currentFile = {
            id: createRandomCode(8),
            name: file.name,
            file,
          };
          modalFiles.value.push(currentFile);

          // 不同类型上传的文件处理
          switch (modalObj.value.type) {
            case UploadType.EXCEL: {
              studentExcelScores.value.length = 0;
              xlsxImport(file)
                .then((res: any) => {
                  Object.keys(res).forEach((key) => {
                    res[key].forEach((item: any) => {
                      let score = item["点评(5星)"] || undefined;
                      if (typeof score === "number") {
                        if (score > 5) {
                          score = 5;
                        }
                        if (score <= 0) {
                          score = undefined;
                        }
                      }

                      studentExcelScores.value.push({
                        student_id: item["学生ID"],
                        teacher_assess_result_level: score,
                        name: item["学生姓名"],
                      });
                    });
                  });
                })
                .catch((e) => {
                  ElMessage.error({
                    type: "error",
                    message: e,
                    offset: 200,
                  });
                });
              break;
            }
            case UploadType.SINGLE: {
              upload(file, "fast-class-work-review", (p: number) => {
                const progress = Math.round(p * 100);
                const index = modalFiles.value.findIndex(
                  (f) => f.id === currentFile.id
                );
                if (index >= 0) {
                  modalFiles.value[index].progress = progress;
                }
              }).then((res: any) => {
                if (res.url) {
                  const index = modalFiles.value.findIndex(
                    (f) => f.id === currentFile.id
                  );
                  if (index >= 0) {
                    modalFiles.value[index].progress = 100;
                    modalFiles.value[index].url = res.url;
                    modalFiles.value[index].md5 = res.md5;
                  }
                }
              });
              break;
            }
            case UploadType.MODAL_SINGLE: {
              upload(file, "fast-class-work-review", (p: number) => {
                const progress = Math.round(p * 100);
                const index = modalFiles.value.findIndex(
                  (f) => f.id === currentFile.id
                );
                if (index >= 0) {
                  modalFiles.value[index].progress = progress;
                }
              }).then((res: any) => {
                if (res.url) {
                  const index = modalFiles.value.findIndex(
                    (f) => f.id === currentFile.id
                  );
                  if (index >= 0) {
                    modalFiles.value[index].progress = 100;
                    modalFiles.value[index].url = res.url;
                    modalFiles.value[index].md5 = res.md5;
                  }
                }
              });
              break;
            }
            case UploadType.PDF: {
              // 只解析pdf文件
              if (!file?.type.includes("pdf")) {
                ElMessage.error({
                  type: "error",
                  message: "请选择pdf文件",
                  offset: 200,
                });
                return;
              }
              break;
            }
            default:
              break;
          }
        });
      }
    };

    // pdf上传学生作品
    const handleUpdatePdfStudentRecords = () => {
      if (uploadingObj.value.loading) {
        ElMessage.info({
          offset: 200,
          message: "当前正在进行导入任务，请稍后",
          type: "info",
        });
        return;
      }
      const file = modalFiles.value[0];
      // 只解析pdf文件
      if (!file.file?.type.includes("pdf")) {
        ElMessage.error({
          type: "error",
          message: "请选择pdf文件",
          offset: 200,
        });
        return;
      }
      uploadingObj.value.loading = true;
      uploadingObj.value.type = UploadType.PDF;
      batchUploadProps.value.type = UploadType.PDF;

      if (pdfParse && file) {
        pdfResult.value.length = 0;
        unKnowPdfResult.value.length = 0;
        modalObj.value.closeVisible = false;
        pdfProgress.value = 0;
        logger.info("fastClassWork pdf name:", file.file.name);
        pdfParse
          .parse(file.file, (progress: any) => {
            const { current, total } = progress || {};
            pdfProgress.value = total ? Math.round((current / total) * 100) : 0;
          })
          .then((res: any) => {
            console.log("pdf result:", res);
            const { worksTags, unknownImages } = res;
            logger.info(
              "fastClassWork pdf success  result:",
              JSON.stringify(worksTags)
            );
            logger.info(
              "fastClassWork pdf  error result:",
              JSON.stringify(unknownImages)
            );
            const pdfWorksTags: {
              student_id: number | string;
              score: number;
            }[] = [];
            if (worksTags && worksTags?.length > 0) {
              worksTags.forEach((work: any) => {
                const { works, qrCode, star } = work;
                pdfWorksTags.push({
                  student_id: qrCode,
                  score: star,
                });
                const studentId = JSON.parse(qrCode);

                const student = studentRecords.value.find(
                  (student: StudentRecords) => student.id === +studentId
                );

                if (student) {
                  pdfResult.value.push({
                    id: createRandomCode(),
                    name: student.name,
                    status: "pending",
                    progress: 0,
                    type: "image",
                    file: new File([works], student.name + ".jpg"),
                    studentWorkId: student.studentWorkId,
                    score: star ? star : undefined,
                    fastClassWorkId: student.fastClassWorkId,
                    studentId: +studentId,
                    token: localStorage.getItem("token"),
                  });
                } else {
                  ElMessage.warning({
                    type: "warning",
                    message: "存在作品无法关联当前班级的学生",
                    offset: 200,
                  });
                }
              });
            }
            if (unknownImages && unknownImages.length > 0) {
              unKnowPdfResult.value.push(...unknownImages);
            }

            // 上传Pdf 埋点
            reportStat(FastClassWorkEvent.FASTCLASSWORK_RECORD_PDF_UPLOAD, {
              class_id: router.currentRoute.value?.query.classId,
              fastClassWork_id: fastClassWorkId.value,
              subject: router.currentRoute.value?.query.subject,
              success_num: worksTags?.length || 0,
              unknown_num: unknownImages?.length || 0,
              success_content: pdfWorksTags,
            });

            if (unKnowPdfResult.value.length > 0) {
              pdfMatchModalVisible.value = true;
            } else {
              const uploadPdfFiles: any[] = [];
              pdfResult.value.forEach((res: any) => {
                uploadPdfFiles.push({ ...res });
              });

              batchFiles.value.push(...uploadPdfFiles);
              batchUploadVisible.value = true;
              addWorkerTask(uploadPdfFiles);
            }
            modalObj.value.visible = false;
            modalFiles.value.length = 0;
          })
          .catch((e) => {
            console.error("pdf解析失败", e);
            ElMessage.error({
              type: "error",
              message: "pdf文件解析失败",
              offset: 200,
            });

            uploadingObj.value.loading = false;
          })
          .finally(() => {
            modalObj.value.closeVisible = true;
          });
      }
    };

    // excel上传学生作品
    const handleUpdateExcelStudentRecords = () => {
      uploadingObj.value.loading = true;
      uploadingObj.value.type = UploadType.EXCEL;
      modalObj.value.closeVisible = false;

      return new Promise((resolve, reject) => {
        if (studentExcelScores.value.length > 0) {
          const _list = studentExcelScores.value.map((stuScore: any) => {
            const { student_id } = stuScore || {};
            const currentStu = studentRecords.value.find(
              (stu: StudentRecords) => stu.id === student_id
            );
            if (currentStu?.result) {
              return { ...stuScore, result: currentStu.result };
            } else {
              return stuScore;
            }
          });
          // 导入接口
          uploadBatchFastClassWork({
            fast_classwork_id: fastClassWorkId.value,
            list: JSON.stringify(_list),
          })
            .then(() => {
              studentExcelScores.value.forEach((student: any) => {
                const { student_id, teacher_assess_result_level, name } =
                  student;
                const currentIndex = studentRecords.value.findIndex(
                  (stu: StudentRecords) =>
                    stu.id === student_id && stu.name === name
                );
                if (currentIndex >= 0) {
                  studentRecords.value[currentIndex].score =
                    (teacher_assess_result_level / 5) * 100 * scoreRate.value;
                }
              });

              // excel导入成功后，关闭弹窗
              modalObj.value.visible = false;
              modalFiles.value.length = 0;
              ElMessage.success({
                type: "success",
                message: "excel导入成绩成功",
                offset: 200,
              });
              // excel导入数据埋点
              const excelStatData: { student_id: number; score: number }[] = [];
              studentExcelScores.value.forEach((studentExcel: any) => {
                excelStatData.push({
                  student_id: studentExcel.student_id,
                  score: studentExcel.teacher_assess_result_level,
                });
              });
              reportStat(FastClassWorkEvent.FASTCLASSWORK_RECORD_EXCEL_UPLOAD, {
                class_id: router.currentRoute.value?.query.classId,
                subject: router.currentRoute.value?.query.subject,
                fastClassWork_id: fastClassWorkId.value,
                excel_content: excelStatData,
              });
              resolve("");
            })
            .catch((e) => {
              reject(`excel 导入学生成绩失败${e}`);
              ElMessage.error({
                type: "error",
                message: "excel导入成绩失败",
                offset: 200,
              });
            })
            .finally(() => {
              uploadingObj.value.loading = false;
              modalObj.value.closeVisible = true;
            });
        } else {
          uploadingObj.value.loading = false;
          resolve("");
        }
      });
    };

    // 单个学生上传作品
    const handleUpdateSingleStudentRecords = (records: StudentRecords) => {
      const { studentWorkId } = records;
      return new Promise((resolve, reject) => {
        if (uploadingObj.value.loading) {
          return;
        }
        // if (
        //   !confirmWarm.value &&
        //   score.value === 0 &&
        //   modalObj.value.type === UploadType.SINGLE
        // ) {
        //   confirmWarm.value = true;
        //   return;
        // }

        const result: StudentResult[] = [];

        if (records.result && records?.result?.length > 0) {
          result.push(...(records.result as any));
        }

        const singleStatResult: { type: string; url: string }[] = [];
        modalFiles.value.forEach((modalFile: any) => {
          const type = modalFile.file?.type?.split("/")?.[0] || "image";
          if (modalFile.url) {
            result.push({
              type,
              params: {
                name: modalFile.file.name,
                url: modalFile.url,
                md5: modalFile.md5,
                rotate: 0,
              },
            });
            singleStatResult.push({
              type,
              url: modalFile.url,
            });
          }
        });
        updateFastClassWork({
          id: +studentWorkId,
          teacher_assess_result_level: score.value ? score.value : undefined,
          result: result.length > 0 ? JSON.stringify(result) : undefined,
        })
          .then(() => {
            modalObj.value.visible = false;
            modalFiles.value.length = 0;

            // 评阅弹窗中的上传，需要重新获取详情数据
            // reviewDetailRef.value && reviewDetailRef.value.initRecordDetail();
            // 评阅列表重新获取数据
            getAnswerRecord();

            // 单个学生上传/打分数据埋点
            reportStat(FastClassWorkEvent.FASTCLASSWORK_RECORD_SINGLE_UPLOAD, {
              class_id: router.currentRoute.value?.query.classId,
              subject: router.currentRoute.value?.query.subject,
              fastClassWork_id: fastClassWorkId.value,
              student_work_id: +studentWorkId,
              score: score.value ? score.value : undefined,
              result:
                singleStatResult.length > 0 ? singleStatResult : undefined,
            });
            resolve("");
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    };
    // 打开单个学生弹窗
    const handleOpenHomeworkUpload = async (
      records: StudentRecords,
      type?: string
    ) => {
      if (assessNoAllowed.value) {
        ElMessage.warning({
          type: "warning",
          message: "学期已结束，当前作业不可上传/打分",
          offset: 200,
        });
        return;
      }
      if (uploadingObj.value.loading) {
        ElMessage.info({
          offset: 200,
          message: "当前正在进行导入任务，请稍后",
          type: "info",
        });
        return;
      }
      if (!records) {
        return;
      }
      const { seatNo, name } = records;
      score.value = 0;
      // confirmWarm.value = false;
      const minHeight = type === UploadType.SINGLE ? "270px" : "210px";
      Object.assign(modalObj.value, {
        ...defaultModalObj,
        type: type ? type : UploadType.SINGLE,
        visible: true,
        style: `min-height:${minHeight};top:50%;transform: translateY(-134px);`,
        title:
          type === UploadType.MODAL_SINGLE
            ? `上传作品 - ${seatNo} ${name}`
            : `导入成绩 - ${seatNo} ${name}`,
        btnTxt: "确定",
        acceptType: ".png,.jpg,.jpeg,.mp4,.mov,.mp3,.wav,.ogg,.acc",
        multiple: true,
        maskClose: false,
        close: () => {
          modalObj.value.visible = false;
          modalFiles.value.length = 0;
        },

        btnClickFunc: () => handleUpdateSingleStudentRecords(records),
      });
    };

    const pdfTipsVisible = ref(false);
    // 点击问号打开提示弹窗
    const handleOpenQuestion = (e: Event) => {
      e.stopPropagation();
      pdfTipsVisible.value = true;
    };
    const closePdfTipsModal = () => {
      pdfTipsVisible.value = false;
    };
    // 打开pdf导入弹窗
    const handleOpenPdfUpload = () => {
      if (
        uploadingObj.value.loading &&
        uploadingObj.value.type !== UploadType.PDF
      ) {
        ElMessage.info({
          offset: 200,
          message: "当前正在进行导入任务，请稍后",
          type: "info",
        });
        return;
      }

      if (
        uploadingObj.value.loading &&
        uploadingObj.value.type === UploadType.PDF
      ) {
        batchUploadProps.value.type = UploadType.PDF;
        batchUploadVisible.value = true;
        return;
      }
      Object.assign(modalObj.value, {
        ...defaultModalObj,
        visible: true,
        type: UploadType.PDF,
        title: "导入PDF",
        btnTxt: "导入",
        style: "",
        acceptType: ".pdf",
        multiple: false,
        close: () => {
          if (!modalObj.value.closeVisible) {
            return;
          }
          modalObj.value.visible = false;
          modalFiles.value.length = 0;
        },
        btnClickFunc: handleUpdatePdfStudentRecords,
      });
    };

    // 打开excel导入弹窗
    const handleOpenExcelUpload = () => {
      if (
        uploadingObj.value.loading &&
        uploadingObj.value.type !== UploadType.EXCEL
      ) {
        ElMessage.info({
          offset: 200,
          message: "当前正在进行导入任务，请稍后",
          type: "info",
        });
        return;
      }
      if (uploadingObj.value.loading) {
        return;
      }
      Object.assign(modalObj.value, {
        ...defaultModalObj,
        visible: true,
        type: UploadType.EXCEL,
        title: "导入成绩",
        btnTxt: "导入",
        style: "",
        acceptType: ".xlsx",
        multiple: false,
        close: () => {
          if (!modalObj.value.closeVisible) {
            return;
          }
          modalObj.value.visible = false;
          modalFiles.value.length = 0;
        },
        btnClickFunc: handleUpdateExcelStudentRecords,
      });
    };

    const goToReviewDetail = (record: StudentRecords) => {
      const { id } = record;
      beSelectedStudentId.value = id;
      isShowReviewDetail.value = true;
      recordDetailProps.value = record;
      recordDetailNoSubmit.value = noSubmitRecords.value.find(
        (student: StudentRecords) => student.id === record.id
      );
      history.pushState({ reviewDetail: true }, "");
    };
    const changeShowFunctionBar = () => {
      isShowFunctionBar.value = !isShowFunctionBar.value;
      isAllSelected.value = false;
    };

    // 学生作品封面
    const getStudentPoster = (data: any) => {
      const { type, params } = data;
      let imgUrl = "";
      switch (type) {
        case ReviewTypes.IMAGE:
          imgUrl = params?.url
            ? params.url + "?x-oss-process=image/resize,w_200"
            : "";
          break;
        case ReviewTypes.AUDIO:
          imgUrl = audioImg;
          break;
        case ReviewTypes.VIDEO:
          imgUrl = params?.url
            ? params.url +
              "?x-oss-process=video/snapshot,t_0,f_jpg,w_400,m_fast,ar_auto"
            : videoImg;
          break;
        case ReviewTypes.STUDENT_RECORD_FILE:
          imgUrl = params?.url
            ? params.url +
              "?x-oss-process=video/snapshot,t_0,f_jpg,w_400,m_fast,ar_auto"
            : videoImg;
          break;
        default:
          imgUrl = "";
          break;
      }
      return imgUrl;
    };

    // 更新学生作品
    const updateStudentRecords = (id: number, updateObj: any) => {
      const { result, score } = updateObj || {};
      const stuIndex = studentRecords.value.findIndex(
        (stu: StudentRecords) => stu.id === id
      );

      if (stuIndex >= 0) {
        score &&
          (studentRecords.value[stuIndex].score =
            (score / 5) * 100 * scoreRate.value);
        studentRecords.value[stuIndex].status = 1;

        // 根据md5判断学生作品是否存在，存在则不继续叠加
        const _cloneResult =
          _.cloneDeep(studentRecords.value[stuIndex].result) || [];
        if (!_cloneResult?.length) {
          studentRecords.value[stuIndex].result = result;
        } else {
          const _result = Array.isArray(result) ? result : [result];
          _result.forEach((res: StudentResult) => {
            const isExit = _cloneResult.find(
              (_r: StudentResult) => _r?.params?.md5 === res.params.md5
            );
            if (!isExit) {
              _cloneResult.push(res);
            }
          });
          studentRecords.value[stuIndex].result = _cloneResult;
        }

        if (
          studentRecords.value[stuIndex]?.result &&
          studentRecords.value[stuIndex]?.result?.[0]
        ) {
          const poster = getStudentPoster(
            studentRecords.value[stuIndex].result?.[0]
          );
          studentRecords.value[stuIndex].poster = poster;
        }
      }
    };

    // 删除选中作品
    const deleteModalFiles = (currentIndex: number) => {
      const _modalFiles = modalFiles.value
        .filter((file, index) => index !== currentIndex)
        .filter(Boolean);
      modalFiles.value.length = 0;
      modalFiles.value.push(..._modalFiles);
      studentExcelScores.value.length = 0;
    };

    const checkoutSelected = (id: number) => {
      studentRecords.value.forEach((e: StudentRecords) => {
        if (e.id === id) {
          e.isSelected = !e.isSelected;
        }
      });
    };
    const checkoutAllSelected = () => {
      if (isAllSelected.value) {
        studentRecords.value.forEach((e: StudentRecords) => {
          if (e.result && e.result?.length > 0) {
            e.isSelected = false;
          }
        });
      } else {
        studentRecords.value.forEach((e: StudentRecords) => {
          if (e.result && e.result?.length > 0) {
            e.isSelected = true;
          }
        });
      }
      isAllSelected.value = !isAllSelected.value;
    };

    const openConfirm = () => {
      deleteConfirm.value = true;
    };

    const passConfirm = async () => {
      await deleteWork();
    };

    const deleteWork = () => {
      const list: any = [];
      loading.value = true;
      studentRecords.value.forEach((student: StudentRecords) => {
        if (student.isSelected) {
          student.result = [];
          // 然后调接口
          list.push({
            student_id: student.id,
            result: [],
          });
          student.isSelected = false;
        } else {
          const result = student?.result || [];
          // 学生端提交的作品中状态可能是未提交，也需要一并提交
          const studentRecordsNoSubmit = noSubmitRecords.value.find(
            (_student: StudentRecords) => _student.id === student.id
          );
          if (studentRecordsNoSubmit && studentRecordsNoSubmit?.result) {
            result.push(...studentRecordsNoSubmit.result);
          }
          list.push({
            student_id: student.id,
            result,
          });
        }
      });

      uploadBatchFastClassWork({
        fast_classwork_id: fastClassWorkId.value,
        list: JSON.stringify(list),
      })
        .then(() => {
          getAnswerRecord();
          deleteConfirm.value = false;
          ElMessage.success({
            type: "success",
            message: "删除作品成功",
            offset: 200,
          });
        })
        .catch((err) => {
          console.error(err);
          ElMessage.error({
            type: "error",
            message: "删除作品失败",
            offset: 200,
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const rotate = _.debounce((deg: number) => {
      let params: any;
      let list: any = [];
      studentRecords.value.forEach((student: StudentRecords) => {
        if (student.isSelected) {
          let listItem: any = {};
          const result: any = [];
          listItem.student_id = student.id;
          student.result?.forEach((ele: any) => {
            ele.params.rotate =
              ele.params.rotate + deg < 0
                ? 360 + (ele.params.rotate + deg)
                : (ele.params.rotate + deg) % 360;
            let resultItem: any = {};
            resultItem.type = ele.type;
            resultItem.params = {
              name: ele.params.name,
              url: ele.params.url,
              rotate: ele.params.rotate,
            };
            result.push(resultItem);
          });

          // 学生端提交的作品中状态可能是未提交，也需要一并提交
          const studentRecordsNoSubmit = noSubmitRecords.value.find(
            (_student: StudentRecords) => _student.id === student.id
          );
          if (studentRecordsNoSubmit && studentRecordsNoSubmit?.result) {
            result.push(...studentRecordsNoSubmit.result);
          }

          listItem.result = result;
          list.push(listItem);
        }
      });
      params = {
        fast_classwork_id: fastClassWorkId.value,
        list: JSON.stringify(list),
      };
      uploadBatchFastClassWork(params).catch((err) => {
        console.error(err);
      });
    }, 200);

    // 当前班级excel模板下载
    const handleDownloadExcel = () => {
      const sheetData: any[] = [];
      sheetData.push(["学生ID", "班级", "学生座号", "学生姓名", "点评(5星)"]);
      studentRecords.value.forEach((student: StudentRecords) => {
        const { id, className, seatNo, name, score } = student;
        sheetData.push([
          id,
          className,
          seatNo,
          name,
          (score / 100) * 5 === 0 ? "" : (score / 100) * 5,
        ]);
      });
      xlsxExport(sheetData, gradeClassName.value + "成绩导入模板.xlsx");
      ElMessage.success({
        type: "success",
        message: "导出excel模板成功",
        offset: 200,
      });
      return;
    };

    // 初始化pdf解析
    const initPdfParse = () => {
      console.time("initPdfParse");
      const env = process.env.RELEASE_ENV ? process.env.RELEASE_ENV : "devp";
      const host = `${env === "prod" ? "" : env}gateway.jilingshu.com.cn.tech`;
      pdfParse = new OcrTag({
        host,
        token: store.state.token,
        workerPath: `https://${
          env === "prod" ? "" : env
        }multi-platform.jilingshu.com.cn/js/pdf.worker.min.js`,
        // workerPath: `http://localhost:8080/js/pdf.worker.min.js`,
      });
      console.timeEnd("initPdfParse");
    };

    const nextStudentRecord = () => {
      const currenTabStudentRecords =
        activeName.value === "allClass"
          ? studentRecords.value
          : activeName.value === "waitReview"
          ? studentRecordsNoAssess.value
          : studentRecordsNoSubmit.value;

      const index = currenTabStudentRecords.findIndex(
        (record: StudentRecords) => {
          return record.id === beSelectedStudentId.value;
        }
      );
      if (index === -1) {
        return false;
      }
      if (index >= currenTabStudentRecords.length - 1) {
        return false;
      } else {
        beSelectedStudentId.value = currenTabStudentRecords[index + 1].id;
        recordDetailProps.value = currenTabStudentRecords[index + 1];
        recordDetailNoSubmit.value = noSubmitRecords.value.find(
          (student: StudentRecords) =>
            student.id === recordDetailProps.value?.id
        );
        return true;
      }
    };

    const previousStudentRecord = () => {
      const currenTabStudentRecords =
        activeName.value === "allClass"
          ? studentRecords.value
          : activeName.value === "waitReview"
          ? studentRecordsNoAssess.value
          : studentRecordsNoSubmit.value;
      const index = currenTabStudentRecords.findIndex(
        (record: StudentRecords) => {
          return record.id === beSelectedStudentId.value;
        }
      );
      if (index <= 0) {
        return false;
      } else {
        beSelectedStudentId.value = currenTabStudentRecords[index - 1].id;
        recordDetailProps.value = currenTabStudentRecords[index - 1];
        recordDetailNoSubmit.value = noSubmitRecords.value.find(
          (student: StudentRecords) =>
            student.id === recordDetailProps.value?.id
        );
        return true;
      }
    };

    watch(
      () => uploadingObj.value.loading,
      (newVal) => {
        if (!newVal) {
          uploadingObj.value.type = "";
          confirmVisible.value = false;
          if (modalObj.value.type !== UploadType.BATCH) {
            getAnswerRecord();
          }
        }
      }
    );
    watch(
      () => pdfMatchModalVisible.value,
      () => {
        if (!pdfMatchModalVisible.value) {
          Object.assign(modalObj.value, defaultModalObj);
          uploadingObj.value.loading = false;
          if (pdfResult.value.length > 0) {
            batchFiles.value.push(...pdfResult.value);

            const uploadPdfFiles: any[] = [];
            pdfResult.value.forEach((res: any) => {
              uploadPdfFiles.push({ ...res });
            });

            addWorkerTask(uploadPdfFiles);
            batchUploadVisible.value = true;
          }
        }
      }
    );

    const handleChangeHistoryState = (event: any) => {
      const { current } = history?.state || event?.state;
      // 若评阅弹窗开启，点击返回先关闭评阅弹窗
      if (current && current.includes("/fastClassWork/review?")) {
        isShowReviewDetail.value = false;
      }
    };

    onMounted(async () => {
      console.time("onMounted");
      loading.value = true;
      const {
        classId,
        id,
        name,
        status,
        className,
        teacherScoreRate,
        _uploadNoAllowed,
        _assessNoAllowed,
      } = router.currentRoute.value?.query || {};

      if (!classId) {
        console.error("classId is not found");
        return;
      }
      if (!id) {
        console.error("homework id is not found");
        return;
      }
      className && (gradeClassName.value = className as string);
      name && (fastClassWorkName.value = name as string);
      teacherScoreRate && (scoreRate.value = +teacherScoreRate as number);
      id && (fastClassWorkId.value = +id as number);

      _uploadNoAllowed && (uploadNoAllowed.value = _uploadNoAllowed === "1");
      _assessNoAllowed && (assessNoAllowed.value = _assessNoAllowed === "1");
      status && (fastClassWorkStatus.value = Number(status));

      await nextTick();
      if (workName.value.clientWidth > 1600) {
        classWorkName.value = true;
      }
      createOssResource();
      initPdfParse();
      getAnswerRecord();
      initWorker();
      console.timeEnd("onMounted");

      window.addEventListener("popstate", handleChangeHistoryState);
    });
    onBeforeUnmount(() => {
      destroyWorker();
      window.removeEventListener("popstate", handleChangeHistoryState);
    });
    onBeforeRouteLeave((to, from, next) => {
      // if (isShowReviewDetail.value) {
      //   isShowReviewDetail.value = false;
      //   next(false);
      //   return;
      // }
      if (uploadingObj.value.loading && !confirmGoBack.value) {
        console.log("当前正在进行上传任务");
        confirmVisible.value = true;
        confirmGoBack.value = false;
        next(false);
      } else {
        next(true);
      }
    });
    return {
      loading,
      tabTile,
      modalObj,
      fileInput,
      UploadType,
      batchFiles,
      modalFiles,
      fastClassWorkId,
      uploadingObj,
      confirmVisible,
      uploadNoAllowed,
      assessNoAllowed,
      reviewDetailRef,
      recordDetailProps,
      batchUploadVisible,
      studentRecords,
      sortByScoreStudentRecords,
      recordDetailNoSubmit,
      studentRecordsNoAssess,
      studentRecordsNoSubmit,
      batchUploadProps,
      gradeClassName,
      fastClassWorkName,
      activeName,
      isShowReviewDetail,
      pdfTipsVisible,
      pdfMatchModalVisible,
      reportVisible,
      handleShowResultReport,
      pdfResult,
      isSortByScore,
      handleChangeSortByScore,
      pdfProgress,
      unKnowPdfResult,
      getAnswerRecord,
      closePdfTipsModal,
      handleGoBack,
      deleteModalFiles,
      stopWorker,
      addWorkerTask,
      closeDetail,
      handleUploadFile,
      handleCloseConfirm,
      closeBatchUploadModal,
      handleChangeFileList,
      handleOpenQuestion,
      handleOpenPdfUpload,
      handleOpenExcelUpload,
      handleOpenBatchUpload,
      goToReviewDetail,
      beSelectedStudentId,
      handleDeleteModalFile,
      isShowFunctionBar,
      changeShowFunctionBar,
      handleOpenHomeworkUpload,
      score,
      checkoutSelected,
      isAllSelected,
      checkoutAllSelected,
      handleDownloadExcel,
      updateStudentRecords,
      rotate,
      nextStudentRecord,
      previousStudentRecord,
      openConfirm,
      passConfirm,
      deleteConfirm,
      iconClasses,
      unSelectedStarClass,
      workName,
      classWorkName,
      fastClassWorkStatus,
      FastClassWorkStatusMap,
    };
  },
});
