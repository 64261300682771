
import {
  defineComponent,
  ref,
  onBeforeMount,
  onActivated,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const router = useRouter();
    const isAndroid = ref(true);
    const device = navigator.userAgent;
    let mySwiper = ref(null);
    let num = ref(1);
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    // let teachMaterialType = ref("official");
    const onTouchEnd = (swiper, event) => {
      logger.info(swiper.swipeDirection);
      logger.info(event);
    };
    const onSlidePrevTransitionEnd = () => {
      logger.info("pre");
    };
    const onSlideNextTransitionEnd = () => {
      logger.info("next");
    };
    const onSwiper = (swiper) => {
      logger.info(swiper);
    };
    const onSlideChange = () => {
      logger.info("slide change", mySwiper.value);
    };

    onMounted(async () => {
      init();
    });
    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
      // mySwiper.value = new Swiper(".swiper", {
      //   on: {
      //     touchStart: function (swiper, event) {
      //       alert("事件触发了;");
      //     },
      //   },
      // });
    };

    const next = () => {
      if (num.value === 1) {
        ElMessage.info({
          offset: 200,
          message: "第一页",
          type: "info",
        });
      } else {
        num.value--;
        mySwiper.value.slidePrev();
      }
    };
    const previous = () => {
      if (num.value === 5) {
        ElMessage.info({
          offset: 200,
          message: "最后一页",
          type: "info",
        });
      } else {
        num.value++;
        mySwiper.value.slideNext();
      }
    };
    return {
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      onTouchEnd,
      num,
      next,
      previous,
      loading,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
});
