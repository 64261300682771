
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import store from "@/store/index";
import { MutationNames } from "@/store/index";
import { getKefuWechatQRCode } from "@/services";
import { ref, onBeforeMount } from "vue";
export default {
  props: {
    mainInfo: {
      type: String,
      default: "未找到相关资源",
    },
    tipInfo: {
      type: String,
      default: "请扫码联系魔欢平台反馈所需资源",
    },
  },
  components: {
    vueQr,
  },
  setup() {
    let getResourceEmptyUrl = ref();
    let showLoadingResult = ref("isLoading");
    // const qrcodeObj = {
    //   text: store.state.pcRequestEmptyQrCodeUrl,
    // };
    const reloadKefuQRCode = () => {
      showLoadingResult.value = "isLoading";
      setTimeout(async () => {
        await getKefuWechatQRCode().then((res) => {
          getResourceEmptyUrl.value = res.data.qrcode_url;
          showLoadingResult.value = "QRCode";
          store.commit(
            MutationNames.CHANGE_PCREQUESTEMPTYQRCODEURL,
            getResourceEmptyUrl.value
          );
        });
      }, 500);
    };
    onBeforeMount(() => {
      if (store.state.pcRequestEmptyQrCodeUrl) {
        getResourceEmptyUrl.value = store.state.pcRequestEmptyQrCodeUrl;
        showLoadingResult.value = "QRCode";
      }
      setTimeout(async () => {
        await getKefuWechatQRCode().then((res) => {
          getResourceEmptyUrl.value = res.data.qrcode_url;
          showLoadingResult.value = "QRCode";
          store.commit(
            MutationNames.CHANGE_PCREQUESTEMPTYQRCODEURL,
            getResourceEmptyUrl.value
          );
        });
      }, 500);
    });
    return {
      getResourceEmptyUrl,
      showLoadingResult,
      reloadKefuQRCode,
    };
  },
};
