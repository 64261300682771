
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import logger from "@evideo/logger";

export default defineComponent({
  setup() {
    const route = useRoute();
    const skinType = computed(() => {
      logger.info("skinType", route.query.skinType);
      return `blank-skinType-${route.query.skinType || 1}`;
    });
    onMounted(async () => {
      // 教材列表页通知Android隐藏webview
      logger.info("教材列表页通知Android隐藏webview");
      setTimeout(() => {
        window.WebViewJavascriptBridge.callHandler(
          "closeTeachMaterial", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }, 100);
    });
    return {
      skinType,
    };
  },
});
