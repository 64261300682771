import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-21e33441")
const _hoisted_1 = { class: "tag-select" }
const _hoisted_2 = ["onClick"]
_popScopeId()

import { PropType, ref, watch } from "vue";
type TagSelectValue = string | number | string[] | number[];


export default _defineComponent({
  props: {
  tags: {
    type: Array as PropType<
      {
        label: string;
        value: TagSelectValue;
      }[]
    >,
    required: true,
  },
  tagValue: {
    type: Object as PropType<TagSelectValue>,
  },
},
  emits: ["update:tagValue"],
  setup(__props, { emit: emits }) {

const props = __props




const _tagValue = ref<TagSelectValue | undefined>(props.tagValue);

watch(
  () => _tagValue.value,
  (newVal) => {
    emits("update:tagValue", newVal);
  }
);

const onTagClick = (tagValue: TagSelectValue) => {
  _tagValue.value = tagValue;
};

const isTagItemActive = (value: any) => {
  const isValueArray = Array.isArray(value);
  const isTagValueArray = Array.isArray(_tagValue.value);
  if (!isValueArray && !isTagValueArray) {
    return _tagValue.value === value;
  }
  if (
    (!isValueArray && isTagValueArray) ||
    (isValueArray && !isTagValueArray)
  ) {
    return false;
  }
  return value.every((currentValue: any, index: number) => {
    return currentValue === (_tagValue.value as any)[index];
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tags, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tag-item", {
        'tag-item-active': isTagItemActive(item.value),
      }]),
        key: item.label,
        onClick: ($event: any) => (onTagClick(item.value))
      }, _toDisplayString(item.label), 11, _hoisted_2))
    }), 128))
  ]))
}
}

})