
import SoftwareControlBar from "@/components/softwareControlBar.vue";

interface School {
  school_id: number;
  school_name: string;
  school_logo?: string;
}

import _ from "lodash";
import { defineComponent, computed, ref } from "vue";
import { ElMessage } from "element-plus";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useStore } from "vuex";
import servers from "@/config/servers";
import SearchKeyboard from "@/components/SearchKeyboard.vue";
import useLogin from "@/hooks/useLoginNextNext";

import AuthorizedModal from "./components/AuthorizedModal.vue";

import { AuthorizedErrorObj } from "./type";

export default defineComponent({
  name: "ChooseSchool",
  components: { SoftwareControlBar, SearchKeyboard, AuthorizedModal },
  setup() {
    const router = useRouter();
    const searchKeyboardRef = ref<any>();
    const searchLoading = computed(() => {
      if (searchKeyboardRef.value) {
        return searchKeyboardRef.value.loading;
      } else {
        return false;
      }
    });
    const store = useStore();
    const { checkAuthorization, switchSchool } = useLogin();
    const isML = computed(() => store.state.isML);
    const searchKeyboardUrl = ref("/dingtalk/supportLoginSchools");
    const searchUrlParams = {
      method: "GET",
      host: servers.common,
      per_page: 10,
    };
    const schoolList = ref<School[]>([]);

    const emptySelected = {
      school_id: -1,
      school_name: "",
      school_logo: "",
    };

    const selectedSchool = ref<School>({ ...emptySelected });

    const authorizeVisible = ref(false);
    const authorizeObj = ref<AuthorizedErrorObj>();

    const handleSelectSchool = (school: School) => {
      if (selectedSchool.value.school_id === school.school_id) {
        selectedSchool.value = { ...emptySelected };
      } else {
        selectedSchool.value = school;
      }
    };
    const goToLogin = _.debounce(() => {
      return new Promise((resolve, reject) => {
        if (selectedSchool.value.school_id === -1) {
          ElMessage.warning({
            type: "warning",
            message: "请选择学校",
            offset: 200,
          });
          resolve("");
          return;
        }
        checkAuthorization(selectedSchool.value.school_id)
          .then(() => {
            localStorage.setItem(
              "lastDingSchool",
              JSON.stringify({
                school_id: selectedSchool.value.school_id,
                school_name: selectedSchool.value.school_name,
                school_logo: selectedSchool.value?.school_logo,
              })
            );
            router.replace({
              path: "/login",
              query: {
                schoolId: selectedSchool.value.school_id,
                schoolName: selectedSchool.value.school_name,
              },
            });
            resolve("");
          })
          .catch((errRes: any) => {
            const { result, message } = errRes || {};
            if (result?.enable === false) {
              authorizeVisible.value = true;
              authorizeObj.value = {
                type: result?.auth_result_type,
                message: result?.message || "",
              };
            }
            console.error(message);
            reject("");
          });
      });
    }, 150);
    onBeforeRouteLeave((to, from, next) => {
      if (to?.name !== "Login") {
        next(false);
        router.replace("./login");
        return;
      } else {
        next(true);
        return;
      }
    });

    return {
      isML,
      searchKeyboardRef,
      searchLoading,
      searchKeyboardUrl,
      schoolList,
      selectedSchool,
      goToLogin,
      searchUrlParams,
      authorizeVisible,
      authorizeObj,
      handleSelectSchool,
    };
  },
});
