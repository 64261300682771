import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-14bae03c")
const _hoisted_1 = { class: "pace-practice-game-view" }
const _hoisted_2 = { class: "game-loading" }
_popScopeId()

import { onBeforeUnmount, onMounted, onUnmounted, ref } from "vue";

import { loadMicroApp } from "qiankun";
import { eventCenter, event } from "@evideo/frontend-utils";
import config from "@/config/app.config";
import servers from "@/config/servers";
import { useStore } from "vuex";
import { setMicroAppMap, deleteMicroAppMap } from "@/main";
import _ from "lodash";
import { useRouter } from "vue-router";
import { setWindowEnv, GameMessageSuccess, GameMessageData } from "@/utils";
import { dataEmbeddingPoint } from "@/services";
import moment from "moment";


export default _defineComponent({
  setup(__props) {

const NAME_SPACE = "pace-practice-game";

const gameContainer = ref<any>();
const gamLoading = ref<boolean>(true);

const store = useStore();

const router = useRouter();

const gameApp = ref<any>();

const burialPointConfig = {
  uid: store.state.pcUserIdAES,
  ct: "96",
  ci: store.state.mac,
  mac: store.state.mac,
  sv: store.state.version,
  fv: store.state.hardwareModel,
  pt: "pc",
};

const gameConfig = {
  token: store.state.token,
  theme:
    JSON.stringify(process.env.RELEASE_ENV) !== "production" &&
    localStorage.getItem("pace-practice-game-theme")
      ? localStorage.getItem("pace-practice-game-theme")
      : "youth",
  burialPointConfig,
  // cardType: "num-musical-notation",
  // beatType: "two-four-beat",
  // playMode: "BEAT",
  // bpm: 200,
  // songInfo: {
  //   name: "歌曲2",
  //   id: "111",
  //   url: "https://devppublic.jilingshu.com.cn.tech/resource_center/teacher/84/20220425/-vc-upload-1650870240873-2.mp3",
  //   tag: "sheetMusic",
  // },
};

const uploadPlayGame = () => {
  const config = {
    ...burialPointConfig,
    eventid: "teaching_pacePracticeGame_startGame",
    pageid: "pacePracticeGame",
    moduleid: "teaching",
    ca: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    eventattr: { name: "pacePracticeGame" },
  };
  const body = encodeURIComponent(JSON.stringify(config));
  dataEmbeddingPoint(body);
};

const handleSendGameConfig = () => {
  eventCenter.send(
    event.CONFIG_PACE_PRACTICE_GAME_INFO || `${NAME_SPACE}:app-config`,
    GameMessageSuccess(gameConfig)
  );
};

const handleGameLuanchOk = () => {
  handleSendGameConfig();
};

const loadGame = async () => {
  const env = JSON.stringify(process.env.RELEASE_ENV);
  let entryUrl = `${config[servers.musicGame]}/pace-practice-game`;
  if (env === `"devp"` && localStorage.getItem("gameUrl") !== null) {
    entryUrl = localStorage.gameUrl;
  }

  eventCenter.sub(`${NAME_SPACE}:luanch-ok`, (data: GameMessageData) => {
    if (data.errorCode === 0) {
      handleGameLuanchOk();
    }
  });
  eventCenter.sub(`${NAME_SPACE}:load-ok`, (data: GameMessageData) => {
    if (data.errorCode === 0) {
      setTimeout(() => {
        gamLoading.value = false;
      }, 500);
    }
  });
  gameApp.value = await loadMicroApp(
    {
      name: "pacePracticeGamePC",
      entry: entryUrl,
      container: "#game-view-container",
      props: {
        args: {
          token: store.state.token,
          appName: "teach-webview",
          runtime: {},
        },
      },
      micrAppUrl: entryUrl,
    },
    {
      sandbox: { experimentalStyleIsolation: true },
    }
  );
  setMicroAppMap("pace-practice-game", gameApp.value);
};

const goBack = _.throttle(() => {
  router.replace({
    path: "/gameCenter",
  });
}, 5000);

onMounted(async () => {
  // 挂载平台标识，用于子系统判断自己所处的平台
  setWindowEnv();
  uploadPlayGame();
  await loadGame();
});

onBeforeUnmount(async () => {
  if (gameApp.value) {
    if (gameApp.value.preUnmount) {
      await gameApp.value.preUnmount();
    }
    await gameApp.value.unmount();
    deleteMicroAppMap("pace-practice-game");
  }
});
onUnmounted(async () => {
  if (gameApp.value) {
    if (gameApp.value.preUnmount) {
      await gameApp.value.preUnmount();
    }
    await gameApp.value.unmount();
    deleteMicroAppMap("pace-practice-game");
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, "游戏加载中,请稍后...", 512), [
      [_vShow, gamLoading.value]
    ]),
    _withDirectives(_createElementVNode("div", {
      id: "game-view-container",
      ref: gameContainer,
      class: "game-container"
    }, null, 512), [
      [_vShow, !gamLoading.value]
    ])
  ]))
}
}

})