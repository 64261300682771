import { OssResource, ProgressFunc } from "@evideo/oss-resource";
import { useStore } from "vuex";
import BMF from "browser-md5-file";
import servers from "@/config/servers";
import config from "@/config/app.config";
import _ from "lodash";

export interface UpLoadReply {
  url: string;
  md5: string;
  size: number;
  fileName?: string;
}
let ossResource: OssResource | null;
let updateTime: any = undefined;

export default () => {
  const store = useStore();

  const md5 = (file: File): Promise<string> => {
    const bmf = new BMF();
    return new Promise((resolve, reject) => {
      bmf.md5(file, (err: Error, md5: string) => {
        if (err) {
          reject(err);
        }
        resolve(md5);
      });
    });
  };

  const encodeFileName = (fileName: string) => {
    return fileName
      .trim()
      .replace(/ /g, "")
      .replace(/\(/g, "（")
      .replace(/\)/g, "）");
  };

  const createOssResource = async (
    token?: string
  ): Promise<undefined | OssResource> => {
    if (!(store?.state?.token || token)) {
      return;
    }
    // oss token 过期为1小时，过期后重新获取 3600000
    if (ossResource && Date.now() - updateTime < 3600000) {
      return;
    } else {
      ossResource &&
        updateTime &&
        console.log(
          "oss token  已过期：",
          new Date(updateTime).toString(),
          new Date()
        );
    }

    ossResource = new OssResource({
      host: config[servers.resource]?.replace(/https:\/\//g, "") || "",
      token: store?.state?.token || token,
      retryMax: 3,
    });

    if (ossResource) {
      await ossResource.init();
      updateTime = _.cloneDeep(Date.now());
      console.log("oss init token time:", updateTime, new Date());
    }
    console.log("createOssResource, ossResource initialized successfully");
  };

  const destroyOssResource = () => {
    ossResource && (ossResource = null);
  };

  const upload = async (
    file: File,
    keyName?: string,
    progress?: ProgressFunc
  ): Promise<UpLoadReply> => {
    await createOssResource();
    const size = file.size;

    // 计算文件md5
    const md5String = await md5(file);

    const _keyName = keyName ? "" : keyName + "/";

    // 上传资源的key
    const key = _keyName + `${md5String}/${encodeFileName(file.name)}`;
    // 上传资源
    let url = "";

    if (ossResource) {
      url = (await ossResource
        .upload(key, file, { cache: true, progress })
        .catch((err) => {
          console.error("上传失败", err);
          throw new Error(err);
        })) as string;
    }

    return {
      url,
      md5: md5String,
      size,
    };
  };
  return {
    upload,
    createOssResource,
    destroyOssResource,
  };
};
