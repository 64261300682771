import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1fcae620")
const _hoisted_1 = {
  key: 0,
  class: "music-melody"
}
_popScopeId()

import MusicRhythm from "@evideo/design-vue/lib/music-rhythm";
import "@evideo/design-vue/lib/music-rhythm/style";
// import { useUserStore } from "@/store/user";
import { ref, onBeforeMount, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store/index";
import { getCacheFile } from "@/services/localResource";
import { commonGetRequest } from "@/utils/request";
import { getUserMelodyFileInfo } from "@/services/rhythm";
import { MutationNames } from "@/store/index";

export default {
  setup(__props) {

// import { getAllMelodyGesture, queryUserMelodyDetail } from "@/services/melody";
const route = useRoute();
const router = useRouter();
// const store = useUserStore();

const id = ref(0);
const platform = ref("");
const isCreate = ref(false);
const contentLoading = ref(true);
const gestureConfig = ref([]);
const info = ref(null); // 律动详情
const mp3Url = ref(null); // 当前播放的mp3 url
const jsonFileContent = ref(null); // json内容
const musicRhythmData = ref(null); //
const scaleNum = ref(0.56); // 缩放比例
const queryParams = ref({
  name: "",
  mp3_file_url: "",
  mp3_file_size: "",
  mp3_file_md5: "",
  type: "1",
}); // 提交参数

const player = ref();
const musicRhythmRef = ref();
const compareVersion = (v1, v2) => {
  // -1|v2大于v1；0|相同；1|v2小于v1
  v1 = v1.split(".");
  v2 = v2.split(".");
  const len = Math.max(v1.length, v2.length);
  while (v1.length < len) {
    v1.push("0");
  }
  while (v2.length < len) {
    v2.push("0");
  }
  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i]);
    const num2 = parseInt(v2[i]);
    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
};
const isLocalCacheData = (data) => {
  return getCacheFile(JSON.stringify(data));
};
const iscompare = compareVersion(store.state.version, "1.4.3"); // 对比是否满足新要版本
const localResourceState = computed(() => store.state.localResourceState);
const getData = async () => {
  if (iscompare >= 0 && localResourceState.value) {
    const { id, version_time, type, source } = route.query;
    const selectSong = [
      {
        cloud_id: id,
        version_time: version_time,
        type: type,
        source: source,
      },
    ];
    const LocalCacheData = await isLocalCacheData({ items: selectSong });
    // 拿到本地的url
    const res = await fetch(LocalCacheData.data[0].url);
    info.value = await res.json();
  } else {
    const { data } = await getUserMelodyFileInfo(route.params.id);
    info.value = data;
  }
  try {
    info.value.json_file_data = await commonGetRequest(
      info.value.json_file_url
    );
  } catch (e) {
    console.error("获取音乐律动json文件异常:", e);
    ElMessage.error({
      message: "音乐律动打开异常，请重试",
      offset: 200,
      type: "error",
    });
  }
  // actionLoading.value = false;
  // // 图片加载好了
  // isReadyPhoto.value = false;
};

watch(
  () => info.value,
  async (val) => {
    const jsonResp = await fetch(
      `${info.value.json_file_url}?t=${new Date().getTime()}`
    );
    const jsonFileContent = await jsonResp.json();
    musicRhythmData.value = {
      id: info.value.id,
      name: info.value.name,
      mp3_file_url: info.value.mp3_file_url,
      // json_file_url: info.value.json_file_url,
      json_file_url: jsonFileContent,
      background_url: jsonFileContent?.background_url,
      border_url: jsonFileContent?.border_url,
      border_heightLight_url: jsonFileContent?.border_heightLight_url,
    };
    console.log("debug:json", musicRhythmData.value);
  },
  { deep: true }
);
const handleQuit = () => {
  router.push({
    path: "/musicRhythm",
  });
  // router.back();
};
if (window.require) {
  const { ipcRenderer } = window.require("electron");
  ipcRenderer.once("goMusicRhythm", (event, message) => {
    // 接收electron原生返回的信息
    handleQuit();
  });
}
onBeforeMount(() => {
  getData();
  // getUserInfo();
  if (route.query.id) {
    isCreate.value = false;
    id.value = route.query.id;
    platform.value = route.query.platform || "web";
    // getRhythmDetail();
  } else {
    isCreate.value = true;
    // ryhthmType = $route.query.type
    // getGestureType();
  }
});

return (_ctx, _cache) => {
  return (musicRhythmData.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(MusicRhythm), {
          ref: musicRhythmRef,
          mode: "rhythm",
          rhythmData: musicRhythmData.value
        }, null, 8, ["rhythmData"])
      ]))
    : _createCommentVNode("", true)
}
}

}