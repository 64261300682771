
import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import { ElMessage } from "element-plus";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import store, { MutationNames } from "@/store";
import { useStore } from "vuex";
import _ from "lodash";
import useLogin from "@/hooks/useLoginNextNext";
import AuthorizedModal from "./components/AuthorizedModal.vue";
import PersonalProductBuyModal from "@/components/common/PersonalProductBuyModal.vue";

import { AuthorizedErrorObj } from "./type";
import SoftwareControlBar from "@/components/softwareControlBar.vue";
import { getTeacherBenefits } from "@/services/personalProduct";

interface School {
  id: number;
  name: string;
  logo: string;
}
export default defineComponent({
  name: "SchoolList",
  components: {
    SoftwareControlBar,
    AuthorizedModal,
    PersonalProductBuyModal,
  },
  setup() {
    const personalPayState: any = reactive({
      showBuyPage: false,
      currentProduct: null,
      currentSchool: null,
    });
    const router = useRouter();
    const store = useStore();
    const {
      switchSchool,
      checkAuthorization,
      getTeacherMe,
      jumpToHome,
      ErrorType,
    } = useLogin();
    const loading = ref(false);
    const isML = computed(() => store.state.isMl);
    const schoolList = ref<School[]>([]);
    const isSelectedId = ref(0);
    const lastSelectedSchoolLogo = computed(() => {
      let _logo = "";
      schoolList.value.forEach((school: School) => {
        if (school.id === isSelectedId.value) {
          _logo = school?.logo;
        }
      });
      return _logo;
    });
    const isAndroid = ref(false);

    const authorizeVisible = ref(false);
    const authorizeObj = ref<AuthorizedErrorObj>();

    const noRelateErrorObj = ref<AuthorizedErrorObj>();

    const teacherName = ref("");
    const loginErrorModalType = ref(-1);

    const handleSelectedSchool = (id: number) => {
      isSelectedId.value = id;
    };

    const closeLoginErrorModal = () => {
      noRelateErrorObj.value = { type: 0, message: "" };
    };

    const handleChangeSchoolLogin = _.debounce(
      async () => {
        try {
          loading.value = true;
          const school = schoolList.value.find(
            (school: any) => school.id === isSelectedId.value
          ) as any;

          await switchSchool(school?.id);

          personalPayState.currentSchool = school;
          if (school.is_personal_production && school.expired_status) {
            await new Promise((_, reject) => {
              reject({ type: ErrorType.PERSONAL_PRODUCTION_EXPIRED });
            });
          }
          if (school.expired_status) {
            // throw new Error({ type: ErrorType.EXPIRED });
            await new Promise((_, reject) => {
              reject({ type: ErrorType.EXPIRED });
            });
          }

          await checkAuthorization(school?.id);
          loading.value = false;
          const { subjects } = await getTeacherMe({
            checkSubject: true,
            saveInfo: true,
          });
          jumpToHome(subjects, school.id);
        } catch (errRes: any) {
          console.error(errRes);
          loading.value = false;
          const { result, type } = errRes || {};
          if (type === ErrorType.UNAUTHORIZED) {
            authorizeVisible.value = true;
            authorizeObj.value = {
              type: result?.auth_result_type,
              message: result?.message || "",
            };
          } else if (type === ErrorType.NO_RELATE_SUBJECT) {
            noRelateErrorObj.value = {
              type: 1,
              message:
                "您尚未关联学科，无法使用魔欢教学软件，请联系魔欢商务处理。",
              teacherName: result?.teacherName || "",
            };
          } else if (type === ErrorType.EXPIRED) {
            noRelateErrorObj.value = {
              type: 3,
              message:
                "您在该学校的授权已过期，无法使用魔欢教学软件，请联系校方处理。",
              teacherName: result?.teacherName || "",
            };
          } else if (type === ErrorType.PERSONAL_PRODUCTION_EXPIRED) {
            noRelateErrorObj.value = {
              type: 2,
              message:
                "您在该学校的授权已过期，无法使用魔欢教学软件，请点击下方按钮续费。",
              teacherName: result?.teacherName || "",
            };
          } else {
            ElMessage.error({
              type: "error",
              message: "切换学校失败",
              offset: 200,
            });
          }
        }
      },
      200,
      { leading: true, trailing: false }
    );

    const goToLogin = () => {
      // store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
      // store.commit(MutationNames.CHANGE_LESSONSTART, true);
      // store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
      // store.commit(MutationNames.CHANGE_GRADENUMBER, "");
      // store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
      store.commit(MutationNames.CHANGE_SCHOOL_ID, undefined);
      store.commit(MutationNames.CHANGE_LOGINBTNTXT, true);

      router.replace({
        path: "/login",
      });
    };

    onMounted(() => {
      const { schools, _teacherName } = router.currentRoute.value?.query || {};
      schoolList.value.length = 0;
      schools && schoolList.value.push(...JSON.parse(schools as any));
      _teacherName && (teacherName.value = _teacherName as any);

      const device = navigator.userAgent;
      isAndroid.value =
        device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

      isSelectedId.value = schoolList.value[0].id;
      const lastLoginSchool = JSON.parse(
        localStorage.getItem("selectedSchool") || "{}"
      );

      if (!isNaN(lastLoginSchool?.schoolId)) {
        const isExitSchool = schoolList.value.find(
          (school: any) => school.id === lastLoginSchool.schoolId
        );
        if (isExitSchool) {
          isSelectedId.value = isExitSchool?.id;
        }
      }
    });
    onBeforeRouteLeave((to, from, next) => {
      if (to.name === "Login") {
        store.commit(MutationNames.CHANGE_LOGINBTNTXT, true);
      }
      next(true);
    });

    const getTeacherBenefitsInfo = async () => {
      const res: any = await getTeacherBenefits();
      const schoolId = personalPayState?.currentSchool?.id || 0;
      personalPayState.currentProduct = res.data?.find(
        (i: any) => i.relation_school_id === schoolId
      );
    };

    const handlePay = async () => {
      await getTeacherBenefitsInfo();
      store.state.teacherBenefits = personalPayState.currentProduct ?? null;
      localStorage.setItem(
        "isPersonal",
        personalPayState.currentProduct ? "1" : "0"
      );
      personalPayState.showBuyPage = true;
      closeLoginErrorModal();
    };

    const handleJumpToHome = async () => {
      await checkAuthorization(personalPayState.currentSchool?.id);
      loading.value = false;
      const { subjects } = await getTeacherMe({
        checkSubject: true,
        saveInfo: true,
      });
      jumpToHome(subjects, personalPayState.currentSchool?.id);
    };

    const handlePaySuccess = async () => {
      await getTeacherBenefitsInfo();
      ElMessage.success({
        type: "success",
        message: "正在跳转中，请稍后",
        offset: 100,
      });
      setTimeout(() => {
        // goToLogin();
        handleJumpToHome();
      }, 4000);
    };

    return {
      isML,
      loading,
      teacherName,
      loginErrorModalType,
      isSelectedId,
      schoolList,
      authorizeVisible,
      handleSelectedSchool,
      handleChangeSchoolLogin,
      lastSelectedSchoolLogo,
      goToLogin,
      authorizeObj,
      noRelateErrorObj,
      closeLoginErrorModal,
      handlePay,
      personalPayState,
      handlePaySuccess,
    };
  },
});
