
import CoursewareCard from "@/components/CoursewareCard.vue";
import ScrollLoad from "@/components/ScrollLoad.vue";
import SelectTree from "@/components/SelectTree.vue";
import TagSelect from "@/components/TagSelect.vue";
import HomeBackground from "@/components/common/HomeBackground.vue";
import RemindEmptyResource from "@/components/remindEmptyResource.vue";
import config from "@/config/app.config";
import servers from "@/config/servers";
import {
  addDownloadLog,
  addInsDownloadLog,
  addInsViewLog,
  addViewLog,
  copyOfficial,
  copyArea,
  dataEmbeddingPoint,
} from "@/services";
import { ElMessage } from "element-plus";
import _ from "lodash";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect,
} from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";

import useCache from "@/hooks/useCache";
import emitter, { EmitterEvents } from "@/utils/eventEmitter";
import { SocketEventMap } from "@/utils/socket-event";
import { noticeCacheErrorDownloadResult } from "@/utils/socketCommon";

export default defineComponent({
  name: "courseware-library",
  components: {
    HomeBackground,
    SelectTree,
    TagSelect,
    ScrollLoad,
    RemindEmptyResource,
    CoursewareCard,
    AsyncCourseWarePreview: defineAsyncComponent(
      () =>
        new Promise<any>((resolve) => {
          // fixme: 这边因为预览组件有ppt应用加载，因此需要延迟500ms，方便页面元素渲染染，不会因为ppt应用的加载，导致页面卡顿
          import("@/components/CourseWarePreview.vue").then((res: any) => {
            setTimeout(() => {
              resolve(res);
            }, 500);
          });
        })
    ),
  },
  setup() {
    // eslint-disable-next-line no-undef
    const store = useStore<State>();
    const scrollLoad = ref<any>(null);
    const {
      canUseCache,
      canUseHightLocalResource,
      checkTypeCanUseCache,
      LocalResourceTypeMap,
      CacheStatus,
      addCacheFile,
      checkCacheFile,
    } = useCache();
    const router = useRouter();

    const selectSubject = store.getters.getSelectSubject();

    const teacherId = computed(() => {
      return store.state.teacherId;
    });

    const coursewaresSources = [
      {
        label: "所有",
        value: "all",
      },
      {
        label: "平台",
        value: "official",
      },
      {
        label: "区域",
        value: "institution",
      },
      {
        label: "学校",
        value: "school",
      },
    ];
    const bookId = ref("");
    const sectionsId = ref("");
    const coursewaresSource = ref("all");

    const selectTreeProps = {
      select: {
        api: `${
          config[servers.resource]
        }/official/books?per_page=9999&show_modules=1&teaching_system=${
          selectSubject.relation_teaching_system
        }`,
        matchData: "@data.items@",
        matchLabel: "{press_version.name}-{name}",
        matchValue: "{id}",
      },
      tree: {
        api: `${config[servers.resource]}/official/book/{selectValue}/sections`,
        matchData: "@data@",
        matchLabel: "{name}",
        matchValue: "{id}",
      },
      nodeClick: true,
    };
    const sectionData = ref<any>({});

    const courseWarePreviewRef = ref();

    const onChooseDataChange = ({ data }: { data: any }) => {
      sectionData.value = data;
    };

    const unGetCoursewareTypes = ["sheet_song"]; // 不支持获取的课件类型
    const unCacheCoursewareTypes = [""]; // 不支持缓存的课件类型
    const unPopularityCoursewareTypes = ["sheet_song"]; // 不支持点击率的课件类型

    const coursewareType = ref<Array<string> | string>([
      "ppt",
      "image",
      "video",
      "audio",
      "swf",
      "sheet_song",
    ]);
    const coursewareTypes = ref([
      {
        label: "全部",
        value: ["ppt", "image", "video", "audio", "swf", "sheet_song"],
      },
      { label: "PPT", value: "ppt" },
      { label: "歌曲", value: "sheet_song" },
      { label: "图片", value: "image" },
      { label: "视频", value: "video" },
      { label: "音频", value: "audio" },
      { label: "flash", value: "swf" },
    ]);

    const scrollLoadProps = computed(() => {
      return {
        api: `${
          config[servers.resource]
        }/teacher/aggregation-resource/coursewares`,
        matchData: "@data.items@",
        params: {
          official_book_id: bookId.value,
          official_book_section_id: sectionsId.value,
          types: Array.isArray(coursewareType.value)
            ? coursewareType.value
            : [coursewareType.value],
          source: coursewaresSource.value,
          teaching_system: selectSubject.relation_teaching_system,
        },
        beforeRequest: (params: any) => {
          if (!params.official_book_id || !params.official_book_section_id) {
            return;
          }
          return params;
        },
      };
    });

    const setLocalLastSelectedCoursewareLibBS = () => {
      if (!bookId.value || !sectionsId.value) {
        return;
      }
      const localData = JSON.parse(
        localStorage.getItem("lastSelectedCoursewareLibBS") || "[]"
      );
      const lastSelected = localData.find(
        (item: any) =>
          item.teacherId === teacherId.value &&
          item.subject === selectSubject.subject
      );
      if (lastSelected) {
        lastSelected.bookId = bookId.value;
        lastSelected.sectionsId = sectionsId.value;
        lastSelected.subject = selectSubject.subject;
      } else {
        localData.push({
          teacherId: teacherId.value,
          bookId: bookId.value,
          sectionsId: sectionsId.value,
          subject: selectSubject.subject,
        });
      }
      localStorage.setItem(
        "lastSelectedCoursewareLibBS",
        JSON.stringify(localData)
      );
    };
    const getLocalLastSelectedCoursewareLibBS = () => {
      const localData = JSON.parse(
        localStorage.getItem("lastSelectedCoursewareLibBS") || "[]"
      );
      const lastSelected = localData.find(
        (item: any) =>
          item.teacherId === teacherId.value &&
          item.subject === selectSubject.subject
      );
      return lastSelected;
    };

    watchEffect(() => {
      setLocalLastSelectedCoursewareLibBS();
    });

    const getCard = (item: any) => {
      return {
        cardType: item.type,
        cardUrl: item.url,
        cardIsPrimary: item?.is_primary,
        cardProps: { ...item },
        ...item,
      };
    };

    // 数据埋点
    const handleDataReport = (item: any) => {
      const eventattr = { id: item.id, owner_type: item.source };
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // 判断是否是安卓设备
      const isAndroid =
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1;
      if (!isAndroid) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_teachmaterial_open",
          pageid: "teachmaterial",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        const body = encodeURIComponent(JSON.stringify(data));
        dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_teachmaterial_open";
            data.pageid = "teachmaterial";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            dataEmbeddingPoint(body);
          }
        );
      }
    };

    // 处理点击跳转逻辑
    const onCardClick = (item: any) => {
      const { cardType, cardUrl, cardProps } = item;
      courseWarePreviewRef.value.showCourseWare(cardType, cardUrl, cardProps);
      viewCourseLog(item);
      handleDataReport(item);
    };

    const copyCourseware = (item: any) => {
      // todo: 这边需要判断下来源，进行不同的接口处理
      const downloadFunc =
        item.source !== "official" ? addInsDownloadLog : addDownloadLog;
      const copyFunc = item.source !== "official" ? copyArea : copyOfficial;
      const copyParams =
        item.source !== "official"
          ? { area_public_courseware_id: item.id }
          : {
              official_courseware_id: item.id,
            };
      copyFunc(copyParams)
        .then(() => {
          downloadFunc(item.id);
          ElMessage.success({
            offset: 200,
            message: "获取成功，已添加至“我的课件”",
          });
        })
        .catch(() => {
          ElMessage.error({
            offset: 200,
            message: "获取失败，请稍后尝试，若是不行请联系客服人员”",
          });
        });
    };
    const viewCourseLog = (item: any) => {
      if (unPopularityCoursewareTypes.includes(item.type)) {
        return;
      }
      const viewLogFunc = item.source === "ins" ? addInsViewLog : addViewLog;
      viewLogFunc(item.id);
    };

    const handleCache = _.debounce((item: any) => {
      scrollLoad.value.scrollData.map((_data: any) => {
        if (
          _data.id === item.id &&
          _data.type === item.type &&
          _data.source === item.source
        ) {
          _data.cacheStatus = CacheStatus.DOWNLOADING;
          _data.cacheProgress = undefined;
          return _data;
        }
        return _data;
      });

      if (canUseHightLocalResource) {
        addCacheFile({
          cloud_id: item.id,
          type: LocalResourceTypeMap?.[item.type]
            ? LocalResourceTypeMap[item.type]
            : item.type,
          url: item?.url,
          mode: "coursewareLibrary",
          name:
            item.type === "ppt" || item.type === "sheet_song"
              ? item?.name + ".json"
              : item?.name,
          data: item?.cardProps,
          version_time: item?.updated_at,
          source: item?.source,
        });
      } else {
        addCacheFile({
          id: item.id,
          url: item?.url,
          mode: "coursewareLibrary",
          name: item.type === "ppt" ? item?.name + ".json" : item?.name,
          version_time: item?.updated_at,
          md5: item.md5,
        });
      }
    }, 100);

    const handleScrollDataFetchEnd = async (fetchData: any) => {
      const { data } = fetchData || {};
      if (!data) {
        return;
      }
      if (!canUseCache?.value) {
        return;
      }
      const cacheParams: any[] = [];

      if (canUseHightLocalResource) {
        data.forEach((_data: any) => {
          cacheParams.push({
            cloud_id: _data.id,
            version_time: _data.updated_at,
            type: LocalResourceTypeMap?.[_data.type]
              ? LocalResourceTypeMap[_data.type].toUpperCase()
              : _data.type?.toUpperCase(),
            source: _data.source,
            mode: "coursewareLibrary",
          });
        });
      } else {
        data.forEach((_data: any) => {
          cacheParams.push({
            id: _data.id,
            md5: _data.md5,
            url: _data.url,
            mode: "coursewareLibrary",
            version_time: _data.updated_at,
          });
        });
      }

      const cacheFiles = await checkCacheFile(
        canUseHightLocalResource ? { items: cacheParams } : cacheParams
      );
      const _scrollData = _.cloneDeep(scrollLoad.value.scrollData);

      cacheFiles &&
        cacheFiles.forEach((cacheData: any) => {
          const { id, type, cacheUrl, cacheStatus, cacheProgress, originUrl } =
            cacheData;
          let index = -1;
          if (canUseHightLocalResource) {
            const _type = LocalResourceTypeMap?.[type]
              ? LocalResourceTypeMap[type]
              : type;
            index = _scrollData.findIndex(
              (data: any) =>
                data.id === id && data.type?.toUpperCase() === _type
            );
          } else {
            index = _scrollData.findIndex(
              (data: any) => data.id === id && data.url === originUrl
            );
          }
          if (index >= 0) {
            _scrollData[index].cacheStatus = cacheStatus;
            _scrollData[index].cacheProgress = cacheProgress || 0;

            if (cacheUrl && cacheStatus === CacheStatus.SUCCESS) {
              _scrollData[index].url = cacheUrl;
              _scrollData[index].localJsonUrl = cacheUrl;
            }
          }
        });
      scrollLoad.value.scrollData.length = 0;
      scrollLoad.value.scrollData.push(..._scrollData);
    };

    // fixme: 兼容1.5.0之前版本的ipc通信，接收参数，返回首页
    const addListenerIpcGoBack = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.on("myCoursewareGoBack", () => {
          router.push("/home");
        });
      }
    };
    const removeListenersIpcGoBack = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.removeAllListeners("myCoursewareGoBack");
      }
    };
    onBeforeRouteLeave((to, from, next) => {
      const { query } = to;
      if (query && query?.home) {
        courseWarePreviewRef.value.reset();
        next(true);
        return;
      }
      if (to?.name === "Login") {
        next(true);
        return;
      }
      if (courseWarePreviewRef.value && courseWarePreviewRef.value.visible) {
        courseWarePreviewRef.value.reset();
        next(false);
        return;
      }
      next(true);
    });

    const socket: any = inject("socket");
    const downloadStatus = (res: any) => {
      const { file_url, mode, source, type, status, cloud_id, cloud_url } =
        res?.data?.details?.[0] || {};
      console.log("#message downloadStatus coursewareLibrary:", res);
      if (mode !== "coursewareLibrary") {
        return;
      }
      const _type = LocalResourceTypeMap?.[type]
        ? LocalResourceTypeMap[type]
        : type;

      if (status === 0) {
        scrollLoad.value.scrollData.map((data: any) => {
          // 高版本匹配
          if (
            canUseHightLocalResource &&
            data.type.toUpperCase() === _type &&
            data.source === source &&
            data.id === cloud_id
          ) {
            data.cacheProgress = 100;
            data.cacheStatus = CacheStatus.SUCCESS;
            data.url = file_url;
            data.localJsonUrl = file_url;
            return data;
          }
          // 低版本匹配
          if (!canUseHightLocalResource && data.url === decodeURI(cloud_url)) {
            data.cacheStatus = CacheStatus.SUCCESS;
            data.url = file_url;
            return data;
          }
          return data;
        });
      }
    };

    const downloadProgress = (res: any) => {
      const { cloud_id, type, mode, complete_file_num, total_file_num } =
        res?.data || {};
      if (mode !== "coursewareLibrary") {
        return;
      }
      scrollLoad.value.scrollData.map((data: any) => {
        if (data.id === cloud_id && data.type.toUpperCase() === type) {
          data.cacheProgress = total_file_num
            ? Math.floor((complete_file_num / total_file_num) * 100)
            : 0;
          data.cacheStatus = CacheStatus.DOWNLOADING;
          return data;
        }

        return data;
      });
    };
    const cacheResourceError = (resource: any) => {
      const { cloud_id, type, source, mode, id, md5 } = resource || {};
      console.log("#message error:", resource);
      if (mode !== "coursewareLibrary") {
        return;
      }
      scrollLoad.value.scrollData.map((data: any) => {
        if (
          canUseHightLocalResource &&
          data.id === cloud_id &&
          data.type === type &&
          data.source === source
        ) {
          data.cacheStatus = CacheStatus.NONE;
          data.cacheProgress = 0;
          return data;
        }
        if (!canUseHightLocalResource && data.id === id && data.md5 === md5) {
          data.cacheStatus = CacheStatus.NONE;
          return data;
        }
        return data;
      });
      ElMessage.error({
        offset: 200,
        message: "资源下载失败",
        type: "error",
      });
    };

    const cacheDownloadFull = (resource: any) => {
      console.log("#message full:", resource);
      const { cloud_id, type, source, mode, id, md5 } = resource || {};
      if (mode !== "coursewareLibrary") {
        return;
      }

      scrollLoad.value.scrollData.map((data: any) => {
        if (
          canUseHightLocalResource &&
          data.id === cloud_id &&
          data.type === type &&
          data.source === source
        ) {
          data.cacheStatus = CacheStatus.NONE;
          data.cacheProgress = 0;
          return data;
        }

        if (!canUseHightLocalResource && data.id === id && data.md5 === md5) {
          data.cacheStatus = CacheStatus.NONE;
          return data;
        }
        return data;
      });

      ElMessage.warning({
        offset: 200,
        message: "当前下载繁忙，请稍后",
        type: "warning",
      });
    };

    const cacheDownloadResourceSuccess = (resource: any) => {
      console.log("#message download success:", resource);
      const { cloud_id, type, source, mode, id, md5 } = resource || {};
      if (mode !== "coursewareLibrary") {
        return;
      }
      const _type = LocalResourceTypeMap?.[type]
        ? LocalResourceTypeMap[type]
        : type;

      scrollLoad.value.scrollData.map((data: any) => {
        if (
          canUseHightLocalResource &&
          data.id === cloud_id &&
          data.type === _type &&
          data.source === source
        ) {
          data.cacheProgress = 0;
          return data;
        }
        return data;
      });
    };

    onMounted(() => {
      const lastSelected = getLocalLastSelectedCoursewareLibBS();
      if (lastSelected) {
        bookId.value = lastSelected.bookId;
        sectionsId.value = lastSelected.sectionsId;
      }
      addListenerIpcGoBack();
      emitter.on(EmitterEvents.ON_CACHE_FULL, cacheDownloadFull);
      emitter.on(EmitterEvents.ON_CACHE_ERROR, cacheResourceError);
      emitter.on(
        EmitterEvents.ON_CACHE_DOWNLOAD_SUCCESS,
        cacheDownloadResourceSuccess
      );
      socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
      socket.on(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
    });
    onBeforeUnmount(() => {
      emitter.off(EmitterEvents.ON_CACHE_ERROR, cacheResourceError);
      emitter.off(EmitterEvents.ON_CACHE_FULL, cacheDownloadFull);
      emitter.off(
        EmitterEvents.ON_CACHE_DOWNLOAD_SUCCESS,
        cacheDownloadResourceSuccess
      );
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
      socket.off(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
      removeListenersIpcGoBack();
    });

    return {
      scrollLoad,
      coursewaresSources,
      bookId,
      sectionsId,
      coursewaresSource,
      selectTreeProps,
      sectionData,
      onChooseDataChange,
      coursewareType,
      coursewareTypes,
      scrollLoadProps,
      courseWarePreviewRef,
      canUseCache,
      canUseHightLocalResource,
      checkTypeCanUseCache,
      CacheStatus,
      unGetCoursewareTypes,
      unCacheCoursewareTypes,
      unPopularityCoursewareTypes,
      getCard,
      handleCache,
      onCardClick,
      copyCourseware,
      handleScrollDataFetchEnd,
    };
  },
});
