
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  onBeforeMount,
  watch,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import store, { MutationNames } from "@/store/index";
import {
  getSchoolClassName,
  startLesson,
  finishLesson,
  getCurrentActiveLesson,
  addLessonProcessData,
  getBindSchools,
  checkPcTeacherAuth,
  switchTeacherTokenSchool,
  checkPcTeachAuthorization,
  uploadData,
  getClientInfo,
  deviceLogin,
  getDeviceInfo,
  createTeachingSystemUseRecord,
  queryClassStudent,
} from "@/services";
import {
  setStudentAttendanceRecords,
  getClassStudentEvaluationRecord,
} from "@/services/classroomInteraction";
import { ElMessage, ElMessageBox } from "element-plus";
import logger from "@evideo/logger";
import { eventCenter } from "@evideo/frontend-utils";
import { reportStat, setStatConfig, StatEvent } from "@/utils/statDataReport";
import UserActionModal from "@/components/common/UserActionModal";

export default defineComponent({
  name: "HomeBackground",
  components: { UserActionModal },
  setup() {
    const test = () => {
      router.push("/random");
    };
    const studentNumOpts = ref([{ value: 0, label: 0 }]);
    // 个人版班级人数
    const personalStudentNum = ref(store.state.personalStudentNum);
    const personalStudentMaxNum = ref(0);
    // 授权和学校权限
    const isAuthorized = ref(false);
    let authResultType = ref(0);
    let authResultMessage = ref("");
    let authLoading = ref(true);
    if (!store.state.firstLoadHome) {
      authLoading.value = false;
    }
    const router = useRouter();
    const teacherName = computed(() => store.state.teacherInfo?.nick_name);
    const schoolName = computed(() => store.state.schoolName);
    let isShow = ref(false);
    const showLoginOutBtn = ref(false);
    // let isShow = ref(store.state.firstLoadHome);
    // const teacherName = ref("");
    let gradeNum = ref(store.state.gradeNumber);
    let classNum = ref(store.state.classNumber);
    let gradeClassData = ref(""); // 年级班级数据
    const attendance = ref(true); // 是否全班出勤
    const options = reactive([]);
    const gradeOptions = ref();
    const classOptions = ref();
    let selGradeName = ref("");
    let selClassName = ref("");
    let selGrade = ref(store.state.gradeName);
    let selClass = ref(store.state.className);
    let getSchoolClassId = ref("");
    let periodsNum = ref(1);
    let confirmFinishLessonFlag = ref(false);
    let chooseCourseFlag = ref(false);
    // 登录账号是否有绑定班级
    let isClass = ref(true);
    let isSchool = ref(true);
    const device = navigator.userAgent;
    // 上下课状态
    let lessonStart = ref(store.state.lessonStart);
    // 自动拍照截图定时器
    let timer;
    const isAndroid = ref(true);
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    const chineseNum = reactive([
      "一年级",
      "二年级",
      "三年级",
      "四年级",
      "五年级",
      "六年级",
      "七年级",
      "八年级",
      "九年级",
      "高一",
      "高二",
      "高三",
      "大一",
      "大二",
      "大三",
      "大四",
    ]);

    const remoteUrls = [
      {
        icon: "https://basic.smartedu.cn/img/logo-icon.afa526cf.png",
        name: "国家中小学智慧教育平台",
        className: "smartEdu-meiyu",
        url: "https://basic.smartedu.cn/art?channelId=31741f87-5444-42f9-ac3f-795d7dc7ef7a&breadcrumb=%E8%89%BA%E6%9C%AF%E8%AF%BE%E7%A8%8B&libraryId=7612f2f2-98f1-46e1-b889-36bdecdbe63e&resourceChannel=library",
      },
    ];
    const answerGameObject =
      JSON.parse(localStorage.getItem("answerGame")) || {};

    // 音乐 or 美术
    let courseLabel = ref(
      JSON.parse(localStorage.getItem("subjectDataSelected")).subject_name
    );
    if (!localStorage.getItem("subjectState")) {
      localStorage.setItem(
        "subjectState",
        JSON.stringify({
          isChangeSubject: false,
          previousSubject: localStorage.getItem("subjectDataSelected")
            ? JSON.parse(localStorage.getItem("subjectDataSelected")).subject
            : "",
        })
      );
    }

    if (
      localStorage.getItem("subjectState") &&
      JSON.parse(localStorage.getItem("subjectState")).isChangeSubject
    ) {
      let { isChangeSubject, previousSubject } = JSON.parse(
        localStorage.getItem("subjectState")
      );
      const currentSubject = localStorage.getItem("subjectDataSelected")
        ? JSON.parse(localStorage.getItem("subjectDataSelected")).subject
        : "";

      if (currentSubject !== previousSubject && store.state.lessonRecordId) {
        confirmFinishLessonFlag.value = false;
        finishLesson({
          lesson_record_id: store.state.lessonRecordId,
        }).then(() => {
          // 确定下课后将状态修改为已下课
          lessonStart.value = true;
          store.commit(MutationNames.CHANGE_LESSONSTART, true);
          store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
          store.commit(MutationNames.CHANGE_GRADENUMBER, "");
          store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
          localStorage.setItem("answerGame", JSON.stringify({}));
          // 通知electron下课
          toElectronFinishLessonInfo();
          clearTimer(timer);
        });
      }
      isChangeSubject = false;
      localStorage.setItem(
        "subjectState",
        JSON.stringify({ isChangeSubject, previousSubject })
      );
    }
    let isShowChangeCourseBtn = ref(
      store.state.subjectData.length === 1 ||
        store.state.subjectData.length === 0
        ? false
        : true
    );
    watch(
      () => store.state.lessonStart,
      (newVal) => {
        lessonStart.value = newVal;
      }
    );
    // watch(
    //   classNum,
    //   (newValue, oldValue) => {
    //     if (classNum.value.indexOf("班") === -1) {
    //       return;
    //     }
    //     const reg = new RegExp("班");
    //     classNum.value = classNum.value.replace(reg, "");
    //   },
    //   { immediate: true }
    // );
    const handleAddLessonProcessData = async (event, message) => {
      // 接收electron原生返回的信息
      logger.info("我接收到的electron发过来的信息picOssUrl", event, message);
      // 上传图片前判断是否下课
      logger.info("!store.state.lessonStart", !store.state.lessonStart);
      if (!store.state.lessonStart) {
        addLessonProcessData(
          {
            lesson_record_id: store.state.lessonRecordId,
            data_url: message,
            data_type: "image",
          },
          false
        ).then((data) => {
          logger.info("data", data);
        });
      }
    };

    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("goHome", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        goBack();
      });
      ipcRenderer.on("goGameCenter", (event, message) => {
        console.log("收到electron goGameCenter消息");
        router.push({
          path: "/gameCenter",
        });
      });
    }
    // 弹出开始上课弹框
    const classChange = () => {
      if (!isClass.value) {
        ElMessage.info({
          offset: 200,
          message: "登录账号未绑定班级，如需上课请先至‘智慧美育云平台’进行绑定",
          type: "info",
        });
        logger.info("没有班级");
      } else {
        isShow.value = true;
      }
    };
    // 向electron发送下课状态
    const toElectronFinishLessonInfo = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        logger.info(
          "向electron发送信息摄像头rtsp地址",
          store.state.monitorCameraIp
        );
        // start-camera 必须在版本大于等于1.3.0的版本
        const iscompare = compareVersion(store.state.version, "1.3.0");
        if (iscompare >= 0) {
          ipcRenderer.sendToHost("finish-lesson"); // 向原生发送token信息
        }
      }
    };
    // 下课
    const checkFinishLesson = () => {
      confirmFinishLessonFlag.value = true;
    };
    // 数据初始化
    const getData = () => {
      initStudentNum(selClass.value);
      getCurrentActiveLesson({}).then((data) => {
        if (data.current_active_lesson !== null) {
          store.commit(
            MutationNames.CHANGE_LESSONRECORDID,
            data.current_active_lesson.id
          );
          store.commit(
            MutationNames.CHANGE_LESSONCLASSID,
            data.current_active_lesson.class_id
          );
          store.commit(
            MutationNames.CHANGE_SCHOOLCLASSID,
            data.current_active_lesson.class_id
          );
          console.log(111, data);
          initStudentNum(data.current_active_lesson.class_id);
          store.commit(MutationNames.CHANGE_LESSONSTART, false);
        }
        getSchoolClassName({ type: 0 }).then((data) => {
          // if (!store.state.lessonStart) {
          //   setScreenshotTimer();
          // }
          // 获取的班级数据为空时，不做后面的业务逻辑
          if (data.length === 0) {
            isClass.value = false;
            return;
          }
          gradeClassData.value = data;
          options.length = 0;
          // 开始初始化年级数据
          for (let j = 0; j < data.length; j++) {
            const gradeNumber = data[j].grade;
            options.push({
              value: gradeNumber,
              label: chineseNum[gradeNumber - 1],
            });
          }
          gradeOptions.value = options.sort((a, b) => a.value - b.value);
          // 年级数据处理结束
          let lessonClassInfo = null;
          // 判断上课班级的id是否为0，不为0代表上课中
          if (store.state.lessonClassId > 0) {
            data.filter((item) => {
              // logger.info("item", item);
              item.class.filter((classList) => {
                // logger.info("classList", classList);
                if (classList.school_class_id === store.state.lessonClassId) {
                  lessonClassInfo = classList;
                  return;
                }
              });
            });
            logger.info("lessonClassInfo", lessonClassInfo);
            // 开始初始化班级数据
            let checkGradeNum = +lessonClassInfo.grade;

            const classData = data.filter(
              (item) => item.grade === checkGradeNum
            );
            const classArray = classData[0].class;
            let navClassArray = [];
            for (let j = 0; j < classArray.length; j++) {
              const classNum = classArray[j].school_class_id;
              const className = classArray[j].class_name;
              navClassArray.push({
                value: classNum,
                label: className,
              });
            }
            classOptions.value = navClassArray;
            // 班级数据处理结束
            const lessonGrade = gradeOptions.value.filter(
              (item) => item.value === +lessonClassInfo.grade
            );
            const lessonClass = classOptions.value.filter(
              (item) => item.value === +lessonClassInfo.school_class_id
            );
            // 当已经在上课状态中，且班级年级不为空时，不去做选中年级班级数据的初始化
            if (classNum.value === "") {
              gradeNum.value = lessonGrade[0].label;
              classNum.value = lessonClass[0].label;
              // // 下拉框选中的年级班级
              selGrade.value = lessonGrade[0].value;
              selClass.value = lessonClass[0].value;
              getSchoolClassId.value = lessonClass[0].school_class_id;
              store.commit(MutationNames.CHANGE_GRADENUMBER, gradeNum.value);
              store.commit(MutationNames.CHANGE_CLASSNUMBER, classNum.value);
              store.commit(MutationNames.CHANGE_CLASSNAME, selClass.value);
              store.commit(MutationNames.CHANGE_GRADENAME, selGrade.value);
              // store.commit(MutationNames.CHANGE_FIRSTLOADHOME, false);
              store.commit(MutationNames.CHANGE_SCHOOLCLASSID, selClass.value);
              console.log(1112, selClass.value);
              initStudentNum(selClass.value);
            }
          } else {
            // 开始初始化班级数据
            let checkGradeNum = 0;
            // localStorage中是否有保存年级和班级
            let localStorageGrade = false;
            if (store.state.firstLoadHome) {
              gradeOptions.value.forEach((item) => {
                if (item.value == localStorage.selGrade) {
                  localStorageGrade = true;
                  logger.info("item.name", item.label);
                  gradeNum.value = item.label;
                }
              });
              if (localStorageGrade) {
                checkGradeNum = parseInt(localStorage.selGrade);
              } else {
                checkGradeNum = gradeOptions.value[0].value;
              }
            } else {
              checkGradeNum = selGrade.value;
            }
            const classData = data.filter(
              (item) => item.grade === checkGradeNum
            );
            const classArray = classData[0].class;
            let navClassArray = [];
            for (let j = 0; j < classArray.length; j++) {
              const classNum = classArray[j].school_class_id;
              const className = classArray[j].class_name;
              navClassArray.push({
                value: classNum,
                label: className,
              });
            }
            classOptions.value = navClassArray;
            // 班级数据处理结束

            if (store.state.firstLoadHome) {
              logger.info("firstLoadHome", classOptions.value);

              // 下拉框选中的年级班级
              if (localStorageGrade) {
                // localStorageGrade为true时说明登录账号中有相同年级，接下去要判断班级是否相同
                selGrade.value = parseInt(localStorage.selGrade);
                // 从localStorage获取年级名字和班级名字
                //
                let localStorageClass = false;
                selClass.value = parseInt(localStorage.selClass);
                classOptions.value.forEach((item) => {
                  if (item.value == selClass.value) {
                    classNum.value = item.label;
                    // 当该账号的中班级也存在就将状态改为true
                    localStorageClass = true;
                  }
                });
                if (localStorageClass) {
                  // 当登录账号中班级和localstorage中保存的相同
                  getSchoolClassId.value = parseInt(localStorage.selClass);
                } else {
                  // 当登录账号中班级和localstorage中保存的不同
                  classNum.value = classOptions.value[0].label;
                  selClass.value = classOptions.value[0].value;
                  getSchoolClassId.value = classOptions.value[0].value;
                }
              } else {
                gradeNum.value = gradeOptions.value[0].label;
                classNum.value = classOptions.value[0].label;
                selGrade.value = gradeOptions.value[0].value;
                selClass.value = classOptions.value[0].value;
                getSchoolClassId.value = classOptions.value[0].value;
              }
              // 下面这行代码永远是undefined 已修改成classOptions.value[0].value,后期测试出问题再看如何调整
              // getSchoolClassId.value = classOptions.value[0].school_class_id;
              store.commit(MutationNames.CHANGE_GRADENUMBER, gradeNum.value);
              store.commit(MutationNames.CHANGE_CLASSNUMBER, classNum.value);
              store.commit(MutationNames.CHANGE_CLASSNAME, selClass.value);
              store.commit(MutationNames.CHANGE_GRADENAME, selGrade.value);
              // store.commit(MutationNames.CHANGE_FIRSTLOADHOME, false);
              store.commit(MutationNames.CHANGE_SCHOOLCLASSID, selClass.value);
              console.log(1113, selClass.value);
              initStudentNum(selClass.value);
            } else {
              gradeNum.value = store.state.gradeNumber;
              classNum.value = store.state.classNumber;
              // 下拉框选中的年级班级
              selGrade.value = store.state.gradeName;
              selClass.value = store.state.className;
            }
            // 当页面为第一次打开，且状态不为上课中时，打开选择班级的弹窗
            if (store.state.firstLoadHome) {
              // schoolChange();
              // classChange();
              classChange();
            }
          }
        });
      });
    };
    // 课时减
    const changePeriodsReduce = () => {
      if (periodsNum.value <= 1) {
        ElMessage.info({
          offset: 200,
          message: "最少需要1课时",
          type: "info",
        });
      } else {
        periodsNum.value--;
      }
    };
    // 课时加
    const changePeriodsPush = () => {
      if (periodsNum.value >= 8) {
        ElMessage.info({
          offset: 200,
          message: "最多添加8课时",
          type: "info",
        });
      } else {
        periodsNum.value++;
      }
    };
    const changeStudentNumReduce = () => {
      if (personalStudentNum.value <= 1) {
        ElMessage.info({
          offset: 200,
          message: "最少为1人",
          type: "info",
        });
      } else {
        personalStudentNum.value--;
      }
    };
    const changeStudentNumPush = () => {
      if (personalStudentMaxNum.value > personalStudentNum.value) {
        personalStudentNum.value++;
      } else {
        ElMessage.info({
          offset: 200,
          message: "已到班级最大人数",
          type: "info",
        });
      }
    };
    const changeGradeName = (gradeItem) => {
      // 根据选择的年级取对应的班级

      const classData = gradeClassData.value.filter(
        (item) => item.grade === gradeItem.value
      );
      const classArray = classData[0].class;
      let navClassArray = [];
      for (let j = 0; j < classArray.length; j++) {
        const classNum = classArray[j].school_class_id;
        const className = classArray[j].class_name;
        navClassArray.push({
          value: classNum,
          label: className,
        });
      }
      classOptions.value = navClassArray;
      selClass.value = classOptions.value[0].value;
      selGradeName.value = gradeItem.label;
      selClassName.value = classOptions.value[0].label;
    };
    const changeClassName = (classItem) => {
      selClass.value = classItem.value;
      selClassName.value = classItem.label;
    };
    // 选择框，是否全部出勤
    const changeAttendance = () => {
      console.log(123);
      attendance.value = !attendance.value;
    };
    const goBack = () => {
      router.push({
        path: "/home",
      });
    };
    const clearTimer = (clearTimerValue) => {
      clearInterval(store.state.screenshotTimer);
      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
    };
    const goToLogin = () => {
      clearTimer(timer);
      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, "");
      store.commit(MutationNames.CHANGE_LESSONSTART, true);
      store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
      store.commit(MutationNames.CHANGE_GRADENUMBER, "");
      store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
      store.commit(MutationNames.CHANGE_SCHOOL_ID, undefined);
      store.commit(MutationNames.CHANGE_TEACHER_INFO, null);
      // 向electorn发送下课消息
      toElectronFinishLessonInfo();
      router.push({
        path: "/login",
      });
    };
    const btnClose = () => {
      selGrade.value = store.state.gradeName;
      selClass.value = store.state.className;
      isShow.value = false;
    };

    // 版本判断
    const compareVersion = (v1, v2) => {
      // -1|v2大于v1；0|相同；1|v2小于v1
      v1 = v1.split(".");
      v2 = v2.split(".");
      const len = Math.max(v1.length, v2.length);

      while (v1.length < len) {
        v1.push("0");
      }
      while (v2.length < len) {
        v2.push("0");
      }

      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);

        if (num1 > num2) {
          return 1;
        } else if (num1 < num2) {
          return -1;
        }
      }

      return 0;
    };
    // 发送截图消息
    const sendStartCamera = () => {
      getCurrentActiveLesson({}).then((data) => {
        // 给electron发送消息前先判断是否在上课中
        logger.info(data);
        if (data.current_active_lesson !== null) {
          if (window.require) {
            const { ipcRenderer } = window.require("electron");
            logger.info(
              "向electron发送信息摄像头rtsp地址",
              store.state.monitorCameraIp
            );
            // start-camera 必须在版本大于等于1.3.0的版本
            const iscompare = compareVersion(store.state.version, "1.3.0");
            if (iscompare >= 0) {
              ipcRenderer.sendToHost(
                "start-camera",
                store.state.monitorCameraIp,
                store.state.lessonRecordId
              ); // 向原生发送token信息
            }
          }
        } else {
          clearTimer(timer);
        }
      });
    };
    // 设置截图的轮询
    const setScreenshotTimer = () => {
      logger.info("设置轮询了");
      if (store.state.screenshotTimer === "") {
        sendStartCamera();
        timer = setInterval(() => {
          logger.info("轮询喽");
          sendStartCamera();
          // 10分钟一次
          // 方便测试改为1分钟发布的时候要调整回10分钟
        }, 1000 * 60 * 10);
      }

      store.commit(MutationNames.CHANGE_SCREENSHOTTIMER, timer);
    };
    // 开始上课
    const centain = () => {
      if (store.state.teacherBenefits) {
        store.commit(
          MutationNames.CHANGE_PERSONAL_STUDENT_NUM,
          personalStudentNum.value
        );
      }

      const selectSubject = store.getters.getSelectSubject();

      store.commit(MutationNames.CHANGE_LESSONCLASSID, selClass.value);
      startLesson({
        class_id: selClass.value,
        lesson_periods: periodsNum.value,
        subject: selectSubject.subject,
      }).then((data) => {
        // 确定开始上课后将状态修改为上课中
        // 把年级和班级的信息发送到electron保存到localStorage
        if (window.require) {
          const { ipcRenderer } = window.require("electron");
          const iscompare = compareVersion(store.state.version, "1.3.3");
          if (iscompare >= 0) {
            const classInfo = {
              selGrade: selGrade.value,
              selClass: selClass.value,
            };
            ipcRenderer.sendToHost("selClassInfo", classInfo); // 向原生发送教师选中班级信息
          }
        }
        if (!answerGameObject.value) {
          let num = ref(selGrade.value + "");
          const myArray = ref([]);
          myArray.value.push(num.value);
          answerGameObject.value = myArray.value;
          localStorage.setItem("answerGame", JSON.stringify(answerGameObject));
        }
        periodsNum.value = 1;
        localStorage.setItem("selGrade", selGrade.value);
        localStorage.setItem("selClass", selClass.value);
        // localStorage.setItem("selGradeName", gradeNum.value);
        // localStorage.setItem("selClassName", classNum.value);
        lessonStart.value = false;
        store.commit(MutationNames.CHANGE_LESSONSTART, false);
        store.commit(
          MutationNames.CHANGE_LESSONRECORDID,
          data.current_active_lesson.id
        );
        // setScreenshotTimer();
        // 全员出勤，发送一个全员出勤
        if (attendance.value) {
          getClassStudentEvaluationRecord(store.state.lessonRecordId)
            .then(async (data) => {
              if (!data || !data.data) {
                throw new Error(
                  "get class status evaluation record error",
                  data
                );
              }
              const stuList = data?.data?.students || [];
              if (stuList.length === 0) {
                return;
              }
              let params = {
                lesson_record_id: store.state.lessonRecordId,
                student_attendance_status_list: stuList.map((i) => ({
                  student_id: i.id,
                  status: 1,
                })),
              };
              await setStudentAttendanceRecords(params);
            })
            .catch((err) => {
              logger.error("【Home】:上课设置全勤失败", err);
              ElMessage.error("设置学生全勤失败，请在课堂互动中手动设置");
            });
        } else {
          // 通知弹窗，并且切换到考勤
          eventCenter.pub("openAttendance");
        }
      });
      isShow.value = false;
      store.commit(MutationNames.CHANGE_SCHOOLCLASSID, selClass.value);
      if (selGradeName.value) {
        gradeNum.value = selGradeName.value;
        store.commit(MutationNames.CHANGE_GRADENUMBER, gradeNum.value);
      } else {
        gradeNum.value = chineseNum[selGrade.value - 1];
        store.commit(MutationNames.CHANGE_GRADENUMBER, gradeNum.value);
      }
      if (selClassName.value) {
        classNum.value = selClassName.value;
        store.commit(MutationNames.CHANGE_CLASSNUMBER, classNum.value);
      }
      store.commit(MutationNames.CHANGE_CLASSNAME, selClass.value);
      store.commit(MutationNames.CHANGE_GRADENAME, selGrade.value);
      store.commit(MutationNames.CHANGE_IFSHOW, isShow.value);
    };
    const confirmFinishLesson = () => {
      confirmFinishLessonFlag.value = false;
      finishLesson({
        lesson_record_id: store.state.lessonRecordId,
      }).then(() => {
        // 确定下课后将状态修改为已下课
        lessonStart.value = true;
        store.commit(MutationNames.CHANGE_LESSONSTART, true);
        store.commit(MutationNames.CHANGE_LESSONCLASSID, 0);
        store.commit(MutationNames.CHANGE_GRADENUMBER, "");
        store.commit(MutationNames.CHANGE_CLASSNUMBER, "");
        localStorage.setItem("answerGame", JSON.stringify({}));
        // 通知electron下课
        toElectronFinishLessonInfo();
        clearTimer(timer);
        ElMessage({
          type: "success",
          message: "下课成功",
        });
      });
    };
    const cancelQuit = () => {
      confirmFinishLessonFlag.value = false;
    };
    const getMac = () => {
      logger.info("versionmac", store.state.version);
      const { ipcRenderer } = window.require("electron");
      // const mac = ipcRenderer?.sendSync("getMac") || "0:0:0:0";
      let mac = ipcRenderer.sendSync("getMac");
      const localMac = localStorage.getItem("mac");
      if (mac) {
        localStorage.setItem("mac", mac);
      } else if (localMac) {
        mac = localMac;
      } else {
        mac = "0:0:0:0";
      }
      logger.info("local mac", localMac);
      logger.info("mac", mac.toUpperCase().replace(/:/g, ""));
      store.commit(
        MutationNames.CHANGE_MAC,
        mac.toUpperCase().replace(/:/g, "")
      );
      logger.info("store.mac", store.state.mac);
    };
    // 获取设备是否有有网络摄像头rtsp
    const getDevice = async () => {
      const data = await getClientInfo({});
      const deviceData = await deviceLogin({
        client_id: data.client_id,
        client_secret: data.client_secret,
      });
      localStorage.setItem("deviceToken", deviceData.access_token);
      store.commit(MutationNames.CHANGE_DEVICETOKEN, deviceData.access_token);
      const deviceInfoData = await getDeviceInfo({});
      await store.commit(
        MutationNames.CHANGE_MONITORCAMERAIP,
        deviceInfoData.monitor_camera_ip
      );
    };
    const getLessonState = () => {
      logger.info("back");
      getCurrentActiveLesson({}).then((data) => {
        if (data.current_active_lesson !== null) {
          store.commit(MutationNames.CHANGE_LESSONSTART, false);
          // store.commit(
          //   MutationNames.CHANGE_LESSONRECORDID,
          //   data.current_active_lesson.id
          // );
          // store.commit(
          //   MutationNames.CHANGE_LESSONCLASSID,
          //   data.current_active_lesson.class_id
          // );
        }
      });
    };
    // 信息上报
    const reportData = () => {
      let mac = store.state.mac;
      if (mac === "0:0:0:0") {
        mac = mac + "-" + store.state.schoolId;
        store.commit(MutationNames.CHANGE_MAC, mac);
      }
      uploadData({
        chip_id: mac,
        school_id: store.state.schoolId,
        mac: mac,
        hardware_model: store.state.hardwareModel,
        software: "pc_teach",
        software_version: store.state.version,
        firmware: "windows",
        use_type: "11",
        screen_orientation: "0",
      }).then((data) => {
        logger.info("success", data);
        getDevice().then(() => {
          logger.info("进来了获取上课状态");
          // getLessonState();
        });
        // getClientInfo({}).then((data) => {
        //   deviceLogin({
        //     client_id: data.client_id,
        //     client_secret: data.client_secret,
        //   }).then((data) => {
        //     localStorage.setItem("deviceToken", data.access_token);
        //     store.commit(MutationNames.CHANGE_DEVICETOKEN, data.access_token);
        //     getDeviceInfo({}).then((data) => {
        //       store.commit(
        //         MutationNames.CHANGE_MONITORCAMERAIP,
        //         data.monitor_camera_ip
        //       );
        //     });
        //   });
        // });
      });
    };

    const localStorageKeyMap = {
      lastLoginSchoolId: "lastLoginSchoolId",
      lastLoginSchoolName: "lastLoginSchoolName",
    };

    const pageState = reactive({
      // 教师绑定学校状态 0没绑定 1绑定一个 2多个
      lastLoginSchoolId: parseInt(
        localStorage.getItem(localStorageKeyMap.lastLoginSchoolId) ?? 0
      ),
      lastLoginSchoolName:
        localStorage.getItem(localStorageKeyMap.lastLoginSchoolName) ?? "",
      teacherBindSchoolStatus: 1,
      currentSchoolName: "",
      currentSchoolId: parseInt(
        localStorage.getItem(localStorageKeyMap.lastLoginSchoolId) ?? 0
      ),
      // 所有关联学校
      bindSchools: [],
      showSelectSchool: false,
      hasSchoolAuth: false,
    });

    // 弹出选择关联学校弹框
    const schoolChange = () => {
      if (!isSchool.value) {
        return;
      }
      pageState.bindSchools.forEach((i) => {
        if (i.school_id === pageState.currentSchoolId) {
          i.disabled = true;
        }
      });
      pageState.showSelectSchool = true;
      isAuthorized.value = false;
    };
    const setCurrentBindSchool = async (schoolId, schoolName) => {
      pageState.currentSchoolId = schoolId;
      pageState.currentSchoolName = schoolName;
      pageState.lastLoginSchoolId = schoolId;
      pageState.lastLoginSchoolName = schoolName;
      localStorage.setItem(localStorageKeyMap.lastLoginSchoolId, schoolId);
      localStorage.setItem(localStorageKeyMap.lastLoginSchoolName, schoolName);
      pageState.hasSchoolAuth = true;
      pageState.showSelectSchool = false;
      // store.commit(MutationNames.CHANGE_SCHOOL_ID, schoolId);
      // store.commit(MutationNames.CHANGE_SCHOOLNAME, schoolName);
      await checkSchoolAuth();
    };

    /**
     * 检查教师绑定学校信息
     * @returns {Promise<boolean>}
     */
    const checkMultipleSchools = async () => {
      const { schools = [] } = await getBindSchools();
      pageState.bindSchools = schools;
      pageState.teacherBindSchoolStatus =
        schools.length >= 2 ? 2 : schools.length;
      if (pageState.teacherBindSchoolStatus === 1) {
        const currentSchool = schools[0];
        await setCurrentBindSchool(
          currentSchool.school_id,
          currentSchool.school_name
        );
      } else if (pageState.teacherBindSchoolStatus === 0) {
        pageState.showSelectSchool = true;
      } else {
        pageState.showSelectSchool = true;
        if (
          !pageState.currentSchoolId ||
          !schools.map((i) => i.school_id).includes(pageState.currentSchoolId)
        ) {
          pageState.currentSchoolId = schools[0].school_id;
        }
        await setCurrentBindSchool(
          store.state.schoolId,
          store.state.schoolName
        );
      }
      await getData();
    };

    const handleSelectSchool = () => {
      const currentSchool = pageState.bindSchools.filter(
        (i) => i.school_id === pageState.currentSchoolId
      )[0];
      if (currentSchool.disabled) {
        ElMessage.info({
          offset: 200,
          message: "该学校未授权，无法使用",
          type: "info",
        });
        return;
      }
      setCurrentBindSchool(currentSchool.school_id, currentSchool.school_name);
    };

    const checkSchoolAuth = async () => {
      const iscompare = compareVersion(store.state.version, "1.2.0");
      if (iscompare >= 0) {
        await getMac();
      }
      // PC版在这里进行授权验证
      if (!isAndroid.value) {
        getData();
        reportData();
        // try {
        //   const { schoolId: school_id } = store.state;
        //   if (school_id) {
        //     let mac = store.state.mac;
        //     if (mac === "0:0:0:0") {
        //       mac = mac + "-" + store.state.schoolId;
        //     }
        //     checkPcTeachAuthorization({
        //       school_id,
        //       chip_id: mac,
        //     })
        //       .then(async (res) => {
        //         authResultType.value = res.auth_result_type;
        //         authResultMessage.value = res.message;
        //         if (res.enable == true) {
        //           // 授权通过之后上报数据
        //           const dataRes = await switchTeacherTokenSchool({ school_id });
        //           authLoading.value = false;
        //           console.log("authLoading解开0");
        //           store.commit(
        //             MutationNames.CHANGE_TOKEN,
        //             dataRes.access_token
        //           );
        //           store.commit(MutationNames.CHANGE_SCHOOL_ID, school_id);
        //           await getCurrentActiveLesson({}).then((data) => {
        //             if (data.current_active_lesson !== null) {
        //               store.commit(
        //                 MutationNames.CHANGE_LESSONRECORDID,
        //                 data.current_active_lesson.id
        //               );
        //               store.commit(
        //                 MutationNames.CHANGE_LESSONCLASSID,
        //                 data.current_active_lesson.class_id
        //               );
        //             } else {
        //               store.commit(MutationNames.CHANGE_LESSONRECORDID, null);
        //               store.commit(MutationNames.CHANGE_LESSONCLASSID, null);
        //             }
        //           });
        //           getData();

        //           reportData();

        //           isAuthorized.value = false;
        //         } else {
        //           authLoading.value = false;
        //           isAuthorized.value = true;
        //           pageState.bindSchools.forEach((i) => {
        //             if (i.school_id === pageState.currentSchoolId) {
        //               i.disabled = true;
        //             }
        //           });
        //         }
        //       })
        //       .catch(() => {
        //         authLoading.value = false;
        //         console.log("authLoading解开1");
        //       });
        //   }
        // } catch (error) {
        //   console.error(error);
        // }
      } else {
        authLoading.value = false;
        isAuthorized.value = false;
      }
    };

    const showConfirmFrame = () => {
      if (!isShowChangeCourseBtn.value) {
        return;
      }
      chooseCourseFlag.value = true;
      console.log(chooseCourseFlag.value);
    };
    const goToChooseCourse = () => {
      let { isChangeSubject, previousSubject } = JSON.parse(
        localStorage.getItem("subjectState")
      );
      isChangeSubject = true;
      previousSubject = localStorage.getItem("subjectDataSelected")
        ? JSON.parse(localStorage.getItem("subjectDataSelected")).subject
        : "";
      localStorage.setItem(
        "subjectState",
        JSON.stringify({ isChangeSubject, previousSubject })
      );
      router.push({
        path: "/chooseCourse",
      });
    };
    const cancelQuitChooseCourse = () => {
      chooseCourseFlag.value = false;
    };

    const openRemoteUrl = (remote) => {
      if (window.require) {
        const { shell } = window.require("electron");
        shell.openExternal(remote.url);
        return;
      }
      window.open(remote.url);
    };

    const initStudentNum = (classId) => {
      if (!classId) {
        return;
      }
      queryClassStudent({
        school_class_id: classId,
      }).then((data) => {
        console.log(1110, data);
        personalStudentMaxNum.value = data.length ?? 0;
        studentNumOpts.value = new Array(personalStudentMaxNum.value + 1)
          .fill(null)
          .map((i, idx) => ({
            value: idx,
            label: idx,
          }));
        if (
          !personalStudentNum.value ||
          personalStudentNum.value > personalStudentMaxNum.value
        ) {
          personalStudentNum.value = data.length;
        }
      });
    };
    onMounted(() => {
      if (!isAndroid.value) {
        // getLessonState();
        // getData();
        if (store.state.firstLoadHome) {
          setStatConfig();
          reportStat({
            moduleid: "login",
            pageid: "login_page",
            eventid: StatEvent.TEACHER_LOGIN,
            eventattr: {
              teacher_id: store.state.teacherId,
              school_id: store.state.schoolId,
              subject: JSON.parse(
                localStorage.getItem("subjectDataSelected") || "{}"
              ),
              login_type: JSON.parse(
                localStorage.getItem("selectedLoginType") || "{}"
              ),
            },
          });
          // 上报教师使用教学系统信息
          const { subject, relation_teaching_system } =
            JSON.parse(localStorage.getItem("subjectDataSelected")) || {};
          if (subject && relation_teaching_system) {
            createTeachingSystemUseRecord({
              subject,
              teaching_system: relation_teaching_system,
            });
          }
          getDevice().then(() => {
            logger.info("获取设备情况");
          });
        }
      }
    });
    onBeforeMount(async () => {
      await getData();
      // if (!store.state.firstLoadHome) {
      //   getData();
      //   return;
      // }
      // // 钉钉登录的不用选择学校和校验权限
      // if (!store.state.isLoginByDingtalk) {
      //   await checkMultipleSchools();
      // } else {
      //   setCurrentBindSchool(store.state.schoolId, store.state.schoolName);
      // }
    });

    onBeforeUnmount(() => {
      // 在页面卸载时将第一次加载页面的状态置为false
      if (store.state.firstLoadHome) {
        store.commit(MutationNames.CHANGE_FIRSTLOADHOME, false);
      }
    });

    const userActionModalVisible = ref(false);

    return {
      studentNumOpts,
      personalStudentMaxNum,
      changeStudentNumPush,
      changeStudentNumReduce,
      personalStudentNum,
      test,
      store,
      userActionModalVisible,
      schoolName,
      handleSelectSchool,
      pageState,
      isAuthorized,
      authResultType,
      authResultMessage,
      authLoading,
      gradeNum,
      classNum,
      teacherName,
      attendance,
      changeAttendance,
      isShow,
      showLoginOutBtn,
      // schoolName,
      schoolChange,
      classChange,
      goBack,
      goToLogin,
      btnClose,
      centain,
      getSchoolClassId,
      gradeOptions,
      classOptions,
      selGrade,
      selClass,
      changeGradeName,
      changeClassName,
      periodsNum,
      changePeriodsReduce,
      changePeriodsPush,
      lessonStart,
      checkFinishLesson,
      confirmFinishLesson,
      confirmFinishLessonFlag,
      cancelQuit,
      isShowChangeCourseBtn,
      courseLabel,
      showConfirmFrame,
      chooseCourseFlag,
      goToChooseCourse,
      cancelQuitChooseCourse,
      remoteUrls,
      openRemoteUrl,
    };
  },
});
