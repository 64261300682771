
import { mapState } from "vuex";
import {
  queryMusicTheories,
  queryMusicTheoryDetail,
  dataEmbeddingPoint,
} from "@/services";
import _ from "lodash";
import store from "@/store/index";
import router from "@/router";
import { useRoute } from "vue-router";
import moment from "moment";
import remindEmptyResource from "@/components/remindEmptyResource.vue";
import logger from "@evideo/logger";
import ShowImage from "@/components/showImage.vue";

export default {
  components: { remindEmptyResource, ShowImage },
  data() {
    return {
      showImgUrl: "",
      showImgDetails: false,
      content: "",
      wrapperLoading: false,
      contentLoading: false,
      menuShow: true,
      contentFontSize: 5,
      isSTB: false,
      treeIdList: [],
      treeData: null,
      activeTreeId: null,
      searchValue: "",
      searchText: "",
      searchVisible: false,
      searchLoading: false,
      serachFinish: false,
      transitionNone: false,
      searchList: [],
      isAndroid: true,
    };
  },
  methods: {
    handleScrollTree() {
      // 自动定位到题目位置
      const treeId = "tree-" + this.activeTreeId;
      const tree = document.getElementById(treeId);
      if (tree) {
        setTimeout(() => {
          tree.scrollIntoView();
        }, 500);
      }
    },
    async queryTreeData() {
      this.wrapperLoading = true;
      const res = await queryMusicTheories({ per_page: 999 });
      let resData = res.data;

      let treeIdList = [];

      // 筛选第一级
      const firstLevel = resData.filter((item) => item.pid === 0 || !item.pid);
      resData = _.difference(resData, firstLevel); // 删除已经查出来的数据

      const secondLevel = firstLevel.map((firstLevelItem) => {
        firstLevelItem.level = 1;

        // 插入第一层Id
        treeIdList.push(firstLevelItem.id);

        // 筛选第二级
        const children = resData.filter(
          (secondLevelItem) => secondLevelItem.pid === firstLevelItem.id
        );
        resData = _.difference(resData, children); // 删除已经查出来的数据

        // 筛选第三级
        firstLevelItem.children = children.map((secondLevelItem) => {
          // 插入第二层Id
          treeIdList.push(secondLevelItem.id);
          secondLevelItem.level = 2;
          const secondLevelChildren = resData.filter(
            (thirdLevelItem) => thirdLevelItem.pid === secondLevelItem.id
          );
          resData = _.difference(resData, secondLevelChildren); // 删除已经查出来的数据

          // 插入第三层Id
          const thirdLevelIds = secondLevelChildren.map((item) => item.id);
          treeIdList = treeIdList.concat(thirdLevelIds);

          secondLevelItem.children = secondLevelChildren;
          return secondLevelItem;
        });
        return firstLevelItem;
      });

      this.treeIdList = treeIdList;
      this.treeData = secondLevel;

      this.$nextTick(() => {
        this.wrapperLoading = false;
      });
    },
    async queryTheoryDetail() {
      this.contentLoading = true;
      const res = await queryMusicTheoryDetail(this.activeTreeId);
      this.content = res.data.content || "";
      this.$refs.theoryContent.scrollTop = 0;
      this.$nextTick(() => {
        this.contentLoading = false;
      });
    },
    handleTreeNodeClick(data, node) {
      logger.info("data", data);
      this.activeTreeId = data.id;
      // 自动定位到题目位置
      // this.handleScrollTree();
    },

    handleChangePage(type) {
      if (this.contentLoading) {
        return;
      }

      const activeTreeIdIndex = this.treeIdList.findIndex(
        (item) => item === this.activeTreeId
      );
      // 上一页
      if (type === 0) {
        this.activeTreeId =
          activeTreeIdIndex > 0
            ? this.treeIdList[activeTreeIdIndex - 1]
            : this.activeTreeId;
      } else {
        this.activeTreeId =
          activeTreeIdIndex + 1 < this.treeIdList.length
            ? this.treeIdList[activeTreeIdIndex + 1]
            : this.activeTreeId;
      }
      this.$refs.tree.setCurrentKey(this.activeTreeId);
      // 自动定位到题目位置
      this.handleScrollTree();
    },
    handleChangeMenuShow() {
      this.menuShow = !this.menuShow;
    },
    async handleSearch() {
      if (!this.searchValue) {
        return;
      }
      this.searchLoading = true;
      const res = await queryMusicTheories({
        per_page: 20,
        content: this.searchValue,
      });
      this.searchList = res.data;
      this.searchText = res.data.length > 0 ? this.searchValue : "";
      this.$nextTick(() => {
        this.searchLoading = false;
        this.serachFinish = true;
      });
    },
    // 调整字体大小
    handleContentFontSizeClick(type) {
      if (type && this.contentFontSize < 11) {
        this.contentFontSize = this.contentFontSize + 1;
        return;
      }
      if (!type && this.contentFontSize > 1) {
        this.contentFontSize = this.contentFontSize - 1;
      }
    },
    async init() {
      this.isSTB = this.$route.query.deviceType === "stb";
      await this.queryTreeData();
      const activeTreeId = parseInt(this.$route.query.id);
      if (activeTreeId) {
        this.activeTreeId = activeTreeId;
        this.menuShow = false;
      } else {
        this.activeTreeId = 3;
      }
      this.$refs.tree.setCurrentKey(this.activeTreeId);
      this.$nextTick(() => {
        logger.info("isAndroid", this.isAndroid);
        // setTimeout(() => {
        this.handleDataReport();
        // }, 10000);
      });
    },
    showSearch() {
      this.searchVisible = true;
    },
    handleSearchItemClick(item) {
      this.activeTreeId = item.id;
      this.$refs.tree.setCurrentKey(this.activeTreeId);

      this.$nextTick(() => {
        // const offsetTop = this.$refs[`treeNode${item.id}`]?.offsetTop;
        // if (offsetTop === 0) {
        //   this.transitionNone = true;
        //   setTimeout(() => {
        //     this.$refs.theoryLeft.scrollTop =
        //       this.$refs[`treeNode${item.id}`]?.offsetTop;
        //     this.transitionNone = false;
        //   }, 60);
        // } else {
        //   this.$refs.theoryLeft.scrollTop = offsetTop;
        // }
        // 自动定位到题目位置
        this.handleScrollTree();
      });
    },
    handleMarkdownImageClick(item) {
      this.showImgDetails = true;
      this.showImgUrl = item.src;
    },
    handleGoBack() {
      router.push({ path: "/musicKnowLedgeHome" });
    },
    handleAndroidGoBack() {
      logger.info("androidgoback");
      logger.info("通知android返回");
      window.WebViewJavascriptBridge.callHandler(
        "jsCallAndroid2Finish", // native中注册时的key值
        null // native中注册方法中的传入的数据
      );
    },
    // 数据埋点
    handleDataReport() {
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      logger.info("this.isAndroid", this.isAndroid);
      if (!this.isAndroid) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_theory_open",
          pageid: "theory",
          moduleid: "teaching",
          eventattr: "",
        };
        logger.info("data", data);
        const body = encodeURIComponent(JSON.stringify(data));
        logger.info("body", body);
        dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            logger.info("安卓调用完成", responseData);
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.eventid = "teaching_theory_open";
            data.pageid = "theory";
            data.moduleid = "teaching";
            data.pt = "android";
            data.eventattr = "";
            logger.info("data", data);
            const body = encodeURIComponent(JSON.stringify(data));
            logger.info("body", body);
            dataEmbeddingPoint(body);
          }
        );
      }
    },
  },
  watch: {
    activeTreeId(value) {
      const currentNode = this.$refs.tree.getNode(value);
      if (currentNode.isLeaf) {
        this.queryTheoryDetail();
      } else {
        this.content = `### ${currentNode.data.name}`;
      }
    },
  },
  computed: {
    ...mapState(["deviceType"]),
  },
  // beforeCreate() {},
  created() {
    const device = navigator.userAgent;
    const deviceType =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    this.isAndroid = deviceType;
    logger.info("isandroid", this.isAndroid);
    logger.info("deviceType", deviceType);
    if (this.isAndroid) {
      logger.info("isAndroid", this.isAndroid);
      const route = useRoute();
      logger.info("route.query.token", route.query.token);
      localStorage.setItem("token", route.query.token);
    }
    this.init();
  },
  mounted() {
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("goHome", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        this.handleGoBack();
      });
    }
  },
};
