
import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  onActivated,
  onMounted,
  watch,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  queryMusicians,
  queryMusiciansCategories,
  dataEmbeddingPoint,
} from "@/services";
import { Calendar } from "@element-plus/icons-vue";
import _ from "lodash";
import { ElMessage } from "element-plus";
import InstrumentsNav from "@/components/preview/InstrumentsNav";
import InstrumentsDetail from "@/components/preview/InstrumentsDetail.vue";
import store from "@/store/index";
import moment from "moment";
import remindEmptyResource from "@/components/remindEmptyResource.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";

export default defineComponent({
  components: {
    Calendar,
    // SearchIcon,
    InstrumentsDetail,
    // ActivePanel,
    InstrumentsNav,
    remindEmptyResource,
    Swiper,
    SwiperSlide,
    // MusicKnowledgeSwiper,
    // MusicKnowledgeWrapper,
  },

  setup() {
    let offsetWidth = ref(false);
    // 乐器列表数据为空时展示的信息
    let swiperTxt = ref("");
    const iconName = "Search";
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const musicianLoading = ref(false);
    const swiperLoading = ref(false);
    let nav = ref([]);
    let swiperList = ref([]);
    let searchList = ref([]);
    let requestData = ref([]);
    let searchValue = ref("");
    let activeCategoryId = ref(null);
    let currentPage = ref(1);
    let searchLastPage = ref(1);
    let perPage = ref(10);
    let lastPage = ref(1);
    let contentLoading = ref(true);
    let searchShow = ref(false);
    let activePanelActive = ref(false);
    let knowledgeData = ref({});
    let NavigationIndex = ref(0);
    let NavigationChildrenIndex = ref(0);
    let isAndroid = ref(true);
    let firstLoading = ref(true);
    let middleSwiperList = ref([]);
    let lastSwiperList = ref([]);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    let firstLoadSwiper = ref(true);
    let mySwiper = ref(null);
    const handleStbClose = () => {
      logger.info("关闭");
    };
    // 数据埋点
    const handleDataReport = (item) => {
      logger.info("item.id", item.id);
      const eventattr = { id: item.id };
      logger.info("eventattr", eventattr);
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (!isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_musician_open",
          pageid: "musician",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        logger.info("data", data);
        const body = encodeURIComponent(JSON.stringify(data));
        logger.info("body", body);
        dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            logger.info("安卓调用完成", responseData);
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_musician_open";
            data.pageid = "musician";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            logger.info("data", data);
            const body = encodeURIComponent(JSON.stringify(data));
            logger.info("body", body);
            dataEmbeddingPoint(body);
          }
        );
      }
    };
    // 隐藏详情面板
    const handleActivePanelHide = () => {
      activePanelActive.value = false;
    };
    // 展示详情面板
    const handleItemClick = (item) => {
      handleDataReport(item);
      logger.info("item", item);
      knowledgeData.value = item;
      logger.info("knowledgeData", knowledgeData.value);
      activePanelActive.value = true;
    };
    // 用户搜索
    const handleSearch = _.throttle(async () => {
      mySwiper.value.touches.diff = 0;
      if (!searchValue.value) {
        // ElMessage.warning({
        //   offset: 200,
        //   message: "请输入搜索内容",
        //   type: "warning",
        // });
        handleInitSwiperData(
          NavigationIndex.value,
          NavigationChildrenIndex.value
        );
        return;
      }
      currentPage.value = 1;
      swiperLoading.value = true;
      let res = null;
      let searchMiddleRes = null;
      let searchLastRes = null;
      res = await queryMusicians({
        page: 1,
        per_page: perPage.value,
        name: searchValue.value,
      });
      searchMiddleRes = await queryMusicians({
        page: 2,
        per_page: perPage.value,
        name: searchValue.value,
      });
      searchLastRes = await queryMusicians({
        page: 3,
        per_page: perPage.value,
        name: searchValue.value,
      });
      let count = 10;
      const swiperListData = [];
      const swiperListDataMiddle = [];
      const swiperListDataLast = [];
      res.data.forEach((item) => {
        if (count === 10) {
          swiperListData.push({
            id: swiperListData.length + 1,
            content: [],
          });
          count = 0;
        }
        logger.info("swiperListData", swiperListData);
        item.avatar = item.avatar.includes("https")
          ? item.avatar
          : item.avatar.replace("http", "https");
        swiperListData[swiperListData.length - 1].content.push(item);
        count++;
      });
      // 2
      searchMiddleRes.data.forEach((item) => {
        if (count === 10) {
          swiperListDataMiddle.push({
            id: swiperListDataMiddle.length + 1,
            middleContent: [],
          });
          count = 0;
        }
        logger.info("swiperListDataMiddle", swiperListDataMiddle);
        item.avatar = item.avatar.includes("https")
          ? item.avatar
          : item.avatar.replace("http", "https");
        swiperListDataMiddle[
          swiperListDataMiddle.length - 1
        ].middleContent.push(item);
        count++;
      });
      // 3
      searchLastRes.data.forEach((item) => {
        if (count === 10) {
          swiperListDataLast.push({
            id: swiperListDataLast.length + 1,
            lastContent: [],
          });
          count = 0;
        }
        logger.info("swiperListDataLast", swiperListDataLast);
        item.avatar = item.avatar.includes("https")
          ? item.avatar
          : item.avatar.replace("http", "https");
        swiperListDataLast[swiperListDataLast.length - 1].lastContent.push(
          item
        );
        count++;
      });
      lastPage.value = res.meta.last_page;
      searchLastPage.value = res.meta.last_page > 3 ? 3 : res.meta.last_page;
      searchList.value = swiperListData;
      swiperList.value = swiperListData;
      middleSwiperList.value = swiperListDataMiddle;
      lastSwiperList.value = swiperListDataLast;
      searchShow.value = true;
      // 选择类型或搜索之后判断边界值
      if (currentPage.value == 1 && lastPage.value !== 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = true;
      } else if ((currentPage.value == lastPage.value) == 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = false;
      } else {
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      }
      logger.info(
        "我在函数里判断完了",
        currentPage.value,
        lastPage.value,
        leftBorderFlag.value,
        rightBorderFlag.value
      );
      if (swiperList.value.length > 0) {
        swiperTxt.value = "";
      } else {
        swiperTxt.value = "showQR";
      }
      nextTick(() => {
        swiperLoading.value = false;
      });
    }, 500);
    // 初始化 Swiper
    const handleInitSwiperData = async (index, childrenIndex) => {
      const selectItem = nav.value[index].children[childrenIndex];
      logger.info("selectItem", selectItem);
      // if (activeCategoryId.value === selectItem.id) {
      //   return;
      // }
      // 右边loading
      swiperLoading.value = true;
      // 整个页面loading
      // musicianLoading.value = true;
      activeCategoryId.value = selectItem.id;
      currentPage.value = 1;
      mySwiper.value.activeIndex = 1;
      await getInstrumentsInfo();
      logger.info("swiperList.value", swiperList.value);
      nextTick(() => {
        contentLoading.value = false;
        swiperLoading.value = false;
      });
      firstLoading.value = false;
    };
    // 获取乐器数据
    const getInstrumentsInfo = async () => {
      mySwiper.value.touches.diff = 0;
      let res = null;
      let middleRes = null;
      let lastRes = null;
      if (!searchValue.value) {
        res = await queryMusicians({
          page: 1,
          per_page: perPage.value,
          sub_type: activeCategoryId.value,
          name: searchValue.value,
        });
        middleRes = await queryMusicians({
          page: 2,
          per_page: perPage.value,
          sub_type: activeCategoryId.value,
          name: searchValue.value,
        });
        lastRes = await queryMusicians({
          page: 3,
          per_page: perPage.value,
          sub_type: activeCategoryId.value,
          name: searchValue.value,
        });
      } else {
        res = await queryMusicians({
          page: 1,
          per_page: perPage.value,
          name: searchValue.value,
        });
        middleRes = await queryMusicians({
          page: 2,
          per_page: perPage.value,
          name: searchValue.value,
        });
        lastRes = await queryMusicians({
          page: 3,
          per_page: perPage.value,
          name: searchValue.value,
        });
      }

      lastPage.value = res.meta.last_page;
      logger.info("lastPage", lastPage.value);
      const content = res.data.map((item) => {
        item.avatar = item.avatar.includes("https")
          ? item.avatar
          : item.avatar.replace("http", "https");
        return item;
      });
      const swiperListData = [{ content }];
      for (let i = 0; i < lastPage.value - 1; i++) {
        swiperListData.push({
          content: [],
        });
      }
      swiperList.value = swiperListData;
      logger.info("swiperList", swiperList.value);
      // data2
      const middleContent = middleRes.data.map((item) => {
        item.avatar = item.avatar.includes("https")
          ? item.avatar
          : item.avatar.replace("http", "https");
        return item;
      });
      const middleSwiperListData = [{ middleContent }];
      for (let i = 0; i < lastPage.value - 1; i++) {
        middleSwiperListData.push({
          middleContent: [],
        });
      }
      middleSwiperList.value = middleSwiperListData;
      logger.info("middleSwiperList", middleSwiperList.value);
      // data3
      const lastContent = lastRes.data.map((item) => {
        item.avatar = item.avatar.includes("https")
          ? item.avatar
          : item.avatar.replace("http", "https");
        return item;
      });
      const lastSwiperListData = [{ lastContent }];
      for (let i = 0; i < lastPage.value - 1; i++) {
        lastSwiperListData.push({
          lastContent: [],
        });
      }
      lastSwiperList.value = lastSwiperListData;
      mySwiper.value.activeIndex = 1;
      logger.info("lastSwiperList===>", lastSwiperList.value);
      // 选择类型或搜索之后判断边界值
      if (currentPage.value == 1 && lastPage.value !== 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = true;
      } else if ((currentPage.value == lastPage.value) == 1) {
        leftBorderFlag.value = false;
        rightBorderFlag.value = false;
      } else {
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      }
      logger.info(
        "我在函数里判断完了",
        currentPage.value,
        lastPage.value,
        leftBorderFlag.value,
        rightBorderFlag.value
      );
      if (res.data.length > 0) {
        swiperTxt.value = "";
      } else {
        swiperList.value.length = 0;
        swiperTxt.value = "暂无相关数据";
      }
      nextTick(() => {
        musicianLoading.value = false;
      });
    };
    // 导航栏点击
    const handleNavItemClick = (index, childrenIndex) => {
      logger.info("index-->", index);
      logger.info("childrenIndex-->", childrenIndex);
      NavigationIndex.value = index;
      NavigationChildrenIndex.value = childrenIndex;
      searchValue.value = "";
      handleInitSwiperData(index, childrenIndex);
    };
    // 初始化数据
    const init = async () => {
      // 重置数据
      nav.value.lenght = 0;
      swiperList.value.lenght = 0;
      requestData.value.lenght = 0;
      let resCategories = null;
      resCategories = await queryMusiciansCategories();
      logger.info("resCategories-->", resCategories);

      const navDate = resCategories.filter((item) => item.parent_id === 0);
      nav.value = navDate.map((item) => {
        item.children = resCategories.filter(
          (childItem) => childItem.parent_id === item.id
        );
        return item;
      });
      logger.info("nav.value-->", nav.value);
      handleInitSwiperData(
        NavigationIndex.value,
        NavigationChildrenIndex.value
      );
    };
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info("当前窗口是在==>", mySwiper.value.activeIndex);
        logger.info(
          "页面切换到上一页函数里拿到的当前页数是===>",
          currentPage.value - 1
        );
        currentPage.value--;
        if (currentPage.value !== 1) {
          getPrevData(currentPage.value - 1);
        }
      }
      // getPrevData(currentPage.value - 1, params);
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      if (!firstLoadSwiper.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info(
            "页面切换到下一页函数里拿到的当前页数是===>",
            currentPage.value + 1
          );
          currentPage.value++;
          if (currentPage.value !== lastPage.value) {
            getNextData(currentPage.value + 1);
          }
        }
        // getNextData(currentPage.value + 1, params);
      }
    };
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      logger.info(
        "点击按钮切换到上一页函数里拿到的当前页数是===>",
        currentPage.value - 1
      );
      if (currentPage.value === 1) {
        ElMessage.warning({
          offset: 200,
          message: "已经是第一页",
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // logger.info("indexpre", startIndex, endIndex);
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      logger.info(
        "点击按钮切换到下一页函数里拿到的当前页数是===>",
        currentPage.value + 1
      );
      if (currentPage.value === lastPage.value) {
        ElMessage.warning({
          offset: 200,
          message: "已经是最后一页",
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
        // currentPage.value++;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    const initMySwiper = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };
    // 拿下一页数据
    const getNextData = (page) => {
      let nextData = null;
      if (!searchValue.value) {
        nextData = queryMusicians({
          page: page,
          per_page: perPage.value,
          second_category: activeCategoryId.value,
          name: searchValue.value,
        });
      } else {
        nextData = queryMusicians({
          page: page,
          per_page: perPage.value,
          name: searchValue.value,
        });
      }
      if ((currentPage.value + 1) % 3 == 1) {
        const content = nextData.data.map((item) => {
          item.avatar = item.avatar.includes("https")
            ? item.avatar
            : item.avatar.replace("http", "https");
          return item;
        });
        const nextSwiperListData = [{ content }];
        for (let i = 0; i < lastPage.value - 1; i++) {
          nextSwiperListData.push({
            content: [],
          });
        }
        swiperList.value.length = 0;
        swiperList.value = nextSwiperListData;
      }
      if ((currentPage.value + 1) % 3 == 2) {
        const middleContent = nextData.data.map((item) => {
          item.avatar = item.avatar.includes("https")
            ? item.avatar
            : item.avatar.replace("http", "https");
          return item;
        });
        const nextSwiperListData = [{ middleContent }];
        for (let i = 0; i < lastPage.value - 1; i++) {
          nextSwiperListData.push({
            middleContent: [],
          });
        }
        middleSwiperList.value.length = 0;
        middleSwiperList.value = nextSwiperListData;
      }
      if ((currentPage.value + 1) % 3 == 0) {
        const lastContent = nextData.data.map((item) => {
          item.avatar = item.avatar.includes("https")
            ? item.avatar
            : item.avatar.replace("http", "https");
          return item;
        });
        const nextSwiperListData = [{ lastContent }];
        for (let i = 0; i < lastPage.value - 1; i++) {
          nextSwiperListData.push({
            lastContent: [],
          });
        }
        lastSwiperList.value.length = 0;
        lastSwiperList.value = nextSwiperListData;
      }
    };
    // 拿上一页数据
    const getPrevData = (page) => {
      let prevData = null;
      if (!searchValue.value) {
        prevData = queryMusicians({
          page: page,
          per_page: perPage.value,
          second_category: activeCategoryId.value,
          name: searchValue.value,
        });
      } else {
        prevData = queryMusicians({
          page: page,
          per_page: perPage.value,
          name: searchValue.value,
        });
      }
      if ((currentPage.value - 1) % 3 == 1) {
        const content = prevData.data.map((item) => {
          item.avatar = item.avatar.includes("https")
            ? item.avatar
            : item.avatar.replace("http", "https");
          return item;
        });
        const prevSwiperListData = [{ content }];
        for (let i = 0; i < lastPage.value - 1; i++) {
          prevSwiperListData.push({
            content: [],
          });
        }
        swiperList.value.length = 0;
        swiperList.value = prevSwiperListData;
      }
      if ((currentPage.value - 1) % 3 == 2) {
        const middleContent = prevData.data.map((item) => {
          item.avatar = item.avatar.includes("https")
            ? item.avatar
            : item.avatar.replace("http", "https");
          return item;
        });
        const prevSwiperListData = [{ middleContent }];
        for (let i = 0; i < lastPage.value - 1; i++) {
          prevSwiperListData.push({
            middleContent: [],
          });
        }
        middleSwiperList.value.length = 0;
        middleSwiperList.value = prevSwiperListData;
      }
      if ((currentPage.value - 1) % 3 == 0) {
        const lastContent = prevData.data.map((item) => {
          item.avatar = item.avatar.includes("https")
            ? item.avatar
            : item.avatar.replace("http", "https");
          return item;
        });
        const prevSwiperListData = [{ lastContent }];
        for (let i = 0; i < lastPage.value - 1; i++) {
          prevSwiperListData.push({
            lastContent: [],
          });
        }
        lastSwiperList.value.length = 0;
        lastSwiperList.value = prevSwiperListData;
      }
    };
    // 返回
    // const handleGoMusicKnowLedgeHome = () => {
    //   router.push({
    //     path: "/musicKnowLedgeHome",
    //   });
    // };
    // android返回
    const handleAndroidGoBack = () => {
      // android返回
      logger.info("androidgoback");
      logger.info("通知android返回");
      window.WebViewJavascriptBridge.callHandler(
        "jsCallAndroid2Finish", // native中注册时的key值
        null // native中注册方法中的传入的数据
      );
    };
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.on("musicianGoBack", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        if (activePanelActive.value) {
          activePanelActive.value = false;
        } else {
          history.back();
        }
        // handleGoMusicKnowLedgeHome();
      });
    }
    onBeforeMount(() => {
      const device = navigator.userAgent;
      isAndroid.value =
        device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      logger.info("isAndroid", isAndroid.value);
      if (isAndroid.value) {
        logger.info("android", route.query.token);
        localStorage.setItem("token", route.query.token);
      }
      musicianLoading.value = true;
      if (document.body.offsetWidth >= 1920) {
        offsetWidth.value = true;
      }
      init();
    });
    onMounted(() => {
      initMySwiper();
      firstLoadSwiper.value = false;
      logger.info("swiperList.value.length==>", swiperList.value.length);
      firstLoading.value = false;
    });
    onBeforeUnmount(() => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.removeAllListeners("musicianGoBack");
    });
    onActivated(() => {
      // this.reload();
      // location.reload();
    });
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          leftBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        logger.info("总页数", lastPage.value);
        if (currentPage.value === lastPage.value) {
          rightBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          rightBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        logger.info("在这看触摸距离", mySwiper.value.touches.diff);
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          ElMessage.warning({
            offset: 200,
            message: "已经是第一页",
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === lastPage.value
        ) {
          ElMessage.warning({
            offset: 200,
            message: "已经是最后一页",
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );
    return {
      musicianLoading,
      swiperTxt,
      offsetWidth,
      iconName,
      loading,
      knowledgeData,
      activePanelActive,
      swiperList,
      nav,
      currentPage,
      lastPage,
      searchValue,
      swiperLoading,
      contentLoading,
      firstLoading,
      middleSwiperList,
      lastSwiperList,
      leftBorderFlag,
      rightBorderFlag,
      firstLoadSwiper,
      mySwiper,
      getNextData,
      getPrevData,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      initMySwiper,
      handleStbClose,
      handleNavItemClick,
      handleInitSwiperData,
      handleSearch,
      handleActivePanelHide,
      handleItemClick,
      previous,
      next,
      // handleGoMusicKnowLedgeHome,
      handleAndroidGoBack,
      isAndroid,
    };
  },
});
