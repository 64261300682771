
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onActivated,
  onMounted,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getTeacherInfo } from "@/services";
import store, { MutationNames } from "@/store/index";
import JsBridge from "../../jsBridge";
import { dataEmbeddingPoint } from "@/services";
import config from "@/config/app.config";
import { eventCenter, event } from "@evideo/frontend-utils";
import HomeBackground from "@/components/common/HomeBackground";
import logger from "@evideo/logger";

export default defineComponent({
  components: {
    HomeBackground,
  },
  setup() {
    const loading = ref(false);
    const teacherName = ref(store.state.nickName);
    const schoolName = ref(store.state.schoolName);
    const router = useRouter();
    // 是否劢联定制版
    let isML = ref(false);
    let isAndroid = ref(true);
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

    // 返回
    const handleGoToHome = () => {
      router.push({
        path: "/home",
      });
    };
    const schoolLogo = computed(() => {
      return store.state.schoolLogo;
    });
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("goHome", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        handleGoToHome();
      });
    }

    onBeforeMount(() => {
      isML.value = store.state.isML;
      logger.info("gradeNumber", store.state.gradeNumber);
      logger.info("classNumber", store.state.classNumber);
      logger.info("schoolClassId", store.state.schoolClassId);
    });
    onActivated(() => {
      // this.reload();
      // location.reload();
    });
    const goToInstruments = () => {
      router.push({
        path: "/instruments",
      });
    };
    const goToMusician = () => {
      router.push({
        path: "/musician",
      });
    };
    const goToTheory = () => {
      router.push({
        path: "/theory",
      });
    };
    const goToStaff = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };

    const goToLogin = () => {
      router.push({
        path: "/home",
      });
    };

    const goLeft = () => {
      const elem = document.getElementById("Middle");
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft += 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    const goRight = () => {
      const elem = document.getElementById("Middle");
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft -= 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    return {
      loading,
      teacherName,
      schoolName,
      goToInstruments,
      goToMusician,
      goToTheory,
      goToStaff,
      goToLogin,
      goLeft,
      goRight,
      handleGoToHome,
      isML,
      schoolLogo,
    };
  },
});
