
import { mapState } from "vuex";
export default {
  name: "MusicKnowledgeInstrumentsNav",
  components: {},
  data() {
    return {
      navActive: 1,
      childrenActive: null,
    };
  },
  props: {
    nav: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    handleChangeNavActive(item, index) {
      this.childrenActive = item.children[0].id;
      this.navActive = item.id;
      this.$emit("navItemClick", index, 0);
    },
    handlechangeNavChildrenActive(childrenItem, index, childrenIndex) {
      this.childrenActive = childrenItem.id;
      this.$emit("navItemClick", index, childrenIndex);
    },
  },
  computed: {
    childrenHeight() {
      return this.deviceType === "stb" && document.body.offsetWidth === 1920
        ? 70
        : 70;
    },
    ...mapState(["deviceType"]),
  },
  mounted() {
    this.childrenActive = this.nav[0].children[0].id;
  },
};
