import servers from "./servers";

export default {
  [servers.user]: "https://devpuser-api.jilingshu.com.cn",
  [servers.device]: "https://devpapi.jilingshu.com.cn",
  [servers.platform]: "https://devpplatform-api.jilingshu.com.cn",
  [servers.pptist]: "https://devpppt.jilingshu.com.cn",
  [servers.ecoach]: "https://devpmusic-score.jilingshu.com.cn",
  [servers.resource]: "https://devpresource-center.jilingshu.com.cn",
  [servers.ec]: "https://devpec.jilingshu.com.cn/stat",
  [servers.qrcode]: "https://devpgateway.jilingshu.com.cn/api/common-service/",
  [servers.kefuQrcode]:
    "https://devpgateway.jilingshu.com.cn/api/common-service/",
  [servers.authorization]: "https://devpapi.jilingshu.com.cn",
  [servers.musicGame]: "https://devpinteractive-teach.jilingshu.com.cn",
  [servers.common]: "https://devpgateway.jilingshu.com.cn/api/common-service/",
  [servers.tools]: "https://devph5-tools.jilingshu.com.cn",
  [servers.localResource]: "http://127.0.0.1",
  [servers.exam]: "https://devpexam-center-service.jilingshu.com.cn",
  [servers.dingtalkCallback]:
    "https://devpmulti-platform.jilingshu.com.cn/login",
  [servers.school]: "https://devpschool.jilingshu.com.cn",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://devpcloud-pre-render-service.jilingshu.com.cn",
  [servers.app]: "https://devpmulti-platform.jilingshu.com.cn",
  [servers.api]: "https://devpapi.jilingshu.com.cn/",
};
