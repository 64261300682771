import servers from "@/config/servers";
import {
  commonGetRequest,
  fetchGetRequest,
  request,
  requestDevice,
} from "@/utils/request";
import logger from "@evideo/logger";

/**
 * request 请求参数
 * (
 *    http method, http 请求方法
 *    request relate path, 请求的相对路径
 *    server, 请求的服务，来源于 servers
 *    params, 请求参数
 *    need token 是否需要 token
 * )
 */

// 查询学校列表
export async function getSchoolList(params: any) {
  return request("GET", `/school/queryName`, servers.common, params);
}

// 获取某学校支持的登录方式
export async function getSupportLoginTypes(schoolId: number) {
  return request(
    "GET",
    `/school/${schoolId}/getSupportLoginTypes`,
    servers.common
  );
}

// 教师登录
export async function teachLogin(params: any) {
  return request("POST", "/teacher/auth/login", servers.device, params);
}

// 获取教师信息
export async function getTeacherInfo(params: any) {
  return request("GET", "/teacher/auth/me", servers.device, params);
}

// 获取教材中心教材列表
export async function getBooks(params: any) {
  return request("GET", "/official/books", servers.resource, params);
}

// 获取教师个人教材列表
export async function getTeacherBooks(params: any) {
  return request("GET", "/teacher/books", servers.resource, params);
}

// 获取教材章节目录
export async function getSections(id: number) {
  return request("GET", `/official/book/${id}/sections`, servers.resource);
}
// 获取教师教材章节目录
export async function getTeacherSections(id: number) {
  return request("GET", `/teacher/book/${id}/sections`, servers.resource);
}

// 考评系统-提交考试答案
export async function uploadExamAnswer(paperId: any, params: any) {
  return request(
    "POST",
    `/student/evaluationPapers/${paperId}`,
    servers.device,
    params
  );
}

// 获取我的课件列表
export async function getMyCourseWare(params: any) {
  params.courseware_types = ["ppt", "image", "video", "audio", "pdf", "swf"];
  return request(
    "GET",
    "/teacher/courseware-space-nodes",
    servers.resource,
    params
  );
}
// 获取我的课件列表,检索时
export async function getMyCourseWareSearch(params: any) {
  params.types = ["ppt", "image", "video", "audio", "pdf", "swf"];
  console.log("params", params);
  return request("GET", `/teacher/coursewares`, servers.resource, params);
}

// 获取我的课件详情
export async function getMyCourseWareDetails(params: any) {
  params.types = ["ppt", "image", "video", "audio", "pdf", "swf"];
  console.log("params", params);
  return request(
    "GET",
    `/teacher/coursewares/${params.id}`,
    servers.resource,
    params
  );
}

// 获取官方课件列表
export async function getCoursewares(params: any) {
  params.types = ["ppt", "image", "video", "audio", "pdf", "swf"];
  console.log("params", params);
  return request("GET", "/official/coursewares", servers.resource, params);
}

// 从官方课件复制到个人课件(获取)
export async function copyOfficial(params: any) {
  return request(
    "POST",
    "/teacher/courseware/copy-from-official",
    servers.resource,
    params
  );
}
// 从区域课件复制到个人课件(获取)
export async function copyArea(params: any) {
  return request(
    "POST",
    "/teacher/courseware/copy-from-area-public",
    servers.resource,
    params
  );
}

// 从新增课件访问记录
export async function addViewLog(id: any) {
  return request(
    "POST",
    `/official/courseware/${id}/add-view-log`,
    servers.resource
  );
}

// 新增课件下载记录
export async function addDownloadLog(id: any) {
  return request(
    "POST",
    `/official/courseware/${id}/add-download-log`,
    servers.resource
  );
}

// 获取学校pc教学软件包信息
export async function getSchoolPcSoftwareUpgradeInfo(params: any) {
  return request(
    "GET",
    "/teacher/schoolPcSoftwareUpgradeInfo",
    servers.device,
    params
  );
}

// 获取乐器类别
export async function queryInstrumentCategories(params: any) {
  return request("GET", "/wps/instrumentCategories", servers.user, params);
}

// 获取乐器列表
export async function queryInstruments(params: any) {
  return request("GET", "/wps/instruments", servers.user, params);
}

// 获取乐器详情
export async function queryInstrumentsDetails(id: any, params = {}) {
  return request("GET", `/preview/instruments/${id}`, servers.user, params);
}

// 获取音乐家类别
export async function queryMusiciansCategories(params: any) {
  return request("GET", "/wps/musicianCategories", servers.user, params);
}

// 获取音乐家列表
export async function queryMusicians(params: any) {
  return request("GET", "/wps/musicians", servers.user, params);
}

// 获取音乐家详情
export async function queryMusiciansDetails(id: any, params = {}) {
  return request("GET", `/preview/musicians/${id}`, servers.user, params);
}

// 获取乐理知识列表
export async function queryMusicTheories(params = {}) {
  return request("GET", "/wps/musicTheories", servers.user, params);
}

// 获取乐理知识详情
export async function queryMusicTheoryDetail(id: any, params = {}) {
  return request("GET", `/preview/musicTheory/${id}`, servers.user, params);
}

// 获取班级列表
export async function queryClass(params: any) {
  return request("GET", "/teacher/schoolClasses", servers.device, params);
}

// 获取班级学生列表
export async function queryClassStudent(params: any) {
  return request("GET", "/teacher/classStudents", servers.device, params);
}
// 获取班级数据
export async function getSchoolClassName(params: any) {
  return request("GET", "/teacher/schoolClasses", servers.device, params);
}
// 上报设备信息
export async function uploadData(params: any) {
  return request("POST", "/device/report-data", servers.device, params);
}
// 获取局端课件列表
export async function getInstitutionCoursewares(params: any) {
  params.types = ["ppt", "image", "video", "audio", "pdf", "swf"];
  console.log("params", params);
  return request("GET", "/teacher/area-public-coursewares", servers.resource, {
    ...params,
    owner_type: 2,
  });
}

// 获取学校课件列表
export async function getSchoolCoursewares(params: any) {
  params.types = ["ppt", "image", "video", "audio", "pdf", "swf"];
  console.log("params", params);
  return request("GET", "/teacher/area-public-coursewares", servers.resource, {
    ...params,
    owner_type: 1,
  });
}

// 通过学校id查询上级机构信息
export async function getSchoolInstitutionInfo(id: any) {
  return request("GET", `/common/school-uplevel-ins-data/`, servers.resource, {
    school_id: id,
  });
}

// 从新增课件访问记录
export async function addInsViewLog(id: any) {
  return request(
    "POST",
    `/teacher/area-public-courseware/${id}/add-view-log`,
    servers.resource
  );
}

// 新增课件下载记录
export async function addInsDownloadLog(id: any) {
  return request(
    "POST",
    `/teacher/area-public-courseware/${id}/add-download-log`,
    servers.resource
  );
}

// 数据埋点get
export async function dataGetEmbeddingPoint(data: any) {
  const url = "http://devpec.jilingshu.com.cn.tech/stat?body=" + data;
  logger.info("servers.ecure", url);
  return commonGetRequest("http://devpec.jilingshu.com.cn.tech/stat", {
    body: data,
  });
}

// 数据埋点fetch get
export async function dataEmbeddingPoint(data: any) {
  const env = process.env.RELEASE_ENV;
  let url = "";
  if (env === "prod") {
    url = "https://ec.jilingshu.com.cn.tech/stat?body=" + data;
  } else {
    url = "https://" + env + "ec.jilingshu.com.cn.tech/stat?body=" + data;
  }

  // const url = "http://10.22.21.116:8081/stat?body=" + data;
  logger.info("servers.ecure", url);
  return fetchGetRequest(url);
}

// 教师开始上课
export async function startLesson(params: any) {
  return request(
    "POST",
    `/teacher/lessonRecord/startLesson`,
    servers.user,
    params
  );
}
// 教师结束上课
export async function finishLesson(params: any) {
  return request(
    "POST",
    `/teacher/lessonRecord/finishLesson`,
    servers.user,
    params
  );
}
// 获取老师当前正在上课的记录
export async function getCurrentActiveLesson(params: any) {
  return request(
    "GET",
    "/teacher/lessonRecord/getCurrentActiveLesson",
    servers.user,
    params
  );
}
//  获取教师扫码登录二维码
export async function getTeacherQRLoginCode() {
  return request("GET", "/social/wechat/getTeacherQRLoginCode", servers.qrcode);
}
//  轮询验证扫码登录状态
export async function queryTeacherLoginQrResult(event_key: any) {
  return request(
    "GET",
    "/social/wechat/queryTeacherLoginQrResult",
    servers.qrcode,
    event_key
  );
}
// 获取客服微信二维码
export async function getKefuWechatQRCode() {
  return request("GET", "/getKefuWechatQRCode", servers.kefuQrcode);
}
// 获取pc端授权状态
export async function checkPcTeachAuthorization(params: any) {
  return request(
    "GET",
    "/device/checkPcTeachAuthorization",
    servers.authorization,
    params
  );
}

// 获取客户端配置 第一步
export async function getClientInfo(params: any, showErrorMessage = true) {
  return requestDevice(
    "GET",
    "/device/auth/client-info",
    servers.device,
    params,
    showErrorMessage
  );
}
// 设备登录 第二步
export async function deviceLogin(params: any, showErrorMessage = true) {
  return requestDevice(
    "POST",
    "/device/auth/login",
    servers.device,
    params,
    showErrorMessage
  );
}
// 获取设备信息 第三步
export async function getDeviceInfo(params: any, showErrorMessage = true) {
  return requestDevice(
    "GET",
    "/device/auth/me",
    servers.device,
    params,
    showErrorMessage
  );
}

// 图片上传
export async function addLessonProcessData(params: any, needErrMes: boolean) {
  // needErrMes 是否需要提示错误信息
  return request(
    "POST",
    "/teacher/lessonRecord/addLessonProcessData",
    servers.user,
    params,
    undefined,
    undefined,
    needErrMes
  );
}

// 获取赏析资源类型
export async function getFamousMusicSongType(params: any) {
  return request(
    "GET",
    "/official/famous-music-song-categories",
    servers.resource,
    params
  );
}
// 获取赏析资源列表
export async function getFamousMusicSongs(params: any) {
  return request(
    "GET",
    "/official/famous-music-songs",
    servers.resource,
    params
  );
}

// 获取赏析资源详情
export async function getFamousMusicSong(id: number) {
  return request("GET", `/official/famous-music-song/${id}`, servers.resource);
}

// 获取绑定学校信息
export const getBindSchools = () =>
  request("GET", "/teacher/getTeacherBindSchools", servers.common);

// 检查PC教学授权是否可用
export const checkPcTeacherAuth = (params: any) =>
  request("GET", "/device/checkPcTeachAuthorization", servers.device, params);

// 切换登陆
export const switchTeacherTokenSchool = (params: any) =>
  request("POST", "/teacher/switchTeacherTokenSchool", servers.common, params);

// 批量查询本地资源是否需要更新
export const compareLocalResourceUpdate = async (params: any) => {
  return await request(
    "POST",
    "/common/compare-resource-update-time",
    servers.resource,
    params
  );
};

// 老师上报教学系统使用
export async function createTeachingSystemUseRecord(params?: any) {
  return await request(
    "POST",
    "/teacher/createTeachingSystemUseRecord",
    servers.user,
    params
  );
}
// 获取/复制律动
export async function getMelodyFile(id: any) {
  return request("POST", `/teacher/userMelodyFiles/copy/${id}`, servers.device);
}
// 删除律动
export async function delMelodyFile(id: any) {
  return request(
    "DELETE",
    `/teacher/userMelodyFiles/delete/${id}`,
    servers.device
  );
}
