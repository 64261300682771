import servers from "./servers";

export default {
  [servers.user]: "https://preuser-api.jilingshu.com.cn",
  [servers.device]: "https://preapi.jilingshu.com.cn",
  [servers.platform]: "https://preplatform-api.jilingshu.com.cn",
  [servers.pptist]: "https://preppt.jilingshu.com.cn",
  [servers.ecoach]: "https://premusic-score.jilingshu.com.cn",
  [servers.resource]: "https://preresource-center.jilingshu.com.cn",
  [servers.ec]: "https://preec.jilingshu.com.cn/stat",
  [servers.qrcode]: "https://pregateway.jilingshu.com.cn/api/common-service/",
  [servers.kefuQrcode]:
    "https://pregateway.jilingshu.com.cn/api/common-service/",
  [servers.authorization]: "https://preapi.jilingshu.com.cn",
  [servers.musicGame]: "https://preinteractive-teach.jilingshu.com.cn",
  [servers.common]: "https://pregateway.jilingshu.com.cn/api/common-service/",
  [servers.tools]: "https://preh5-tools.jilingshu.com.cn",
  [servers.exam]: "https://preexam-center-service.jilingshu.com.cn",
  [servers.localResource]: "http://127.0.0.1",
  [servers.dingtalkCallback]:
    "https://premulti-platform.jilingshu.com.cn/login",
  [servers.school]: "https://preschool.jilingshu.com.cn",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://precloud-pre-render-service.jilingshu.com.cn",
  [servers.app]: "https://premulti-platform.jilingshu.com.cn",
  [servers.api]: "https://preapi.jilingshu.com.cn/",
};
