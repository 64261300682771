
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  onActivated,
  toRefs,
  toRaw,
  onBeforeUnmount,
  watch,
} from "vue";
import store, { MutationNames } from "@/store/index";
import { ElMessage } from "element-plus";
import { onBeforeRouteLeave, useRouter, useRoute } from "vue-router";
import {
  getTeacherInfo,
  getCoursewares,
  getBooks,
  getSections,
  dataEmbeddingPoint,
  getFamousMusicSongs,
  getFamousMusicSongType,
} from "@/services";
import { usePageTableContext } from "@evideo/frontend-utils";
import _ from "lodash";
import pageTableContextMap from "@/utils/page-table-context-map";
import moment from "moment";
import remindEmptyResource from "@/components/remindEmptyResource.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";
import AppreciationDetail from "@/components/AppreciationDetail.vue";

export default defineComponent({
  components: {
    AppreciationDetail,
    remindEmptyResource,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const cascaderRef = ref();
    let CoursewaresId = ref("");
    let refreshItem = ref(0);
    let isAndroid = ref(true);
    let firstLoading = ref(true);
    let mySwiper = ref(null);
    const router = useRouter();
    const route = useRoute();
    let teacherName = ref(store.state.nickName);
    let schoolName = ref(store.state.schoolName);
    // 当前页
    let currentPage = ref(1);
    // 总页数
    let totalPage = ref(1);
    let message = ref("");
    let searchName = ref("");
    let cascaderList = ref([]);
    let teachResourceEight = reactive([]);
    let middleTeachResourceEight = reactive([]);
    let lastTeachResourceEight = reactive([]);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    let firstLoad = ref(false);
    let noData = ref(false);
    let noDataMsg = ref(false);
    // 是否劢联定制版
    let isML = ref(false);
    // 详情组件是否显示
    let activePanelActive = ref(false);

    let typeValue = ref("");
    const device = navigator.userAgent;
    const android =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (android) {
      isAndroid.value = false;
    }
    // const CascaderRef = ref();

    // 赏析类型id
    const typeID = ref(route.query.typeId);

    // 级联选择书本的完整路径
    const bookSelectFullValue = ref();
    // 级联选择模式配置项
    const cascaderProps = {
      checkStrictly: true,
      emitPath: false,
      expandTrigger: "hover",
    };
    let resourceData = ref({});

    // 获取检索表单的参数上下文工具
    const { getContextInfo, saveContextInfo, deleteContextInfo } =
      usePageTableContext();

    const handleGetPageContext = () => {
      const context = getContextInfo(pageTableContextMap.COURSEWARE_CENTER);
      logger.info(context);
      const {
        CoursewaresId: cId,
        typeValue: tValue,
        currentPage: cPage,
        searchName: sname,
        bookSelectFullValue: bsfValue,
      } = context;

      if (cId) {
        CoursewaresId.value = cId;
      }
      if (tValue) {
        typeValue.value = tValue;
      }
      if (sname) {
        searchName.value = sname;
      }
      if (bsfValue) {
        bookSelectFullValue.value = bsfValue;
      }
      if (cPage && cPage > 1) {
        currentPage.value = cPage;
      }

      deleteContextInfo(pageTableContextMap.COURSEWARE_CENTER);
    };
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.on("appreciationResourcesGoBack", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        console.log("activePanelActive.value", activePanelActive.value);
        // handleGoMusicKnowLedgeHome();
        if (activePanelActive.value) {
          console.log("activePanelActive.value1");
          activePanelActive.value = false;
        } else {
          console.log("activePanelActive.value2");
          router.push("/appreciateHome");
        }
      });
    }
    onBeforeMount(() => {
      isML.value = store.state.isML;
      const device = navigator.userAgent;
      const android =
        device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      if (android) {
        isAndroid.value = false;
      }
      // 初始化上次保存的检索参数
      handleGetPageContext();
    });
    onMounted(() => {
      logger.info("当前currentPage值是===>", currentPage.value);
      logger.info("当前totalPage值是===>", totalPage.value);
      // currentPage.value = 1;
      init();
      const param = {
        book_section_id: CoursewaresId.value || undefined,
        name: searchName.value || undefined,
        type: typeValue.value || undefined,
      };
      logger.info("param====>", param);
      getFamousMusicSongTypeInfo();
      getFamousMusicSongsData(param);
      firstLoading.value = false;
      // border();
      // getResourceInfo(param);
      getTeacherData();
    });
    onActivated(() => {
      logger.info("firstLoadPPT", store.state.firstLoadPPT);
      if (android) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
      if (firstLoad.value) {
        refreshItem.value++;
        searchName.value = "";
        currentPage.value = 1;
        CoursewaresId.value = "";
        getResourceInfo();
        mySwiper.value.activeIndex = 1;
      }
    });
    onBeforeRouteLeave((to, from, next) => {
      if (to.name === "Home") {
        firstLoad.value = true;
      }
      next();
    });
    onBeforeUnmount(() => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.removeAllListeners("appreciationResourcesGoBack");
    });
    // 获取赏析资源类型
    const getFamousMusicSongTypeInfo = () => {
      getFamousMusicSongType({
        per_page: 9999,
      }).then((data) => {
        console.log("data33", data.data.items);
        const treeData = getTreeData(parseInt(typeID.value), data.data.items);
        console.log("data3344", treeData);
        cascaderList.value = treeData;
        // 获取到赏析资源类型，并且提取出根节点
      });
    };
    // 构造菜单树数据
    const getTreeData = (pid, menus) =>
      menus
        .filter((item) => item.pid === pid)
        .map((item) => {
          if (menus.filter((menu) => menu.pid === item.id).length === 0) {
            return { label: item.name, value: item.id };
          }
          return {
            label: item.name,
            value: item.id,
            children: getTreeData(item.id, menus),
          };
        });

    const getTeacherData = () => {
      getTeacherInfo({}).then((data) => {
        logger.info("teacherName", data);
        teacherName.value = data.nick_name;
        store.commit(MutationNames.CHANGE_NICKNAME, data.nick_name);
      });
    };
    // 切换课件类型时重新请求课件列表
    const getResourceInfo = (params) => {
      mySwiper.value.touches.diff = 0;
      teachResourceEight.length = 0;
      middleTeachResourceEight.length = 0;
      lastTeachResourceEight.length = 0;
      const searchFunc = getCoursewares();
      getCoursewares({
        per_page: 12,
        page: 1,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        if (data.data.items.length === 0) {
          noData.value = false;
          noDataMsg.value = true;
        } else {
          noData.value = true;
        }
        teachResourceEight.length = 0;
        teachResourceEight.push(...data.data.items);
        logger.info("初始化拿数据data===>", teachResourceEight);
        // 选择类型或搜索之后判断边界值
        if (currentPage.value == 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if ((currentPage.value == totalPage.value) == 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        logger.info(
          "我在函数里判断完了",
          currentPage.value,
          totalPage.value,
          leftBorderFlag.value,
          rightBorderFlag.value
        );
      });
      getCoursewares({
        per_page: 12,
        page: 2,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        middleTeachResourceEight.length = 0;
        middleTeachResourceEight.push(...data.data.items);
        logger.info("初始化拿数据data===>", middleTeachResourceEight);
      });
      getCoursewares({
        per_page: 12,
        page: 3,
        book_show_modules: 1,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        lastTeachResourceEight.length = 0;
        lastTeachResourceEight.push(...data.data.items);
        logger.info("初始化拿数据data===>", lastTeachResourceEight);
      });
    };
    // 获取赏析资源列表
    const getFamousMusicSongsData = (params) => {
      logger.info("初始化拿数据函数里的当前页====>", currentPage.value);
      // const searchFunc = getCoursewares();
      getFamousMusicSongs({
        per_page: 12,
        page: currentPage.value,
        category_id: typeID.value,
        ...params,
      }).then((data) => {
        logger.info("data", data);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if (currentPage.value % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...data.data.items);
          mySwiper.value.activeIndex = 1;
        }
        if (currentPage.value % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...data.data.items);
          mySwiper.value.activeIndex = 2;
        }
        if (currentPage.value % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...data.data.items);
          mySwiper.value.activeIndex = 3;
        }
        // 判断结束
        logger.info(
          "这边初始化数据结束，开始判断边界值===>",
          currentPage.value,
          totalPage.value
        );
        if (currentPage.value === 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if (
          currentPage.value === totalPage.value &&
          totalPage.value === 1
        ) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else if (
          currentPage.value === totalPage.value &&
          totalPage.value !== 1
        ) {
          leftBorderFlag.value = true;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        if (data.data.items.length === 0) {
          noData.value = false;
          noDataMsg.value = true;
        } else {
          noData.value = true;
        }
        // teachResourceEight.push(...data.data.items);
      });
      getFamousMusicSongs({
        per_page: 12,
        page: currentPage.value + 1,
        category_id: typeID.value,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if ((currentPage.value + 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value + 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value + 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...data.data.items);
        }
        // 判断结束
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        // middleTeachResourceEight.push(...data.data.items);
      });
      getFamousMusicSongs({
        per_page: 12,
        page: currentPage.value - 1,
        category_id: typeID.value,
        ...params,
      }).then((data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if ((currentPage.value - 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value - 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...data.data.items);
        }
        if ((currentPage.value - 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...data.data.items);
        }
      });
    };
    // 数据埋点
    const handleDataReport = async (item) => {
      const eventattr = { id: item.id };
      logger.info("eventattr", eventattr);
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "appreciation_appreciationResources_open",
          pageid: "appreciationResources",
          moduleid: "appreciation",
          eventattr: eventattr,
        };
        logger.info("data", data);
        const body = encodeURIComponent(JSON.stringify(data));
        logger.info("body", body);
        await dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          async function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            logger.info("安卓调用完成", responseData);
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "appreciation_appreciationResources_open";
            data.pageid = "appreciationResources";
            data.moduleid = "appreciation";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            logger.info("body", body);
            await dataEmbeddingPoint(body);
          }
        );
      }
    };
    // 卡片点击事件
    const handleCardClick = async (data) => {
      console.log("aaaa", data);
      await handleDataReport(data);
      resourceData.value = data;
      // 消除下拉列表
      cascaderClose();
      // 显示详情
      activePanelActive.value = true;
    };

    const handleSearch = _.debounce(() => {
      logger.info("search", searchName.value);
      currentPage.value = 1;
      const params = {
        song_name: searchName.value,
      };
      getFamousMusicSongsData(params);
      mySwiper.value.activeIndex = 1;
    }, 500);
    const handleGoToLogin = () => {
      firstLoad.value = true;
      refreshItem.value = 0;
      CoursewaresId.value = "";
      if (android) {
        // window.WebViewJavascriptBridge.callHandler(
        //   "closeTeachMaterial", // native中注册时的key值
        //   null // native中注册方法中的传入的数据
        // );
        // 现在不在该页面向Android去发送隐藏的方法，改为到blank页面去发送
        router.replace({
          path: "/blank",
          query: {
            skinType: 1,
          },
        });
      } else {
        router.push({
          // path: "/home",
          path: "/login",
        });
      }
    };

    const cascaderClose = () => {
      document.querySelectorAll(".el-cascader__dropdown").forEach((item) => {
        if (item.style.display !== "none") {
          item.style.display = "none";
        }
      });
    };

    const handleChangeCoursewares = (value) => {
      console.log("查询", value);
      // console.log("查询", searchName.value);
      if (value === null) {
        bookSelectFullValue.value = [];
      }
      currentPage.value = 1;
      let params = {
        song_name: "",
      };
      if (searchName.value) {
        params.song_name = searchName.value;
      }
      if (value) {
        // const num = value.length - 1;
        // params.book_section_id = value[num];
        // typeID.value = value[num];
        params.book_section_id = value;
        typeID.value = value;
      } else {
        // CoursewaresId.value = "";
        typeID.value = route.query.typeId;
      }
      console.log("params", params);
      getFamousMusicSongsData(params);
      mySwiper.value.activeIndex = 1;
    };
    //  初始化滑屏
    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };
    const onSwiper = (swiper) => {
      logger.info("onSwiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", currentPage.value);
    };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      logger.info(
        "点击按钮切换到下一页函数里拿到的当前页数是===>",
        currentPage.value + 1
      );
      if (currentPage.value === totalPage.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      logger.info(
        "点击按钮切换到上一页函数里拿到的当前页数是===>",
        currentPage.value - 1
      );
      if (currentPage.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info(
          "activeIndex页面切换到上一页函数里拿到的当前页数是===>",
          currentPage.value - 1
        );
        logger.info("activeIndex===>", mySwiper.value.activeIndex);
        currentPage.value--;

        const params = {
          song_name: searchName.value,
        };
        if (currentPage.value !== 1) {
          getFamousMusicSongsData(currentPage.value - 1, params);
        }
      }
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info(
            "activeIndex1页面切换到下一页函数里拿到的当前页数是===>",
            currentPage.value + 1
          );
          logger.info("activeIndex===>", mySwiper.value.activeIndex);
          currentPage.value++;
          const params = {
            song_name: searchName.value,
          };
          if (currentPage.value !== totalPage.value) {
            getFamousMusicSongsData(currentPage.value + 1, params);
          }
        } else {
          logger.info("翻页失败");
        }
      }
    };

    // 隐藏详情面板
    const handleActivePanelHide = () => {
      activePanelActive.value = false;
    };
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          leftBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        logger.info("在这看触摸距离startX===>", mySwiper.value.touches.startX);
        logger.info(
          "在这看触摸距离currentX===>",
          mySwiper.value.touches.currentX
        );
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === totalPage.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );
    watch(
      () => currentPage.value,
      () => {
        logger.info("我监听了", leftBorderFlag.value, rightBorderFlag.value);
        logger.info("总页数", totalPage.value);
        if (currentPage.value === totalPage.value) {
          rightBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          rightBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      }
    );
    return {
      onSwiper,
      next,
      previous,
      firstLoading,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      onSlideChange,
      mySwiper,
      leftBorderFlag,
      rightBorderFlag,
      noDataMsg,
      middleTeachResourceEight,
      lastTeachResourceEight,
      teacherName,
      teachResourceEight,
      cascaderList,
      currentPage,
      totalPage,
      searchName,
      isAndroid,
      handleSearch,
      handleGoToLogin,
      handleCardClick,
      handleChangeCoursewares,
      // CascaderRef,
      refreshItem,
      activePanelActive,
      schoolName,
      noData,
      typeValue,
      bookSelectFullValue,
      isML,
      init,
      handleActivePanelHide,
      cascaderProps,
      resourceData,
      cascaderRef,
      cascaderClose,
    };
  },
});
