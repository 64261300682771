// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/login/zdy-login-btn.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".zdy-login[data-v-6d9d020b]{display:flex;align-items:center;justify-content:center;width:100%;height:100%}.zdy-login .zdy-login-btn[data-v-6d9d020b]{width:222px;height:222px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;cursor:pointer;box-shadow:0 0 20px rgba(0,0,0,.2)}.zdy-login .confirm-btn[data-v-6d9d020b]{display:flex;align-items:center;justify-content:center;width:100%;height:76px;padding-right:30px;cursor:pointer;color:#fff;border:0;border-radius:38px;background:linear-gradient(90deg,#ff6f57,#fd9078);box-shadow:0 5px 10px #ffccc4;font-size:30px}.zdy-login .no-allow-click[data-v-6d9d020b]{cursor:not-allowed}.zdy-login .arrow-right-icon[data-v-6d9d020b]{font-size:30px}", ""]);
// Exports
module.exports = exports;
