
import {
  Ref,
  computed,
  defineAsyncComponent,
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  inject,
  onActivated,
} from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import servers from "@/config/servers";
import config from "@/config/app.config";
import ScrollLoad from "@/components/ScrollLoad.vue";
import RemindEmptyResource from "@/components/remindEmptyResource.vue";
import CoursewareCard from "@/components/CoursewareCard.vue";
import moment from "moment";

import { useStore } from "vuex";
import useCache from "@/hooks/useCache";
import { SocketEventMap } from "@/utils/socket-event";
import emitter, { EmitterEvents } from "@/utils/eventEmitter";
import { noticeCacheErrorDownloadResult } from "@/utils/socketCommon";
import { ElMessage } from "element-plus";
import _ from "lodash";
import logger from "@evideo/logger";
import { dataEmbeddingPoint } from "@/services";

export default defineComponent({
  name: "my-courseware",
  components: {
    ScrollLoad,
    RemindEmptyResource,
    CoursewareCard,
    AsyncCourseWarePreview: defineAsyncComponent(
      () =>
        new Promise((resolve) => {
          // fixme: 这边因为预览组件有ppt应用加载，因此需要延迟500ms，方便页面元素渲染染，不会因为ppt应用的加载，导致页面卡顿
          setTimeout(() => {
            import("@/components/CourseWarePreview.vue").then((res: any) => {
              resolve(res);
            });
          }, 500);
        })
    ),
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const breadcrumbList: Ref<Array<{ id: string | number; name: string }>> =
      ref([
        {
          id: 0,
          name: "我的课件",
        },
      ]);

    const parent_id = ref(0);
    const courseWarePreviewRef = ref();
    const socket: any = inject("socket");
    const scrollLoad = ref<any>(null);

    const isAndroid = ref(false);
    // 判断是否是安卓设备
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

    const {
      canUseCache,
      canUseHightLocalResource,
      CacheStatus,
      addCacheFile,
      checkCacheFile,
    } = useCache();

    const scrollLoadProps = computed(() => {
      return {
        api: `${config[servers.resource]}/teacher/courseware-space-nodes`,
        matchData: "@data.items@",
        params: {
          parent_id: parent_id.value,
          courseware_types: ["ppt", "image", "video", "audio", "swf"],
        },
      };
    });

    const getCard = (item: any = {}) => {
      return {
        cardType: item.courseware?.type || "folder",
        cardUrl: item.courseware?.url || item.url,
        ...item,
        thumbnail_images: item?.courseware?.thumbnail_images,
      };
    };

    const onBreadcrumbClick = (breadcrumbItem: any) => {
      parent_id.value = breadcrumbItem.id;
      const index = breadcrumbList.value.findIndex(
        (item) => item.id === breadcrumbItem.id
      );
      breadcrumbList.value.splice(index + 1);
    };

    // 数据埋点
    const handleDataReport = (item: any) => {
      const eventattr = { id: item.id, owner_type: "school" };
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (!isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_teachmaterial_open",
          pageid: "teachmaterial",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        const body = encodeURIComponent(JSON.stringify(data));
        dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_teachmaterial_open";
            data.pageid = "teachmaterial";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            dataEmbeddingPoint(body);
          }
        );
      }
    };

    const onCardClick = (card: any) => {
      if (card.type === 0) {
        // 文件夹
        parent_id.value = card.id;
        breadcrumbList.value.push({
          id: card.id,
          name: card.name,
        });
        return;
      }
      const { cardType, cardUrl } = card;
      courseWarePreviewRef.value.showCourseWare(cardType, cardUrl);
      handleDataReport(card);
    };
    const handleScrollDataFetchEnd = async (fetchData: any) => {
      const { data } = fetchData || {};
      if (!data) {
        return;
      }
      if (!canUseCache?.value) {
        return;
      }
      const cacheParams: any[] = [];
      if (canUseHightLocalResource) {
        data.forEach((_data: any) => {
          if (_data.courseware) {
            const { id, updated_at, type } = _data.courseware || {};
            cacheParams.push({
              cloud_id: id,
              version_time: updated_at,
              type: type?.toUpperCase(),
              source: "school",
              mode: "myCourseware",
            });
          }
        });
      } else {
        data.forEach((_data: any) => {
          const { id, updated_at, md5, url } = _data.courseware || {};
          _data.courseware &&
            cacheParams.push({
              id: id,
              md5: md5,
              url: url,
              mode: "myCourseware",
              version_time: updated_at,
            });
        });
      }
      const cacheFiles = await checkCacheFile(
        canUseHightLocalResource ? { items: cacheParams } : cacheParams
      );
      const _scrollData = _.cloneDeep(scrollLoad.value.scrollData);
      cacheFiles &&
        cacheFiles.forEach((cacheData: any) => {
          const { id, type, cacheUrl, cacheStatus, cacheProgress, originUrl } =
            cacheData;
          let index = -1;
          if (canUseHightLocalResource) {
            index = _scrollData.findIndex(
              (data: any) =>
                data.courseware &&
                data.courseware.id === id &&
                data.courseware.type?.toUpperCase() === type
            );
          } else {
            index = _scrollData.findIndex(
              (data: any) =>
                data.courseware &&
                data.courseware.id === id &&
                data.courseware.url === originUrl
            );
          }
          if (index >= 0) {
            _scrollData[index].cacheStatus = cacheStatus;
            _scrollData[index].cacheProgress = cacheProgress || 0;
            cacheUrl &&
              cacheStatus === CacheStatus.SUCCESS &&
              (_scrollData[index].courseware.url = cacheUrl);
          }
        });
      scrollLoad.value.scrollData.length = 0;
      scrollLoad.value.scrollData.push(..._scrollData);
    };

    const handleCache = _.debounce((item: any) => {
      const { id, type, url, updated_at, name, md5 } = item?.courseware || {};
      scrollLoad.value.scrollData.map((_data: any) => {
        if (!_data?.courseware) {
          return _data;
        }
        if (_data.courseware.id === id && _data.courseware.type === type) {
          _data.cacheStatus = CacheStatus.DOWNLOADING;
          _data.cacheProgress = undefined;
          return _data;
        }
        return _data;
      });

      if (canUseHightLocalResource) {
        addCacheFile({
          cloud_id: id,
          type: type,
          url: url,
          mode: "myCourseware",
          name: type === "ppt" ? name + ".json" : name,
          version_time: updated_at,
          source: "school",
        });
      } else {
        addCacheFile({
          id: id,
          url: url,
          mode: "myCourseware",
          name: type === "ppt" ? name + ".json" : name,
          version_time: updated_at,
          md5: md5,
        });
      }
    }, 100);

    const downloadStatus = (res: any) => {
      console.log("#message downloadStatus myCourseware", res.data);
      const { file_url, mode, type, status, cloud_id, cloud_url } =
        res?.data?.details?.[0] || {};
      if (mode !== "myCourseware") {
        return;
      }
      if (status === 0) {
        scrollLoad.value.scrollData.map((data: any) => {
          if (!data?.courseware) {
            return data;
          }
          if (
            canUseHightLocalResource &&
            data.courseware.type.toUpperCase() === type &&
            data.courseware.id === cloud_id
          ) {
            data.cacheProgress = 100;
            data.cacheStatus = CacheStatus.SUCCESS;
            data.courseware.url = file_url;
            return data;
          }
          // 低版本匹配
          if (
            !canUseHightLocalResource &&
            data.courseware.url === decodeURI(cloud_url)
          ) {
            data.cacheStatus = CacheStatus.SUCCESS;
            data.courseware.url = file_url;
            return data;
          }
          return data;
        });
      }
    };

    const downloadProgress = (res: any) => {
      const { cloud_id, type, mode, complete_file_num, total_file_num } =
        res?.data || {};
      if (mode !== "myCourseware") {
        return;
      }
      scrollLoad.value.scrollData.map((data: any) => {
        if (!data?.courseware) {
          return data;
        }
        if (
          data.courseware.id === cloud_id &&
          data.courseware.type.toUpperCase() === type
        ) {
          data.cacheProgress = total_file_num
            ? Math.floor((complete_file_num / total_file_num) * 100)
            : 0;
          data.cacheStatus = CacheStatus.DOWNLOADING;
          return data;
        }
        return data;
      });
    };
    const cacheResourceError = (resource: any) => {
      const { cloud_id, type, mode, id, md5 } = resource || {};
      if (mode !== "myCourseware") {
        return;
      }
      scrollLoad.value.scrollData.map((data: any) => {
        if (!data?.courseware) {
          return data;
        }
        if (
          canUseHightLocalResource &&
          data.courseware.id === cloud_id &&
          data.courseware.type === type
        ) {
          data.cacheStatus = CacheStatus.NONE;
          data.cacheProgress = 0;
          return data;
        }
        if (
          !canUseHightLocalResource &&
          data.courseware.id === id &&
          data.courseware.md5 === md5
        ) {
          data.cacheStatus = CacheStatus.NONE;
          return data;
        }
        return data;
      });
      ElMessage.error({
        offset: 200,
        message: "资源下载失败",
        type: "error",
      });
    };

    const cacheDownloadFull = (resource: any) => {
      const { cloud_id, type, mode, id, md5 } = resource || {};
      if (mode !== "myCourseware") {
        return;
      }

      scrollLoad.value.scrollData.map((data: any) => {
        if (!data?.courseware) {
          return data;
        }
        if (
          canUseHightLocalResource &&
          data.courseware.id === cloud_id &&
          data.courseware.type === type
        ) {
          data.cacheStatus = CacheStatus.NONE;
          data.cacheProgress = 0;
          return data;
        }
        if (
          !canUseHightLocalResource &&
          data.courseware.id === id &&
          data.courseware.md5 === md5
        ) {
          data.cacheStatus = CacheStatus.NONE;
          return data;
        }
        return data;
      });

      ElMessage.warning({
        offset: 200,
        message: "当前下载繁忙，请稍后",
        type: "warning",
      });
    };
    const cacheDownloadResourceSuccess = (resource: any) => {
      const { cloud_id, type, mode } = resource || {};
      if (mode !== "myCourseware") {
        return;
      }

      scrollLoad.value.scrollData.map((data: any) => {
        if (!data?.courseware) {
          return data;
        }
        if (
          canUseHightLocalResource &&
          data.courseware.id === cloud_id &&
          data.courseware.type === type
        ) {
          data.cacheProgress = 0;
          return data;
        }
        return data;
      });
    };

    // fixme: 兼容1.5.0之前版本的ipc通信，接收参数，返回首页
    const addListenerIpcGoBack = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.on("myCoursewareGoBack", () => {
          router.push("/home");
        });
      }
    };
    const removeListenersIpcGoBack = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.removeAllListeners("myCoursewareGoBack");
      }
    };

    onBeforeUnmount(() => {
      removeListenersIpcGoBack();
    });

    onBeforeRouteLeave((to, from, next) => {
      const { query } = to;
      if (query && query?.home) {
        courseWarePreviewRef.value.reset();
        next(true);
        return;
      }
      if (to?.name === "Login") {
        next(true);
        return;
      }
      if (courseWarePreviewRef.value && courseWarePreviewRef.value.visible) {
        courseWarePreviewRef.value.reset();
        next(false);
        return;
      }
      next(true);
    });
    onActivated(() => {
      if (isAndroid.value) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
    });
    onMounted(() => {
      emitter.on(EmitterEvents.ON_CACHE_FULL, cacheDownloadFull);
      emitter.on(EmitterEvents.ON_CACHE_ERROR, cacheResourceError);
      emitter.on(
        EmitterEvents.ON_CACHE_DOWNLOAD_SUCCESS,
        cacheDownloadResourceSuccess
      );
      socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
      socket.on(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
      addListenerIpcGoBack();
    });
    onBeforeUnmount(() => {
      emitter.off(EmitterEvents.ON_CACHE_ERROR, cacheResourceError);
      emitter.off(EmitterEvents.ON_CACHE_FULL, cacheDownloadFull);
      emitter.off(
        EmitterEvents.ON_CACHE_DOWNLOAD_SUCCESS,
        cacheDownloadResourceSuccess
      );
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
      socket.off(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
    });
    return {
      scrollLoad,
      canUseCache,
      canUseHightLocalResource,
      CacheStatus,
      breadcrumbList,
      courseWarePreviewRef,
      scrollLoadProps,
      onBreadcrumbClick,
      onCardClick,
      getCard,
      handleCache,
      handleScrollDataFetchEnd,
    };
  },
});
