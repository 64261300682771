
interface SelectedWork {
  id: number;
  name?: string;
}
import _ from "lodash";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";

import { getSchoolClassName } from "@/services";
import {
  deleteFastClassWork,
  finishFastClassWork,
  getSchoolSubjects,
  getCurrentActiveSemester,
  getSemesterList,
  getFastClassWorkDetail,
  editFastClassWork,
} from "@/services/homework";
import ScrollLoad from "@/components/ScrollLoad.vue";
import Confirm from "./component/confirm.vue";
import HomeworkForm from "./component/homeworkForm.vue";
import ResultReportModal from "./component/resultReportModal.vue";
import servers from "@/config/servers";
import config from "@/config/app.config";
import { initStatConfig, reportStat, FastClassWorkEvent } from "./statReport";
import {
  SelectItems,
  FastClassWorkStatus,
  GradeName,
  FormType,
  FastClassWorkStatusMap,
} from "./dataMap";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "FastClassWork",
  components: {
    ScrollLoad,
    HomeworkForm,
    Confirm,
    ResultReportModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const scrollLoad = ref<any>(null);
    const homeworkFormRef = ref<any>(null);
    const lessonStart = ref(store.state.lessonStart);
    let isShowTooltip = ref();
    const selectedState = ref(-1);
    const selectedClassGrade = ref();

    const reportModalVisible = ref(false);
    const reportFastClassWorkId = ref(-1);
    const reportFastClassWorkName = ref("");

    let selectedSubject = ref(
      JSON.parse(localStorage.getItem("subjectDataSelected") || "0").subject
    );
    const formType = ref<string>(FormType.EDIT);
    const subjectData = reactive(store.state.subjectData);
    const homeworkState = ["未开始", "进行中", "截止提交", "完成评阅"];
    let idSelectToDelete = ref();
    let idSelectToComplateReview = ref();

    let isShowEdit = ref(false);
    const deleteConfirmVisible = ref(false);
    const completeConfirmVisible = ref(false);
    const selectedWork = ref<SelectedWork | undefined>();

    const selectedGrade = ref(-1);
    const selectedClass = ref<any>();
    const classPanelVisible = ref(false);
    const classItems = computed(() => {
      if (selectedGrade.value < 0) {
        return [];
      }
      const currentGrade = gradeClass.value.find(
        (_gradeClass: any) => _gradeClass.grade === selectedGrade.value
      );
      return currentGrade?.class;
    });

    const handleOpenClassPanel = () => {
      classPanelVisible.value = !classPanelVisible.value;
    };
    const handleChangeSelectedGrade = (grade: number) => {
      selectedGrade.value = grade;
    };
    const handleChangeSelectedClass = (classItem: any) => {
      selectedClass.value = classItem;
      classPanelVisible.value = false;
    };
    const showHomeworkInfo = computed(() => {
      const works: any[] = [];
      if (scrollLoad.value && scrollLoad.value?.scrollData) {
        scrollLoad.value.scrollData.forEach((data: any) => {
          if (data) {
            const submitNum = data.records.filter(
              (record: any) => record.status !== 0
            ).length;

            const endDate = moment(data.finished_submit_at).format(
              "YYYY-MM-DD"
            );
            works.push({
              ...data,
              subject: subjectData.find(
                (sub: any) => sub.subject === selectedSubject.value
              )?.subject_name,
              startTime: formatTime(data.started_at),
              finishedSubmitTime: formatTime(data.finished_submit_at),
              submitNum: submitNum,
              gradeClassName: selectedClass.value
                ? GradeName[selectedClass.value.grade - 1] +
                  selectedClass.value.class_name
                : "",
              totalNum: data.records.length,
              canDelete: true,
            });
          }
        });
      }
      return works;
    });

    // 滚动数据接口配置
    const homeworkListProps = ref<any>({
      api: `${config[servers.exam]}/teacher/fast-classwork/list`,
      matchData: "@data.items@",
      params: {
        subject: selectedSubject.value,
      },
      beforeRequest: (params: any) => {
        if (!selectedClass.value?.school_class_id) {
          return;
        }
        return params;
      },
    });

    // 表单参数
    const formProps = computed(() => {
      return {
        type: formType.value,
        subject: selectedSubject.value,
        gradeClass: gradeClass.value,
        id: selectedWork.value?.id,
        schoolSubject,
      };
    });

    // 删除滑动数据中保存的作业
    const deleteScrollData = (id: number) => {
      if (scrollLoad.value && scrollLoad.value.scrollData.length > 0) {
        const data = scrollLoad.value.scrollData.filter((item: any) => {
          return item.id !== id;
        });
        scrollLoad.value.scrollData.length = 0;
        scrollLoad.value.scrollData.push(...data);
      } else {
        return;
      }
    };

    // 编辑滑动数据中的作业
    const editScrollData = (id: number, modifiedData?: any) => {
      const data = scrollLoad.value.scrollData.map((item: any) => {
        if (item.id === id) {
          const { started_at, finished_submit_at, title, status } =
            modifiedData || {};
          status && (item.status = status);

          if (!started_at || !finished_submit_at) {
            return item;
          }
          // 作业更新的数据
          // 列表中应该只有开始时间、结束时间会变化；状态可能发生变化
          item.title = title;
          item.started_at = started_at;
          item.finished_submit_at = finished_submit_at;
          if (new Date().getTime() < new Date(started_at).getTime()) {
            item.status = 0;
          } else if (
            new Date().getTime() > new Date(finished_submit_at).getTime()
          ) {
            item.status = 2;
          } else if (
            new Date().getTime() >= new Date(started_at).getTime() &&
            new Date().getTime() <= new Date(finished_submit_at).getTime()
          ) {
            item.status = 1;
          }
          return item;
        } else {
          return item;
        }
      });
      scrollLoad.value.scrollData.length = 0;
      scrollLoad.value.scrollData.push(...data);
    };

    // 改变班级、状态时，触发滚动数据重新获取
    const onChangeSelected = (type: string, value: any) => {
      if (!scrollLoad.value) {
        return;
      }
      // scrollLoad.value.clearScrollData();
      switch (type) {
        case SelectItems.CLASS: {
          _.merge(homeworkListProps.value.params, {
            school_class_id: value,
          });
          break;
        }
        case SelectItems.STATUS: {
          const _params = _.cloneDeep(homeworkListProps.value.params);
          const { status, ...newHomeworkListProps } = _params || {};
          if (value === -1) {
            homeworkListProps.value.params = newHomeworkListProps;
          } else {
            homeworkListProps.value.params = {
              ...newHomeworkListProps,
              status: value,
            };
          }
          break;
        }
        case SelectItems.SUBJECT: {
          _.merge(homeworkListProps.value.params, {
            subject: value,
          });
          break;
        }
        default:
          break;
      }
    };

    // 进入评阅页面
    const goToHomeworkReview = (item: any) => {
      if (!item) {
        return;
      }

      const { title, id, status } = item;
      const className =
        GradeName[selectedClass.value.grade - 1] +
        selectedClass.value.class_name;
      const saveCurrentSelected = {
        classId: selectedClass.value.school_class_id,
        state: selectedState.value,
        subject: selectedSubject.value,
      };
      localStorage.setItem(
        "fastClassWork",
        JSON.stringify(saveCurrentSelected)
      );

      const assessNoAllowed = 0;
      // 截止提交、完成评阅、作业关联曲目
      const uploadNoAllowed =
        item.status >= FastClassWorkStatusMap.END_SUBMIT ||
        item.status === FastClassWorkStatusMap.NO_SUBMIT
          ? 1
          : 0;
      router.push({
        path: "/fastClassWork/review",
        query: {
          classId: selectedClass.value.school_class_id,
          className,
          id,
          name: title,
          status,
          subject: selectedSubject.value,
          _uploadNoAllowed: uploadNoAllowed,
          _assessNoAllowed: assessNoAllowed,
          teacherScoreRata:
            item?.config?.teacher_assess_result_level?.score_rate || 1,
        },
      });
    };
    const formatTime = (time: string) => {
      if (!time) {
        return;
      }
      const overDay = time.split(" ")[0];
      const inDay = time.split(" ")[1];
      const month =
        (overDay.split("-")[1].slice(0, 1) === "0"
          ? overDay.split("-")[1].slice(1, 2)
          : overDay.split("-")[1]) + "月";
      const day = overDay.split("-")[2] + "日";
      const specificTime = inDay.split(":");
      return month + day + " " + specificTime[0] + ":" + specificTime[1];
    };
    watch(
      () => selectedState.value,
      () => {
        onChangeSelected(SelectItems.STATUS, selectedState.value);
      }
    );

    watch(
      () => selectedClass.value?.school_class_id,
      () => {
        if (selectedClass.value?.school_class_id) {
          onChangeSelected(
            SelectItems.CLASS,
            selectedClass.value?.school_class_id
          );
        }
      }
    );

    watch(
      () => selectedSubject.value,
      () => {
        onChangeSelected(SelectItems.SUBJECT, selectedSubject.value);
      }
    );

    const gradeClass = ref<any>([]);
    // 获取老师任课年级班级数据
    const initFastClassWorkSelected = () => {
      let classId = !lessonStart.value ? store.state.className : 0;

      const currentSelectedStr = localStorage.getItem("fastClassWork");
      if (currentSelectedStr) {
        const currentSelected = JSON.parse(currentSelectedStr);
        currentSelected?.classId && (classId = currentSelected?.classId);
        currentSelected?.state >= 0 &&
          (selectedState.value = currentSelected.state);
        currentSelected?.subject &&
          (selectedSubject.value = currentSelected.subject);
      }

      return new Promise((resolve, reject) => {
        getSchoolClassName({ type: 0 })
          .then((data: any) => {
            gradeClass.value.length = 0;
            data && gradeClass.value.push(...data);

            data.forEach((e: any) => {
              e.class.forEach((item: any) => {
                if (classId && item.school_class_id === classId) {
                  selectedClass.value = item;
                }
              });
            });

            if (!classId) {
              selectedClass.value = data?.[0]?.class?.[0];
            }
            selectedGrade.value = +selectedClass.value.grade;

            resolve("");
          })
          .catch((e: any) => {
            reject(`获取学校年级班级信息失败：${e}`);
          });
      });
    };

    const schoolSubject: any[] = []; // 存储学校学科
    const getSubjectCompetencyNorms = () => {
      return new Promise((resolve, reject) => {
        getSchoolSubjects()
          .then((res: any) => {
            const { subject } = res.data || {};
            if (subject) {
              schoolSubject.push(...subject);
            }
          })
          .catch((err) => {
            console.error("获取学校学科失败", err);
          });
      });
    };

    const currentActiveSemesterTime = {
      // 当前学期时间
      startDate: "",
      endDate: "",
      lastEndDate: "",
      currentActive: true,
    };

    // 删除的二次确认
    const confrimDeleteHomework = (id: number) => {
      deleteConfirmVisible.value = true;
      idSelectToDelete.value = id;
    };
    // 完成评阅的二次确认
    const confrimComplateReview = (id: number) => {
      completeConfirmVisible.value = true;
      // 记录完成评阅的id
      idSelectToComplateReview.value = id;
    };

    const passConfirmDelete = _.debounce(async () => {
      const selectItem = scrollLoad.value.scrollData.find(
        (item: any) => item.id === idSelectToDelete.value
      );
      try {
        await deleteFastClassWork(idSelectToDelete.value);
        deleteConfirmVisible.value = false;
        deleteScrollData(idSelectToDelete.value);
        ElMessage.success({
          type: "success",
          message: "删除作业成功",
          offset: 200,
        });

        // 删除作业数据埋点
        reportStat(FastClassWorkEvent.FASTCLASSWORK_DELETE, {
          isSong:
            selectItem.config?.music_teaching_system_config?.song_list.length >
            0,
          class_id: selectItem.class.id,
          fastClassWork_id: selectItem.id,
          subject: selectItem.subject,
        });
      } catch (e) {
        console.error("删除作业失败", e);
        ElMessage.error({
          type: "error",
          message: "删除作业失败",
          offset: 200,
        });
      }
    }, 200);

    // 二次确认通过，开始执行逻辑
    const passConfirmFinish = _.debounce(async () => {
      try {
        await finishFastClassWork({ id: idSelectToComplateReview.value });
        completeConfirmVisible.value = false;
        editScrollData(idSelectToComplateReview.value, { status: 3 });
        ElMessage.success({
          type: "success",
          message: "完成评阅作业成功",
          offset: 200,
        });
        // 处理 完成评阅 埋点
        const selectItem = scrollLoad.value.scrollData?.find(
          (item: any) => item.id === idSelectToComplateReview.value
        );
        reportStat(FastClassWorkEvent.FASTCLASSWORK_FINISH_MARK, {
          isSong:
            selectItem.config?.music_teaching_system_config?.song_list.length >
            0,
          class_id: selectItem.class.id,
          fastClassWork_id: selectItem.id,
          subject: selectItem.subject,
        });
      } catch (e) {
        console.error("完成评阅作业失败", e);
      }
    }, 200);

    const openEditHomework = (type: string, id?: number) => {
      formType.value = type;
      isShowEdit.value = true;
      id &&
        (selectedWork.value = {
          id,
        });
    };

    // 显示查看作业报告弹窗
    const showFastClassWorkReport = _.debounce((id: number, name: string) => {
      reportModalVisible.value = true;
      reportFastClassWorkId.value = id;
      reportFastClassWorkName.value = name;
    }, 100);

    // 截止提交作业
    const endTimeFastClassWorkNow = _.debounce(
      (id: number) => {
        return new Promise((resolve, reject) => {
          getFastClassWorkDetail(id)
            .then((res: any) => {
              const {
                finished_submit_at,
                attachments,
                config,
                norms,
                ...details
              } = res?.data || {};
              const nowEndTime = moment(new Date()).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              editFastClassWork(id, {
                ...details,
                attachments: JSON.stringify(attachments),
                config: JSON.stringify(config),
                norms: JSON.stringify(norms),
                finished_submit_at: nowEndTime,
              }).then(() => {
                editScrollData(id, {
                  title: details.title,
                  started_at: details.started_at,
                  finished_submit_at: nowEndTime,
                });
                resolve("");
              });
            })
            .catch((err: any) => reject(err));
        });
      },
      200,
      { leading: true, trailing: false }
    );

    const reportFastClassWorkNow = _.debounce(
      (id: number) => {
        return new Promise((resolve, reject) => {
          getFastClassWorkDetail(id)
            .then((res: any) => {
              const { started_at, attachments, config, norms, ...details } =
                res?.data || {};
              const nowStartTime = moment(new Date()).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              editFastClassWork(id, {
                ...details,
                attachments: JSON.stringify(attachments),
                config: JSON.stringify(config),
                norms: JSON.stringify(norms),
                started_at: nowStartTime,
              }).then((res) => {
                console.log(res);

                editScrollData(id, {
                  title: details.title,
                  started_at: nowStartTime,
                  finished_submit_at: details.finished_submit_at,
                });
                resolve("");
              });
            })
            .catch((err: any) => reject(err));
        });
      },
      100,
      { leading: true, trailing: false }
    );
    // 收到编辑作业组件的消息，关闭编辑
    const closeEdit = (type: string, modifiedData: any) => {
      isShowEdit.value = false;
      if (type === FormType.CREATE) {
        scrollLoad.value && scrollLoad.value.fetchScrollData();
      } else {
        if (selectedWork.value) {
          editScrollData(selectedWork.value.id, modifiedData);
        }
      }
    };
    // 关于tooltip功能,即鼠标放在作业名上显示全名
    const onMouseOver = (id: number) => {
      const spanDom: Element | null = document.querySelector(
        `.hwName span[flag='${id}']`
      );
      let parentWidth = (spanDom?.parentNode as HTMLDivElement).offsetWidth;
      let contentWidth = (spanDom as HTMLDivElement).offsetWidth;
      // 判断是否禁用tooltip功能
      isShowTooltip.value = contentWidth <= parentWidth;
    };

    const handleClose = () => {
      classPanelVisible.value = false;
    };

    // 重新加载当前作业是否可删除
    const reloadCanDeleteWorks = () => {
      scrollLoad.value?.scrollData &&
        scrollLoad.value?.scrollData.map((data: any) => {
          // const startDate = moment(data.started_at).format("YYYY-MM-DD");
          data.canDelete = true;
          return data;
        });
    };

    const initCurrentSemester = () => {
      return new Promise((resolve, reject) => {
        getCurrentActiveSemester().then((currentRes: any) => {
          const { start_date, end_date, current_active } =
            currentRes.data || {};
          console.log("当前学期为:", current_active, start_date, end_date);
          if (current_active) {
            getSemesterList({}).then((res: any) => {
              const { total } = res.data.meta;
              getSemesterList({ per_page: total }).then((_res: any) => {
                const list = res.data.items;
                for (let i = 0; i < list.length; i++) {
                  if (new Date(list[i].end_date) <= new Date(start_date)) {
                    Object.assign(currentActiveSemesterTime, {
                      startDate: start_date,
                      endDate: end_date,
                      lastEndDate: list[i].end_date,
                      currentActive: true,
                    });
                    break;
                  }
                }
                reloadCanDeleteWorks();
                console.log(
                  "上个学期结束时间：",
                  currentActiveSemesterTime.lastEndDate
                );
                resolve("");
              });
            });
          } else {
            Object.assign(currentActiveSemesterTime, {
              startDate: start_date,
              endDate: end_date,
              lastEndDate: end_date,
              currentActive: false,
            });
            reloadCanDeleteWorks();
            resolve("");
          }
        });
      });
    };

    onMounted(async () => {
      try {
        await initFastClassWorkSelected();
        initStatConfig(store);
        // 获取当前学期时间
        // initCurrentSemester();

        getSubjectCompetencyNorms();
      } catch (e) {
        console.error(e);
      }
    });
    return {
      FormType,
      formProps,
      scrollLoad,
      isShowEdit,
      lessonStart,
      subjectData,
      isShowTooltip,
      homeworkState,
      deleteConfirmVisible,
      selectedState,
      selectedGrade,
      selectedClass,
      handleClose,
      classPanelVisible,
      handleOpenClassPanel,
      handleChangeSelectedGrade,
      handleChangeSelectedClass,
      reportFastClassWorkNow,
      endTimeFastClassWorkNow,
      showFastClassWorkReport,
      reportModalVisible,
      reportFastClassWorkId,
      reportFastClassWorkName,
      classItems,
      GradeName,
      gradeClass,
      homeworkFormRef,
      selectedSubject,
      showHomeworkInfo,
      homeworkListProps,
      selectedClassGrade,
      FastClassWorkStatus,
      completeConfirmVisible,
      FastClassWorkStatusMap,
      closeEdit,
      onMouseOver,
      passConfirmFinish,
      passConfirmDelete,
      openEditHomework,
      goToHomeworkReview,
      confrimComplateReview,
      confrimDeleteHomework,
    };
  },
});
