import servers from "./servers";

export default {
  [servers.user]: "https://testuser-api.jilingshu.com.cn",
  [servers.device]: "https://testapi.jilingshu.com.cn",
  [servers.platform]: "https://testplatform-api.jilingshu.com.cn",
  [servers.pptist]: "https://testppt.jilingshu.com.cn", // "http://localhost:8090/", //
  [servers.ecoach]: "https://testmusic-score.jilingshu.com.cn",
  [servers.resource]: "https://testresource-center.jilingshu.com.cn",
  [servers.ec]: "https://testec.jilingshu.com.cn/stat",
  [servers.qrcode]: "https://testgateway.jilingshu.com.cn/api/common-service/",
  [servers.kefuQrcode]:
    "https://testgateway.jilingshu.com.cn/api/common-service/",
  [servers.authorization]: "https://testapi.jilingshu.com.cn",
  [servers.musicGame]: "https://testinteractive-teach.jilingshu.com.cn",
  [servers.common]: "https://testgateway.jilingshu.com.cn/api/common-service/",
  [servers.tools]: "https://testh5-tools.jilingshu.com.cn",
  [servers.exam]: "https://testexam-center-service.jilingshu.com.cn",
  [servers.localResource]: "http://127.0.0.1",
  [servers.dingtalkCallback]:
    "https://testmulti-platform.jilingshu.com.cn/login",
  [servers.school]: "https://testschool.jilingshu.com.cn",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://testcloud-pre-render-service.jilingshu.com.cn",
  [servers.app]: "https://testmulti-platform.jilingshu.com.cn",
  [servers.api]: "https://testapi.jilingshu.com.cn/",
};
