enum CoursewareType {
  Folder = "folder",
  Ppt = "ppt",
  Image = "image",
  Audio = "audio",
  Video = "video",
  Flash = "swf",
  Speak = "speak",
  Plan = "plan",
  SingPlay = "sheet_song",
}

const CoursewareTypeDesc = {
  [CoursewareType.Folder]: "文件夹",
  [CoursewareType.Ppt]: "PPT",
  [CoursewareType.Image]: "图片",
  [CoursewareType.Audio]: "音频",
  [CoursewareType.Video]: "视频",
  [CoursewareType.Flash]: "Flash",
  [CoursewareType.Speak]: "说课",
  [CoursewareType.Plan]: "教案",
  [CoursewareType.SingPlay]: "唱歌",
};

export { CoursewareType, CoursewareTypeDesc };
