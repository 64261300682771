
import BScroll from "@better-scroll/core";
import ParaButton from "./ParaButton.vue";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import logger from "@evideo/logger";

export default defineComponent({
  name: "playback-controls",
  components: {
    ParaButton,
  },
  props: {
    elementInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    const meta = ref(props.elementInfo);
    console.log("meta", meta.value);

    if (meta.value.id === undefined) {
      return {};
    }
    const audioRef = ref<HTMLAudioElement>();
    const isPlay = ref(false);
    // 曲目切换控制
    const currentSongIndex = ref(0);
    const currentSong = computed(
      () => meta.value.resources[currentSongIndex.value]
    );
    watch(currentSongIndex, () => {
      console.log("currentSong.value", currentSong.value);
      // 当重新选择曲目后重置scroll（判断是否需要滑动） 并且将位置初始化0
      setTimeout(() => {
        scroll.value.refresh();
        scroll.value.scrollTo(0);
      }, 0);

      const player = audioRef.value;
      if (player) {
        console.log("sdsd", currentSong.value);

        player.src = currentSong.value.file_url;
        isPlay.value = false;
      }
    });

    // 当前歌曲播控
    const curTimeRef = ref(0);
    const duration = ref(100);
    const onSetTime = (e: any) => {
      const player = audioRef.value;
      if (player) {
        player.currentTime = parseInt(e.target.value);
      }
    };

    const onPlay = () => {
      const player = audioRef.value;
      if (player) {
        if (player.paused) {
          player.play();
          isPlay.value = true;
        } else {
          player.pause();
          isPlay.value = false;
        }
      }
    };
    // 鼠标松开进度条开始播放
    const mouseMovePlay = () => {
      const player = audioRef.value;
      if (player) {
        player.play();
        isPlay.value = true;
      }
    };
    // 鼠标按住进度条暂停播放
    const mouseMovePause = () => {
      const player = audioRef.value;
      if (player) {
        player.pause();
        isPlay.value = false;
      }
    };

    const onPause = () => {
      const player = audioRef.value;
      if (player) {
        player.pause();
        isPlay.value = false;
        logger.info(`【调试日志】欣赏歌曲暂停`);
      }
    };

    const stopPlay = () => {
      const player = audioRef.value;
      player && player.pause();
      isPlay.value = false;
      player && (player.currentTime = 0);
      curTimeRef.value = 0;
    };

    const scroll = ref<any>();
    const scrollEl = ref<HTMLDivElement>();

    onMounted(() => {
      scroll.value = new BScroll(scrollEl.value!, {
        scrollX: true,
        click: true,
        disableMouse: false,
        disableTouch: false,
        observeDOM: true, // 修复移动设备无法拖拽
      });
      const player = audioRef.value;
      console.log("wewe1", currentSong.value);
      if (player) {
        console.log("wewe", currentSong.value);

        player.src = currentSong.value.file_url;

        player.addEventListener("durationchange", () => {
          duration.value = player.duration;
        });

        player.addEventListener("timeupdate", () => {
          curTimeRef.value = player.currentTime;
          updateCurParaBtnIndexByTime(player.currentTime);
        });

        player.addEventListener("ended", () => {
          isPlay.value = false;
          curTimeRef.value = 0;
          curParaBtnIndex.value = 0;
        });
      }
    });

    onBeforeUnmount(() => {
      const player = audioRef.value;
      player?.pause();
      scroll.value && scroll.value.destroy();
    });
    const getTextWidth = (str = "", style = {}, offset = 0) => {
      const dom = document.createElement("span");
      Object.entries(style).forEach(([key, value]) => {
        (dom as any).style[key] = value;
      });
      dom.textContent = str;
      document.body.appendChild(dom);
      const width = dom.clientWidth;
      console.log("width", width);

      document.body.removeChild(dom);
      return `${width + offset}px`;
    };

    // 分段按钮
    const curParaBtnIndex = ref(0);
    const para_info = computed(() => {
      const para_info = currentSong.value.para_info || [];
      return para_info.map((item: any) => {
        return {
          ...item,
          width: getTextWidth(
            item.name,
            {
              fontSize: "22px",
              display: "inline-block",
              margin: "3px 15px",
              padding: "0 10px",
            },
            10
          ),
          el: ref(),
        };
      });
    });
    watch(
      () => curParaBtnIndex.value,
      (newVal) => {
        const para = para_info.value[newVal];
        if (!para) {
          return;
        }
        scroll.value &&
          scroll.value.scrollToElement(para.el.value.$el, 300, true);
      }
    );
    const onParaClicked = (index: number): void => {
      curParaBtnIndex.value = index;
      const para = para_info.value[index]!;
      const player = audioRef.value;
      if (player) {
        player.currentTime = para.start_time;
        !isPlay.value && player.play() && (isPlay.value = true);
      }
    };
    const updateCurParaBtnIndexByTime = (time: number) => {
      if (para_info.value.length === 0) {
        return;
      }
      const reversed = para_info.value.slice().reverse();
      const index = reversed.findIndex((para: any) => time >= para.start_time);
      curParaBtnIndex.value = reversed.length - 1 - index;
    };

    return {
      meta,
      audioRef,
      isPlay,
      onPlay,
      mouseMovePlay,
      mouseMovePause,
      onSetTime,
      currentSongIndex,
      curTimeRef,
      duration,
      scrollEl,
      para_info,
      curParaBtnIndex,
      onParaClicked,
    };
  },
});
