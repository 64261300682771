import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ba638cb0")
const _hoisted_1 = { class: "courseware-card" }
const _hoisted_2 = {
  key: 0,
  class: "card-progress"
}
const _hoisted_3 = {
  key: 1,
  class: "card-tag-teaching"
}
const _hoisted_4 = { class: "card-name" }
const _hoisted_5 = { class: "card-name_text" }
const _hoisted_6 = { class: "card-bottom" }
_popScopeId()

import { PropType, computed, onMounted, ref, watch } from "vue";
import { CoursewareType, CoursewareTypeDesc } from "@/utils/coursewareType";

interface ICoursewareCard extends Record<string, any> {
  cardType: CoursewareType;
  cardUrl: string;
  cardIsPrimary: number; // 0 - ppt; 1 - 教材
  thumbnail_images: string[]; // ppt缩略图
}

export default _defineComponent({
  props: {
  card: {
    type: Object as PropType<ICoursewareCard>,
    required: true,
  },
},
  emits: ["click"],
  setup(__props, { emit: emits }) {

const props = __props


const thumbnailImages = computed(() => {
  return props.card.thumbnail_images || [];
});

const coursewareTypeDesc = computed(() => {
  return CoursewareTypeDesc[props.card.cardType];
});
const cardTagClassName = computed(() => {
  return `card-tag_${props.card.cardType}`;
});
const backgroundImageUrl = ref("");

const cardPreview = computed(() => {
  let className: string | string[] = `card-preview_${props.card.cardType}`;

  const style: any = {};

  if (
    [CoursewareType.Image, CoursewareType.Video, CoursewareType.Ppt].includes(
      props.card.cardType
    ) &&
    backgroundImageUrl.value
  ) {
    style["backgroundImage"] = `url(${backgroundImageUrl.value})`;
  }
  if (
    backgroundImageUrl.value &&
    props.card.cardType === CoursewareType.Video
  ) {
    className = [className, "card-preview_video_player"];
  } else if (
    backgroundImageUrl.value &&
    props.card.cardType === CoursewareType.Image
  ) {
    className = [className, "card-preview_image_player"];
  } else if (
    backgroundImageUrl.value &&
    props.card.cardType === CoursewareType.Ppt
  ) {
    className = [className, "card-preview_ppt_player"];
  }
  return {
    style,
    class: className,
  };
});

watch(
  () => props.card.url,
  () => {
    getBackgroundImageUrl();
  }
);

const getBackgroundImageUrl = () => {
  if (
    ![CoursewareType.Image, CoursewareType.Video, CoursewareType.Ppt].includes(
      props.card.cardType
    )
  ) {
    return;
  }
  let url = "";
  switch (props.card.cardType) {
    case CoursewareType.Image:
      url = props.card.cardUrl;
      break;
    case CoursewareType.Video:
      url =
        props.card.cardUrl +
        "?x-oss-process=video/snapshot,t_1000,f_jpg,w_400,m_fast,ar_auto";
      break;
    case CoursewareType.Ppt:
      url = thumbnailImages.value[0] + "?x-oss-process=image/resize,w_400";
      break;
  }

  if (!url) {
    return;
  }
  const img = new Image();
  img.src = url;
  img.onload = () => {
    backgroundImageUrl.value = url;
  };
};


const onCardClick = () => {
  emits("click", props.card);
};

const cardProgressIndex = ref(0);
const mouseMoving = ref(false);
const onMouseMove = (e: any) => {
  const thumbnailImagesLen = thumbnailImages.value.length;
  if (thumbnailImagesLen <= 0) {
    return;
  }
  const imageLen = thumbnailImagesLen > 10 ? 10 : thumbnailImagesLen;
  const containerWidth = e.target.offsetWidth;
  const offsetX = e.offsetX;
  const index = Math.floor((offsetX / containerWidth) * imageLen);
  mouseMoving.value = true;
  if (index < 0) {
    cardProgressIndex.value = 0;
    backgroundImageUrl.value =
      thumbnailImages.value[0] + "?x-oss-process=image/resize,w_400";
    return;
  }
  cardProgressIndex.value = index;
  backgroundImageUrl.value =
    thumbnailImages.value[index] + "?x-oss-process=image/resize,w_400";
};

const omMouseLeave = () => {
  if (thumbnailImages.value.length > 0) {
    mouseMoving.value = false;
    cardProgressIndex.value = 0;
    backgroundImageUrl.value =
      thumbnailImages.value[0] + "?x-oss-process=image/resize,w_400";
  }
};

onMounted(() => {
  getBackgroundImageUrl();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: onCardClick,
      class: _normalizeClass(["card-preview", _unref(cardPreview).class]),
      style: _normalizeStyle(_unref(cardPreview).style),
      onMousemove: onMouseMove,
      onMouseleave: omMouseLeave
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["card-tag", _unref(cardTagClassName)])
      }, _toDisplayString(_unref(coursewareTypeDesc)), 3),
      (_unref(thumbnailImages).length > 0 && mouseMoving.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(cardProgressIndex.value + 1) + "/" + _toDisplayString(_unref(thumbnailImages).length) + "页 ", 1))
        : _createCommentVNode("", true),
      (__props.card?.cardIsPrimary && __props.card.cardType === _unref(CoursewareType).Ppt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(__props.card.name), 1)
      ])
    ], 38),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "bottom", { data: __props.card })
    ])
  ]))
}
}

})