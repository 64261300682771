
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useRouter } from "vue-router";
import store from "@/store/index";
import HomeBackground from "@/components/common/HomeBackground";
import logger from "@evideo/logger";
export default defineComponent({
  components: {
    HomeBackground,
  },
  setup() {
    const loading = ref(false);
    const teacherName = ref(store.state.nickName);
    const schoolName = ref(store.state.schoolName);
    const cbVisible = ref(false);
    const routerUrl = ref("/song-maker/index.html");
    const router = useRouter();
    const schoolLogo = computed(() => {
      return store.state.schoolLogo;
    });
    // 是否劢联定制版
    let isML = ref(false);
    // 是否打开iframe
    let isOpenIframe = ref(false);
    let isAndroid = ref(true);
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

    // 返回
    const handleGoToHome = () => {
      router.push({
        path: "/home",
      });
    };
    const getIpcMessage = (event, data) => {
      const iframe = document.getElementById("cb");
      if (!isOpenIframe.value) {
        handleGoToHome();
      } else {
        iframe.contentWindow.postMessage({ message: "打开关闭确认框" });
      }
    };
    onBeforeMount(() => {
      isML.value = store.state.isML;
    });
    onMounted(() => {
      if (window.require) {
        console.log("收到electron goGameCenter消息");
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.on("cbGoHome", getIpcMessage);
      }
      window.closeIframe = () => {
        cbVisible.value = false;
        isOpenIframe.value = false;
      };
    });
    onBeforeUnmount(async () => {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.removeListener("cbGoHome", getIpcMessage);
    });
    const goToXXZQJ = () => {
      console.log("pppp", window);
      routerUrl.value = "/song-maker/index.html";
      // routerUrl.value = "/Kandinsky/index.html";
      cbVisible.value = true;
      isOpenIframe.value = true;
    };
    const goToJZ = () => {
      routerUrl.value = "/Rhythm/index.html";
      cbVisible.value = true;
      isOpenIframe.value = true;
    };
    const goToInteractiveBeat = () => {
      router.push({
        path: "/musicRhythm",
      });
    };
    const goToAnswerPK = () => {
      router.push({
        path: "/gameCenter/answerGame",
      });
    };

    const goToLogin = () => {
      router.push({
        path: "/home",
      });
    };

    const goLeft = () => {
      const elem = document.getElementById("Middle");
      elem.scrollLeft += 2000;
    };
    const goRight = () => {
      const elem = document.getElementById("Middle");
      elem.scrollLeft -= 2000;
    };
    return {
      loading,
      teacherName,
      schoolName,
      goToXXZQJ,
      goToJZ,
      goToInteractiveBeat,
      goToAnswerPK,
      goToLogin,
      goLeft,
      goRight,
      handleGoToHome,
      isML,
      schoolLogo,
      cbVisible,
      routerUrl,
    };
  },
});
