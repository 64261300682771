
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onActivated,
  onMounted,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { getTeacherInfo } from "@/services";
import store, { MutationNames } from "@/store/index";
import JsBridge from "../../jsBridge";
import { dataEmbeddingPoint } from "@/services";
import config from "@/config/app.config";
import { eventCenter, event } from "@evideo/frontend-utils";
import HomeBackground from "@/components/common/HomeBackground";

export default defineComponent({
  components: {
    HomeBackground,
  },
  setup() {
    const loading = ref(false);
    const teacherName = ref(store.state.nickName);
    const schoolName = ref(store.state.schoolName);
    const router = useRouter();
    const schoolLogo = computed(() => {
      return store.state.schoolLogo;
    });
    // 是否劢联定制版
    let isML = ref(false);
    let isAndroid = ref(true);
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

    // 返回
    const handleGoToHome = () => {
      router.push({
        path: "/home",
      });
    };
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.once("goHome", (event, message) => {
        // 接收electron原生返回的信息
        console.log(1231234);
        handleGoToHome();
      });
    }
    onBeforeMount(() => {
      isML.value = store.state.isML;
    });
    onActivated(() => {
      // this.reload();
      // location.reload();
    });
    const goToRhythm = () => {
      router.push({
        path: "/gameCenter/pacePracticeGame",
      });
    };
    const goToListening = () => {
      router.push({
        path: "/gameCenter/auditionGame",
      });
    };
    const goToInteractiveBeat = () => {
      router.push({
        path: "/musicRhythm",
      });
    };
    const goToAnswerPK = () => {
      router.push({
        path: "/gameCenter/answerGame",
      });
    };

    const goToLogin = () => {
      router.push({
        path: "/home",
      });
    };

    const goLeft = () => {
      const elem = document.getElementById("Middle");
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft += 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    const goRight = () => {
      const elem = document.getElementById("Middle");
      // fjfwBox = document.querySelector("#Middle");
      elem.scrollLeft -= 2000;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    return {
      loading,
      teacherName,
      schoolName,
      goToRhythm,
      goToListening,
      goToInteractiveBeat,
      goToAnswerPK,
      goToLogin,
      goLeft,
      goRight,
      handleGoToHome,
      isML,
      schoolLogo,
    };
  },
});
