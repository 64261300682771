
import {
  defineComponent,
  ref,
  reactive,
  onBeforeMount,
  onMounted,
  computed,
  watchEffect,
} from "vue";
import { eVideoGetFileType } from "@/utils";
import MusicPlayer from "@/components/preview/MusicPlayer.vue";
import logger from "@evideo/logger";
import useGesture from "@/utils/useGesture";

export default defineComponent({
  name: "InstrumentsDetail",
  components: { MusicPlayer },
  props: {
    detail: {
      type: Object,
    },
  },
  emits: ["handleActivePanelHide"],
  setup(props, ctx) {
    const info = ref(props.detail);
    // 字体大小
    let introductionFontSize = ref(36);
    // 最大字体
    let fontSizeMax = ref(56);
    // 最小字体
    let fontSizeMin = ref(28);
    let intros = ref([]);
    let musicUrl = ref(null);
    let videoUrl = ref(null);
    let musicTitle = ref(null);
    let videoTitle = ref(null);
    let musicArtist = ref(null);
    let dialogTableVisible = ref(false);

    // 返回乐器列表
    const handleHideDetail = () => {
      ctx.emit("handleActivePanelHide");
      musicUrl.value = null;
    };
    // 显示audio/video组件
    const handleToPlayer = (item) => {
      // const targetUrl = item.url.includes("https")
      //   ? item.url
      //   : item.url.replace("http", "https");
      // const fileType = eVideoGetFileType(targetUrl);
      logger.info("item", item);
      if (item.type === "video") {
        dialogTableVisible.value = true;
        videoUrl.value = item.url;
        videoTitle.value = item.name;
      } else {
        // const reg = new RegExp(".mp3");
        // const name = item.name.replace(reg, "");
        musicUrl.value = item.url;
        musicTitle.value = item.name;
      }
    };

    // 调整字体大小
    const handleFontSizeIconClick = (type) => {
      if (type && introductionFontSize.value < fontSizeMax.value) {
        introductionFontSize.value = introductionFontSize.value + 2;
        return;
      }
      if (!type && introductionFontSize.value > fontSizeMin.value) {
        introductionFontSize.value = introductionFontSize.value - 2;
      }
    };
    const scale = ref(1);
    const imageStyle = computed(() => ({
      transform: `translate(${dragData.imgPosX}px, ${dragData.imgPosY}px) scale(${scale.value})`,
      transition: ".1s",
    }));

    const showOverlay = ref(false);
    const handleImgClick = () => {
      console.log("clickclick");
      dragData.imgPosX = 0;
      dragData.imgPosY = 0;
      scale.value = 0.5;
      showOverlay.value = !showOverlay.value;
    };

    const zoomIn = () => {
      scale.value += 0.1;
    };

    const zoomOut = () => {
      if (scale.value > 0.5) {
        scale.value -= 0.1;
      }
    };
    const handleWheel = (event) => {
      if (event.deltaY < 0) {
        zoomIn();
      } else {
        zoomOut();
      }
    };

    let startScale = 1;
    const imageRef = ref(null);
    // 手势控制放大缩小
    const { initGestureEvent, destroyGestureEvent } = useGesture(imageRef, {
      gesturestart: () => {
        startScale = scale.value;
      },
      gesturechange: (e) => {
        const newScale = Math.max(startScale * e.scale, 1);
        scale.value = newScale;
      },
    });
    watchEffect(() => {
      const gesture = imageRef.value;
      if (gesture) {
        initGestureEvent();
      } else {
        destroyGestureEvent();
      }
    });

    // pc端图片移动
    let dragData = reactive({
      dragging: false,
      startX: 0,
      startY: 0,
      imgPosX: 0,
      imgPosY: 0,
    });
    // const imageRefimageRef = ref(null);

    const handleMouseDown = (event) => {
      console.log("downdown");
      event.stopPropagation();
      dragData.dragging = true;
      dragData.startX = event.clientX - dragData.imgPosX;
      dragData.startY = event.clientY - dragData.imgPosY;
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      imageRef.value.style.pointerEvents = "none";
    };

    const handleMouseUp = (event) => {
      console.log("upupup");
      event.stopPropagation();
      if (dragData.dragging) {
        dragData.dragging = false;
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
      imageRef.value.style.pointerEvents = "auto";
    };

    const handleMouseMove = (event) => {
      console.log("movemovemove");
      if (dragData.dragging) {
        const targetPosX = event.clientX - dragData.startX;
        const targetPosY = event.clientY - dragData.startY;
        if (
          Math.abs(targetPosX - dragData.imgPosX) > 1 ||
          Math.abs(targetPosY - dragData.imgPosY) > 1
        ) {
          dragData.imgPosX += (targetPosX - dragData.imgPosX) / 10;
          dragData.imgPosY += (targetPosY - dragData.imgPosY) / 10;
        }
      }
    };

    // 触摸控制图片移动
    // 触摸开始
    const touchStart = (event) => {
      event.preventDefault(); // 防止默认滚动行为
      dragData.dragging = true;
      const touch = event.touches[0];
      dragData.startX = touch.clientX - dragData.imgPosX;
      dragData.startY = touch.clientY - dragData.imgPosY;
    };

    // 触摸移动
    const touchMove = (event) => {
      event.preventDefault(); // 防止默认滚动行为
      if (dragData.dragging) {
        const touch = event.touches[0];
        const targetPosX = touch.clientX - dragData.startX;
        const targetPosY = touch.clientY - dragData.startY;
        if (
          Math.abs(targetPosX - dragData.imgPosX) > 1 ||
          Math.abs(targetPosY - dragData.imgPosY) > 1
        ) {
          dragData.imgPosX += (targetPosX - dragData.imgPosX) / 10;
          dragData.imgPosY += (targetPosY - dragData.imgPosY) / 10;
        }
      }
    };

    // 触摸结束
    const touchEnd = () => {
      dragData.dragging = false;
    };

    const addTypeToList = () => {
      props.detail.details.map((item) => {
        const targetUrl = item.url.includes("https")
          ? item.url
          : item.url.replace("http", "https");
        const fileType = eVideoGetFileType(targetUrl);
        if (fileType === 1) {
          item.type = "video";
        } else {
          item.type = "music";
        }
      });
    };

    onBeforeMount(() => {
      if (props?.detail?.intros.trim().length > 0) {
        intros.value = props.detail.intros.split(/[,|\n]/);
      }
      // intros.value =
      //   props.detail.intros !== "" ? props.detail.intros.split(/[,|\n]/) : [];
    });
    onMounted(() => {
      logger.info("info", props);
      addTypeToList();
    });
    return {
      dialogTableVisible,
      props,
      videoUrl,
      videoTitle,
      musicUrl,
      musicTitle,
      musicArtist,
      fontSizeMax,
      fontSizeMin,
      info,
      introductionFontSize,
      intros,
      handleHideDetail,
      handleFontSizeIconClick,
      handleToPlayer,
      showOverlay,
      handleImgClick,
      imageStyle,
      zoomIn,
      zoomOut,
      handleWheel,
      dragData,
      handleMouseDown,
      handleMouseUp,
      handleMouseMove,
      imageRef,
      touchStart,
      touchMove,
      touchEnd,
      addTypeToList,
    };
  },
});
